import Container from 'components/common/container';

import ancestorsProverbs from './ancestorsProverbsData';

import './style.scss';

function AncestorsProverbs() {
  return (
    <div className="ancestors-proverbs">
      <Container>
        <div className="ancestors-proverbs__container">
          <img className="ancestors-proverbs__cover" src="/images/ancestors_proverbs_cover.jpg" alt="" />
          <p className="ancestors-proverbs__description">{ancestorsProverbs.description}</p>
        </div>
      </Container>
    </div>
  );
}

export default AncestorsProverbs;
