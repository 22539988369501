import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'core/hooks';
import { Button, notification, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Flex from 'components/common/Flex';
import { useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';

import MobileWrapper from './MobileWrapper';
import NavbarItems from './NavbarItems';
import StreamLabel from './StreamLabel';
import Logo from './Logo';
import Language from './AppLanguage';
import './style.scss';

function Navbar() {
  const navigate = useNavigate();
  const { userInfo } = useMemoSelector(getAuthState);
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [isMemosDisabled, setMemosDisabled] = useState<boolean>(false);
  const { currentLanguage } = useLanguage();

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            navigate('/login?routeto=memos');
            notificationApi.destroy();
          }}
        >
          {currentLanguage.login}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: currentLanguage?.loginToContinue,
      description: currentLanguage?.memosCondition,
      btn,
      key,
      onClose: () => {},
    });
  };

  const handleMenuOpen = () => {
    setMenuOpened(!menuOpened);
  };

  const toMemos = () => {
    if (isMemosDisabled) {
      notificationApi.destroy();
      openNotification();
    } else {
      navigate('/memos');
    }
  };

  useEffect(() => {
    if (userInfo) {
      setMemosDisabled(false);
    } else {
      setMemosDisabled(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1250) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className="navbar"
    >
      {notificationContextHolder}
      <Flex alignItems="center">
        <Logo
          isMobileDevice={isMobileDevice}
          headerText={currentLanguage?.headerText || ''}
          headerShortText={currentLanguage?.headerShortText || ''}
        />
        <StreamLabel label={currentLanguage?.live_stream || ''} />
      </Flex>
      {!isMobileDevice ? (
        <Flex alignItems="center">
          <NavbarItems className="navbar__menu" toMemos={toMemos} />
          <Language />
        </Flex>
      ) : (
        <>
          <Button onClick={handleMenuOpen}>
            <MenuOutlined />
          </Button>
          {menuOpened && (
            <MobileWrapper
              toMemos={toMemos}
              setMenuOpened={setMenuOpened}
            />
          )}
        </>
      )}
    </Flex>
  );
}

export default Navbar;
