import { Button, Menu, Popconfirm, Tooltip, Typography } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { IMemo } from 'types';
import Flex from 'components/common/Flex';
import { useLanguage } from 'core/hooks';

const { Item } = Menu;
const { Text } = Typography;

interface IMemosMenu {
  memos: IMemo[];
  createNewMemo: () => void;
  selectMemo: (memoItem: IMemo) => void;
  setEditMode: (val: boolean) => void;
  handleRemoveMemo: () => void;
}

function MemosMenu({
  memos,
  createNewMemo,
  selectMemo,
  setEditMode,
  handleRemoveMemo,
}: IMemosMenu) {
  const { currentLanguage } = useLanguage();
  return (
    <div className="memos__menu">
      <Button
        className="memos__add-memo"
        type="primary"
        onClick={createNewMemo}
      >
        {currentLanguage.add_new_memo}
      </Button>
      {memos?.length ? (
        <Menu
          key={memos.length}
          className="memos__menu__wrapper"
          theme="light"
          mode="inline"
          defaultSelectedKeys={[memos[0]._id]}
        >
          {memos.map((memoItem: IMemo) => (
            <Item
              className="memos__menu__item"
              key={memoItem._id}
              onClick={() => selectMemo(memoItem)}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
              >
                <Text
                  ellipsis={{ tooltip: memoItem.title }}
                  style={{ width: 158 }}
                >
                  {memoItem.title}
                </Text>
                <Flex className="memos__menu__item__buttons" alignItems="center">
                  <Tooltip title={currentLanguage.edit}>
                    <Button className="memos__menu__item__remove-button" onClick={() => setEditMode(true)} type="ghost">
                      <FiEdit />
                    </Button>
                  </Tooltip>
                  <Popconfirm
                    title={currentLanguage.deletion_message}
                    onConfirm={handleRemoveMemo}
                    onCancel={() => { }}
                    okText={currentLanguage.agree}
                    cancelText={currentLanguage.no}
                  >
                    <Tooltip title={currentLanguage.delete}>
                      <Button className="memos__menu__item__remove-button" type="ghost">
                        <AiFillDelete />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Flex>

              </Flex>
            </Item>
          ))}
        </Menu>
      ) : null}
    </div>
  );
}

export default MemosMenu;
