/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { logError } from 'config/logging';

import { registerUser, userLogin, logoutUser } from './actions';
import { IAuth, IAuthMessage } from './types';

export const initialState: IAuth = {
  loading: false,
  userInfo: undefined,
  message: undefined,
};

const isAuthErr = (object: any): object is IAuthMessage => object.key && object.message && object.type;

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: builder => {
    // register user
    builder.addCase(registerUser.pending, state => {
      state.loading = true;
      state.message = undefined;
    }).addCase(registerUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    }).addCase(registerUser.rejected, (state, { payload }) => {
      state.loading = false;
      if (isAuthErr(payload)) {
        state.message = payload;
      } else {
        logError(payload);
      }
    });

    // login user
    builder.addCase(userLogin.pending, state => {
      state.loading = true;
      state.message = undefined;
    })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.user;
        state.message = payload.message;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        if (isAuthErr(payload)) {
          state.message = payload;
        } else {
          logError(payload);
        }
      });

    // login user
    builder.addCase(logoutUser.pending, state => {
      state.loading = true;
      state.message = undefined;
    })
      .addCase(logoutUser.fulfilled, state => {
        state.loading = false;
        state.userInfo = undefined;
        state.message = undefined;
      })
      .addCase(logoutUser.rejected, (state, { payload }) => {
        state.loading = false;
        if (isAuthErr(payload)) {
          state.message = payload;
        } else {
          logError(payload);
        }
      });
  },
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;
