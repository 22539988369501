import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AUTH_API } from 'config/constants';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_API,
    prepareHeaders: headers => headers,
  }),
  endpoints: build => ({
    getDetails: build.query({
      query: () => ({
        url: 'api/user/profile',
        method: 'GET',
      }),
    }),
  }),
});

// export react hook
export const { useGetDetailsQuery } = authApi;
