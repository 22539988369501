import { IBibleBookChapter } from 'types';
import { ChapterService } from 'core/services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useMemoSelector } from 'store/hooks';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import useBookNavigation from 'components/pages/bible/useBookNavigation';
import { getBibleTranslationsIds, getCurrentBook, getCurrentChapter } from 'store/selectors/bibleTranslations';
import { getSplittedText } from 'components/pages/bible/utils';

const useChapterText = () => {
  const dispatch = useAppDispatch();
  const { getTranslationsChaptersTexts } = ChapterService;
  const { getPrevBook, getNextBook, setCurrentBook } = useBookNavigation();
  const { currentBook, chapterNumber, translationIds } = useMemoSelector(state => ({
    currentBook: getCurrentBook(state),
    chapterNumber: getCurrentChapter(state),
    translationIds: getBibleTranslationsIds(state),
  }));
  const [chaptersTexts, setChaptersTexts] = useState<IBibleBookChapter[]>([]);

  useEffect(() => {
    getTranslationsChaptersTexts(translationIds, currentBook.number, chapterNumber).then(data => {
      // sorting for correct displaying of chapters. order by user checked translations
      const sortedData = data.sort((a, b) => translationIds.indexOf(a.translation_id) - translationIds.indexOf(b.translation_id));
      setChaptersTexts(sortedData);
    });
  }, [currentBook.number, getTranslationsChaptersTexts, translationIds, chapterNumber]);

  const chaptersSplittedVerses = useMemo(() => (
    chaptersTexts.map(({ text, preface, translation_id }) => ({
      text: getSplittedText(text, preface),
      translationId: translation_id,
    }))
  ), [chaptersTexts]);

  const getNextChaptersTexts = useCallback(() => {
    const nextChapterNumber = chapterNumber + 1;
    if (nextChapterNumber > currentBook.chaptersCount) {
      const nextBook = getNextBook();
      return setCurrentBook(nextBook.number);
    }

    dispatch(bibleTranslationsSlice.actions.setCurrentChapterNumber(nextChapterNumber));
  }, [chapterNumber, currentBook.chaptersCount, dispatch, getNextBook, setCurrentBook]);

  const getPrevChaptersTexts = useCallback(() => {
    const prevChapterNumber = chapterNumber - 1;
    if (prevChapterNumber < 1) {
      const prevBook = getPrevBook();
      return setCurrentBook(prevBook.number, prevBook.chapters_count);
    }

    dispatch(bibleTranslationsSlice.actions.setCurrentChapterNumber(prevChapterNumber));
  }, [chapterNumber, dispatch, getPrevBook, setCurrentBook]);

  return {
    chaptersTexts,
    getNextChaptersTexts,
    getPrevChaptersTexts,
    chaptersSplittedVerses,
    currentBook,
    chapterNumber,
    translationIds,
  };
};

export default useChapterText;
