import { Button } from 'antd';
import { useYoutubeChannel } from 'core/hooks';
import { useNavigate } from 'react-router-dom';

function StreamLabel({
  label,
}: { label: string }) {
  const navigate = useNavigate();
  const { channelIsLive } = useYoutubeChannel();
  return (
    <Button
      className="navbar__stream-label-button"
      type="primary"
      size="small"
      style={{
        marginLeft: 'var(--gap-main)',
        ...(!channelIsLive ? {
          backgroundColor: 'var(--main-coral)',
          color: 'var(--main-white)',
        } : {}),
      }}
      danger={channelIsLive}
      onClick={() => navigate('/stream')}
    >
      {label}
    </Button>
  );
}

export default StreamLabel;
