import { configureStore } from '@reduxjs/toolkit';

import throttle from 'lodash/throttle';
import { APP_DEFAULT_LANGUAGE, APP_LANGUAGES_ENUM } from 'config/constants';
import { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage } from 'core/services/localStorage.service';

import generalSlice from './slices/generalSlice';
import selectLanguages from './selectors/selectLanguages';
import bibleTranslationsSlice from './slices/bibleTranslationsSlice';
import memosSlice from './slices/memos';
import bookmarksSlice from './slices/bookmarks';
import notesSlice from './slices/notes';
import { getBibleTrState } from './selectors/bibleTranslations';
import { IBibleSliceState } from './slices/bibleTranslationsSlice/types';
import authReducer, { initialState as initialAuthState } from './slices/auth';
import { authApi } from './slices/auth/service';
import { getAuthUserInfo } from './selectors/auth';
import { IUserInfo } from './slices/auth/types';

const BIBLE_TRANSLATIONS_KEY = 'bibleTranslations';
const APP_LANGUAGE_KEY = 'language';
const USER_INFO_KEY = 'user-info';

const persistedBibleTranslations = getFromLocalStorage<IBibleSliceState>(BIBLE_TRANSLATIONS_KEY);
const persistedAppLanguage = getFromLocalStorage<APP_LANGUAGES_ENUM>(APP_LANGUAGE_KEY);
const persistedUserInfo = getFromLocalStorage<IUserInfo>(USER_INFO_KEY);

const store = configureStore({
  reducer: {
    general: generalSlice.reducer,
    bibleTranslations: bibleTranslationsSlice.reducer,
    memos: memosSlice.reducer,
    bookmarks: bookmarksSlice.reducer,
    notes: notesSlice.reducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authApi.middleware),
  preloadedState: {
    general: { language: persistedAppLanguage || APP_DEFAULT_LANGUAGE },
    bibleTranslations: persistedBibleTranslations,
    auth: {
      ...initialAuthState,
      ...(persistedUserInfo && { userInfo: persistedUserInfo }),
    },
  },
});

// listen for store changes and use setToLocalStorage to save them to localStorage
store.subscribe(throttle(() => {
  setToLocalStorage(BIBLE_TRANSLATIONS_KEY, getBibleTrState(store.getState()));
  setToLocalStorage(APP_LANGUAGE_KEY, selectLanguages(store.getState()));
  const userInfo = getAuthUserInfo(store.getState());
  userInfo ? setToLocalStorage(USER_INFO_KEY, userInfo) : removeFromLocalStorage(USER_INFO_KEY);
}, 1000));

export default store;
