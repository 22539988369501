import { useDispatch } from 'react-redux';
import { userLogin } from 'store/slices/auth/actions';
import { getAuthState } from 'store/selectors/auth';
import { Input, Button, Typography, Form, notification } from 'antd';
import { useLanguage } from 'core/hooks';
import { useMemoSelector } from 'store/hooks';

import './style.scss';

const { Title, Text } = Typography;

interface FormData {
  username: string;
  password: string;
}

export default function ResetPassword() {
  const { loading } = useMemoSelector(getAuthState);
  const dispatch = useDispatch();
  const { currentLanguage } = useLanguage();
  const [contextHolder] = notification.useNotification();

  const submitForm = (data: FormData) => {
    dispatch(userLogin(data));
  };

  return (
    <div className="reset">
      {contextHolder}
      <div className="reset__container">
        <Title className="reset__text" level={4}>{currentLanguage.resetPassword}</Title>
        <Text className="reset__text__paragraph">{currentLanguage.resetPasswordText}</Text>
        <Form className="reset__form" onFinish={submitForm}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: currentLanguage.emailRequireErrorMessage }]}
          >
            <div className="reset__form__item">
              <Input
                placeholder={currentLanguage.email}
                type="email"
                className="reset__form__item__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: currentLanguage.passwordRequireErrorMessage }]}
          >
            <div className="reset__form__item">
              <Input
                placeholder={currentLanguage.password}
                type="password"
                className="reset__form__item__input"
              />
            </div>
          </Form.Item>
          <div className="reset__form__item">
            <Button className="reset__submit" htmlType="submit" type="primary" disabled={loading}>
              {loading
                ? (
                  <>
                    <span>...</span>
                    <span>{currentLanguage.loading}</span>
                  </>
                )
                : currentLanguage.resetPassword}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
