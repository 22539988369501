import { ReactChild, forwardRef, ForwardedRef } from 'react';
import './style.scss';

interface IContainerProps {
  children: ReactChild;
  fluid?: boolean;
  style?: React.CSSProperties;
  id?: string;
}

const Container = forwardRef<HTMLDivElement, IContainerProps>(
  ({ children, fluid, style, id }: IContainerProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div
      id={id}
      className={fluid ? 'container container__fluid' : 'container'}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  ),
);

export default Container;
