import { Button } from 'antd';
import { useLanguage } from 'core/hooks';
import { IMemo } from 'types';

interface IMemosListProps {
  memos: IMemo[];
  handleMemoItemClick: (memoId: string | undefined) => void;
  createNewMemo: () => void;
}

function MemosList({
  memos,
  createNewMemo,
  handleMemoItemClick,
}: IMemosListProps) {
  const { currentLanguage } = useLanguage();
  return (
    <div className="memos__list">
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={createNewMemo}
      >
        {currentLanguage.add_new_memo}
      </Button>
      <ul className="memos__list__items">
        {memos.map((memo: IMemo) => (
          <li
            key={memo._id}
            className="memos__list__item"
          >
            <Button
              style={{ width: '100%' }}
              onClick={() => handleMemoItemClick(memo._id)}
            >
              {memo.title}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MemosList;
