import {
  YOUTUBE_PLAYLIST,
  YOUTUBE_FACETS,
  YOUTBE_PLAYLIST_VIDEOS,
} from '../../config/constants';

export const getYoutubePlaylists = async (maxResult = 10) => {
  const response = await fetch(`${YOUTUBE_PLAYLIST}&maxResults=${maxResult}`);
  const playlist = await response.json();

  return playlist;
};

export const getYoutubeChannelVideo = async (maxResult = 40) => {
  const response = await fetch(`${YOUTUBE_FACETS}&maxResults=${maxResult}`);
  const facetVideoList = await response.json();

  return facetVideoList;
};

export const getPlaylistVideos = async (playlistID: string, pageToken?: string, maxResult = 20) => {
  let url = `${YOUTBE_PLAYLIST_VIDEOS}&playlistId=${playlistID}&maxResults=${maxResult}`;
  if (pageToken) {
    url += `&pageToken=${pageToken}`;
  }

  const response = await fetch(url);
  const playlistData = response.json();

  return playlistData;
};
