import { useState } from 'react';
import { ContactUsService } from 'core/services';

interface IMessageBody {
  email: string;
  message: string;
  phoneNumber: string;
}

const useContactUs = () => {
  const [loading, setLoading] = useState(false);

  const sendEmail = (value: IMessageBody) => {
    setLoading(true);
    try {
      return ContactUsService.sendMessageForCustomer(value);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    sendEmail,
  };
};

export default useContactUs;
