import { IBibleBook } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { BooksService } from 'core/services';
import { useAppSelector } from 'store/hooks';
import selectLanguages from 'store/selectors/selectLanguages';
import { TRANSLATIONS_IDS_MAP } from 'config/constants';

const useBooks = () => {
  const [booksList, setBooksList] = useState<IBibleBook[]>([]);
  const langShortcut = useAppSelector(selectLanguages);
  const translationId = useMemo(() => TRANSLATIONS_IDS_MAP[langShortcut], [langShortcut]);

  const getBookByNumber = async (bookNumber: number, translation_id: number) => {
    const result = await BooksService.getBookByNumber(bookNumber, translation_id);
    return result;
  };

  useEffect(() => {
    BooksService.getBooksListByTranslationId(translationId)
      .then(data => {
        setBooksList(data);
      });
  }, [translationId]);

  return {
    booksList,
    getBookByNumber,
  };
};

export default useBooks;
