import Container from 'components/common/container';

import churchActsData from './churchActsData';

import './style.scss';

function ChurchActs() {
  const churchActs = churchActsData;
  return (
    <div className="church-acts">
      <Container>
        <div className="church-acts__container">
          <img className="church-acts__cover" src="/images/church_acts_cover.png" alt="" />
          <p className="church-acts__description">{churchActs.description}</p>
        </div>
      </Container>
    </div>
  );
}

export default ChurchActs;
