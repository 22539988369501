import { useCallback, useEffect, useRef } from 'react';
import { IMemo } from 'types';
import { Button, Form, Input, Popconfirm, message } from 'antd';
import Flex from 'components/common/Flex';
import { useLanguage } from 'core/hooks';

type INewMemo = Partial<IMemo>;

interface IMemoPreviewProps {
  editMode: boolean;
  memo: INewMemo | null;
  updateCurrentMemo: ({ title, text }: {title: string; text: string}) => void;
  saveMemo: ({ title, text }: {title: string; text: string}) => void;
  setEditMode: (val: boolean) => void;
  setActiveMemo: (val: IMemo | null) => void;
}
const { TextArea } = Input;

function MemoPreview({
  editMode,
  memo,
  setEditMode,
  updateCurrentMemo,
  saveMemo,
  setActiveMemo,
}: IMemoPreviewProps) {
  const { currentLanguage } = useLanguage();
  const [form] = Form.useForm();
  const textareaRef = useRef<any>(null);

  form?.setFieldsValue(memo);
  const onFinish = useCallback((val: {title: string; text: string }) => {
    const { title, text } = val;
    if (memo?._id) {
      if (title !== memo?.title || text !== memo?.text) {
        const emptyText = ' ';
        updateCurrentMemo({ title, text: text.length ? text : emptyText });
        return message.success(currentLanguage.saved);
      }

      return true;
    }

    saveMemo({ title, text });
    return message.success(currentLanguage.saved);
  }, [currentLanguage.saved, memo, saveMemo, updateCurrentMemo]);

  const confirmCancel = () => {
    if (!memo?._id) {
      setActiveMemo(null);
    }

    form.setFieldsValue(memo);
    setEditMode(false);
  };

  const scrollToBottom = () => {
    if (textareaRef.current) {
      textareaRef.current.resizableTextArea.textArea.scrollTop = textareaRef.current.resizableTextArea.textArea.scrollHeight;
    }
  };

  useEffect(() => {
    if (memo?.text?.length) {
      scrollToBottom();
    }
  }, [memo]);
  return (
    <div className="memos__preview">
      <Form
        key={memo?.title}
        form={form}
        layout="vertical"
        disabled={!editMode}
        onFinish={onFinish}
      >
        <Form.Item
          name="title"
          style={{ width: '100%' }}
        >
          <Input
            showCount
            maxLength={30}
            className="memos__preview__textarea"
            placeholder={currentLanguage.memo_title}
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="text"
        >
          <TextArea
            ref={textareaRef}
            className="memos__preview__textarea"
            placeholder={currentLanguage.memo_text}
            style={{ minWidth: 280, minHeight: 500 }}
          />
        </Form.Item>
        {editMode && (
          <Flex justifyContent="flex-end">
            <>
              <Form.Item>
                <Button htmlType="submit" type="primary" style={{ marginRight: 16 }}>
                  {currentLanguage.save}
                </Button>
              </Form.Item>
              <Popconfirm
                title={currentLanguage.cancel_editing}
                onConfirm={confirmCancel}
                onCancel={() => {}}
                okText={currentLanguage.agree}
                cancelText={currentLanguage.no}
              >
                <Button>{currentLanguage.cancel}</Button>
              </Popconfirm>
            </>
          </Flex>
        )}
      </Form>
    </div>
  );
}

export default MemoPreview;
