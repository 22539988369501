export default {
  ru: [
    {
      title: 'БИБЛИЯ',
      number: 1,
      url: 'lessonsData/ru_lesson/facet_1_ru',
    },
    {
      title: 'БОГ',
      number: 2,
      url: 'lessonsData/ru_lesson/facet_2_ru',
    },
    {
      title: 'ЧЕЛОВЕК',
      number: 3,
      url: 'lessonsData/ru_lesson/facet_3_ru',
    },
    {
      title: 'ГРЕХ',
      number: 4,
      url: 'lessonsData/ru_lesson/facet_4_ru',
    },
    {
      title: 'ЗАКОН  БОЖИЙ',
      number: 5,
      url: 'lessonsData/ru_lesson/facet_5_ru',
    },
    {
      title: 'ИИСУС ХРИСТОС',
      number: 6,
      url: 'lessonsData/ru_lesson/facet_6_ru',
    },
    {
      title: 'СПАСЕНИЕ',
      number: 7,
      url: 'lessonsData/ru_lesson/facet_7_ru',
    },
    {
      title: 'БЛАГОДАТЬ',
      number: 8,
      url: 'lessonsData/ru_lesson/facet_8_ru',
    },
    {
      title: 'ЗОВ',
      number: 9,
      url: 'lessonsData/ru_lesson/facet_9_ru',
    },
    {
      title: 'ПОКАЯНИЕ',
      number: 10,
      url: 'lessonsData/ru_lesson/facet_10_ru',
    },
    {
      title: 'ВЕРА',
      number: 11,
      url: 'lessonsData/ru_lesson/facet_11_ru',
    },
    {
      title: 'НОВОЕ РОЖДЕНИЕ',
      number: 12,
      url: 'lessonsData/ru_lesson/facet_12_ru',
    },
    {
      title: 'ОПРАВДАНИЕ',
      number: 13,
      url: 'lessonsData/ru_lesson/facet_13_ru',
    },
    {
      title: 'УСЫНОВЛЕНИЕ',
      number: 14,
      url: 'lessonsData/ru_lesson/facet_14_ru',
    },
    {
      title: 'ОСВЯЩЕНИЕ',
      number: 15,
      url: 'lessonsData/ru_lesson/facet_15_ru',
    },
    {
      title: 'ПРОСЛАВЛЕНИЕ',
      number: 16,
      url: 'lessonsData/ru_lesson/facet_16_ru',
    },
    {
      title: 'ЕДИНЕНИЕ СО ХРИСТОМ',
      number: 17,
      url: 'lessonsData/ru_lesson/facet_17_ru',
    },
    {
      title: 'БЛАГОСЛОВЕНИЕ И ПРОКЛЯТИЕ',
      number: 18,
      url: 'lessonsData/ru_lesson/facet_18_ru',
    },
    {
      title: 'СВЯТОЙ ДУХ',
      number: 19,
      url: 'lessonsData/ru_lesson/facet_19_ru',
    },
    {
      title: 'КРЕЩЕНИЕ СВЯТЫМ ДУХОМ',
      number: 20,
      url: 'lessonsData/ru_lesson/facet_20_ru',
    },
    {
      title: 'ЦЕРКОВЬ',
      number: 21,
      url: 'lessonsData/ru_lesson/facet_21_ru',
    },
    {
      title: 'ЛИЧНОЕ СВИДЕТЕЛЬСТВО',
      number: 22,
      url: 'lessonsData/ru_lesson/facet_22_ru',
    },
    {
      title: 'АНГЕЛЫ',
      number: 23,
      url: 'lessonsData/ru_lesson/facet_23_ru',
    },
    {
      title: 'ВОСКРЕСЕНИЕ МЁРТВЫХ',
      number: 24,
      url: 'lessonsData/ru_lesson/facet_24_ru',
    },
    {
      title: 'ВЕЧНЫЙ СУД',
      number: 25,
      url: 'lessonsData/ru_lesson/facet_25_ru',
    },
    {
      title: 'КРЕЩЕНИЕ ОГНЁМ',
      number: 26,
      url: 'lessonsData/ru_lesson/facet_26_ru',
    },
    {
      title: 'ДУХОВНАЯ ВОЙНА',
      number: 27,
      url: 'lessonsData/ru_lesson/facet_27_ru',
    },
    {
      title: 'БОЖЕСТВЕННОЕ ИСЦЕЛЕНИЕ',
      number: 28,
      url: 'lessonsData/ru_lesson/facet_28_ru',
    },
    {
      title: 'ХВАЛЕНИЯ',
      number: 29,
      url: 'lessonsData/ru_lesson/facet_29_ru',
    },
    {
      title: 'КЛЯТВА',
      number: 30,
      url: 'lessonsData/ru_lesson/facet_30_ru',
    },
    {
      title: 'ВОЗЛОЖЕНИЕ РУК',
      number: 31,
      url: 'lessonsData/ru_lesson/facet_31_ru',
    },
    {
      title: 'КРЕЩЕНИЕ ВОДОЙ',
      number: 32,
      url: 'lessonsData/ru_lesson/facet_32_ru',
    },
    {
      title: 'ВЕЧЕРЯ ГОСПОДНЯ',
      number: 33,
      url: 'lessonsData/ru_lesson/facet_33_ru',
    },
  ],
  am: [
    {
      title: 'ԱՍՏՎԱԾԱՇՈւՆՉ',
      number: 1,
      url: 'lessonsData/am_lesson/facet_1_am',
    },
    {
      title: 'ԱՍՏՎԱԾ',
      number: 2,
      url: 'lessonsData/am_lesson/facet_2_am',
    },
    {
      title: 'ՄԱՐԴ',
      number: 3,
      url: 'lessonsData/am_lesson/facet_3_am',
    },
    {
      title: 'ՄԵՂՔ',
      number: 4,
      url: 'lessonsData/am_lesson/facet_4_am',
    },
    {
      title: 'ԱՍՏԾՈ ՕՐԵՆՔ',
      number: 5,
      url: 'lessonsData/am_lesson/facet_5_am',
    },
    {
      title: 'ՀԻՍՈւՍ ՔՐԻՍՏՈՍ',
      number: 6,
      url: 'lessonsData/am_lesson/facet_6_am',
    },
    {
      title: 'ՓՐԿՈւԹՅՈւՆ',
      number: 7,
      url: 'lessonsData/am_lesson/facet_7_am',
    },
    {
      title: 'ՇՆՈՐՀՔ',
      number: 8,
      url: 'lessonsData/am_lesson/facet_8_am',
    },
    {
      title: 'ԿԱՆՉ',
      number: 9,
      url: 'lessonsData/am_lesson/facet_9_am',
    },
    {
      title: 'ԱՊԱՇԽԱՐՈւԹՅՈւՆ',
      number: 10,
      url: 'lessonsData/am_lesson/facet_10_am',
    },
    {
      title: 'ՀԱՎԱՏՔ',
      number: 11,
      url: 'lessonsData/am_lesson/facet_11_am',
    },
    {
      title: 'ՎԵՐՍՏԻՆ ԾՆՈւՆԴ',
      number: 12,
      url: 'lessonsData/am_lesson/facet_12_am',
    },
    {
      title: 'ԱՐԴԱՐԱՑՈւՄ',
      number: 13,
      url: 'lessonsData/am_lesson/facet_13_am',
    },
    {
      title: 'ՈՐԴԵԳՐՈւԹՅՈւՆ',
      number: 14,
      url: 'lessonsData/am_lesson/facet_14_am',
    },
    {
      title: 'ՍՐԲԱՑՈւՄ',
      number: 15,
      url: 'lessonsData/am_lesson/facet_15_am',
    },
    {
      title: 'ՓԱՌԱՎՈՐՈւՄ',
      number: 16,
      url: 'lessonsData/am_lesson/facet_16_am',
    },
    {
      title: 'ՔՐԻՍՏՈՍԻ ՀԵՏ ՄԵԿԱՑՈւՄ',
      number: 17,
      url: 'lessonsData/am_lesson/facet_17_am',
    },
    {
      title: 'ՕՐՀՆՈւԹՅՈւՆ ԵՎ ԱՆԵԾՔ',
      number: 18,
      url: 'lessonsData/am_lesson/facet_18_am',
    },
    {
      title: 'ՍՈւՐԲ ՀՈԳԻ',
      number: 19,
      url: 'lessonsData/am_lesson/facet_19_am',
    },
    {
      title: 'ՍՈւՐԲ ՀՈԳՈՎ ՄԿՐՏՈւԹՅՈւՆ',
      number: 20,
      url: 'lessonsData/am_lesson/facet_20_am',
    },
    {
      title: 'ԵԿԵՂԵՑԻ',
      number: 21,
      url: 'lessonsData/am_lesson/facet_21_am',
    },
    {
      title: 'ԱՆՁՆԱԿԱՆ ՎԿԱՅՈւԹՅՈւՆ',
      number: 22,
      url: 'lessonsData/am_lesson/facet_22_am',
    },
    {
      title: 'ՀՐԵՇՏԱԿՆԵՐ',
      number: 23,
      url: 'lessonsData/am_lesson/facet_23_am',
    },
    {
      title: 'ՄԵՌԵԼՆԵՐԻ ՀԱՐՈւԹՅՈւՆ',
      number: 24,
      url: 'lessonsData/am_lesson/facet_24_am',
    },
    {
      title: 'ՀԱՎԻՏԵՆԱԿԱՆ ԴԱՏԱՍՏԱՆ',
      number: 25,
      url: 'lessonsData/am_lesson/facet_25_am',
    },
    {
      title: 'ԿՐԱԿՈՎ ՄԿՐՏՈւԹՅՈւՆ',
      number: 26,
      url: 'lessonsData/am_lesson/facet_26_am',
    },
    {
      title: 'ՀՈԳԵՎՈՐ ՊԱՏԵՐԱԶՄ',
      number: 27,
      url: 'lessonsData/am_lesson/facet_27_am',
    },
    {
      title: 'ԱՍՏՎԱԾԱՅԻՆ ԲԺՇԿՈւԹՅՈւՆ',
      number: 28,
      url: 'lessonsData/am_lesson/facet_28_am',
    },
    {
      title: 'ՊԱՐԾԱՆՔ',
      number: 29,
      url: 'lessonsData/am_lesson/facet_29_am',
    },
    {
      title: 'ԵՐԴՈւՄ',
      number: 30,
      url: 'lessonsData/am_lesson/facet_30_am',
    },
    {
      title: 'ՁԵՌՆԱԴՐՈւԹՅՈւՆ',
      number: 31,
      url: 'lessonsData/am_lesson/facet_31_am',
    },
    {
      title: 'ՋՐՈՎ ՄԿՐՏՈւԹՅՈւՆ',
      number: 32,
      url: 'lessonsData/am_lesson/facet_32_am',
    },
    {
      title: 'ՏԵՐՈւՆԱԿԱՆ ԸՆԹՐԻՔ',
      number: 33,
      url: 'lessonsData/am_lesson/facet_33_am',
    },
  ],
  en: [
    {
      title: 'THE HOLY BIBLE',
      number: 1,
      url: 'lessonsData/en_lesson/facet_1_en',
    },
    {
      title: 'GOD',
      number: 2,
      url: 'lessonsData/en_lesson/facet_2_en',
    },
    {
      title: 'MAN',
      number: 3,
      url: 'lessonsData/en_lesson/facet_3_en',
    },
  ],
  ku: [
    {
      title: 'KITÊBA PÎROZ (BÎBLÎYA)',
      number: 1,
      url: 'lessonsData/ku_lesson/facet_1_ku',
    },
    {
      title: 'XWEDÊ',
      number: 2,
      url: 'lessonsData/ku_lesson/facet_2_ku',
    },
  ],
};
