interface IErrorData {
  type: string;
  message: string;
  buttonText?: string;
  headerText?: string;
}

// Error messages
export enum errorEnum {
  errorMessage = 'errorMessage',
  importantMessage = 'importantMessage',
}

export enum ERROR_MESSAGES_KEYS {
  SERVER_ERROR = 'SERVER_ERROR',
  SHARE_ERROR = 'SHARE_ERROR',
}

export const ERROR_MESSAGES: Record<ERROR_MESSAGES_KEYS, IErrorData> = {
  [ERROR_MESSAGES_KEYS.SERVER_ERROR]: {
    type: errorEnum.errorMessage,
    message: 'Server error. Please try again in a couple of minutes.',
  },
  [ERROR_MESSAGES_KEYS.SHARE_ERROR]: {
    type: errorEnum.errorMessage,
    message: 'An unexpected error occurred while sharing your post, please try again.',
  },
};
