export const YOUTUBE_API_KEY = 'AIzaSyAjwpdGE_E20WPJZ8KttsSa8oNLarEnONI';
const YOUTUBE_VIDEOS_CHANNEL_ID = 'UCphs_rznTG7wrHvWW1YpsFA';
const YOUTUBE_FACETS_CHANNEL_ID = 'UCV3BdjdfUS9qToYVvdUjjTQ';
export const YOUTUBE_CHANNEL_ID = 'UCphs_rznTG7wrHvWW1YpsFA';

export const YOUTUBE_PLAYLIST_IDS = {
  YOUTUBE_MILAZIM_PLAYLIST_ID: 'PLmjLQh7CaAEgedm_JJc0Vh986P-6TM6__',
  YOUTUBE_XNKO_PLAYLIST_ID: 'PLmjLQh7CaAEgEQKfDxJDm62c_6CqtqdCO',
  YOUTUBE_ARSEN_PLAYLIST_ID: 'PLmjLQh7CaAEiA04akzJcW4WH-5AQ_LIzf',
  YOUTUBE_TIGRAN_PLAYLIST_ID: 'PLmjLQh7CaAEhOaUAH_-oRNt_1Wuze6ohY',
  YOUTUBE_ARMEN_PLAYLIST_ID: 'PLmjLQh7CaAEjd8QKfVld2o5MZq5Njbhbb',
  YOUTUBE_DAVIT_PLAYLIST_ID: 'PLmjLQh7CaAEgr2eT8pqribZhKA7pS6VY2',
  YOUTUBE_EPHESIANS_PLAYLIST_ID: 'PLmjLQh7CaAEj07TcExIwkrB2sIkx-6Tmy',
  YOUTUBE_MYSTERIOUS_SECRETS_PLAYLIST_ID: 'PLmjLQh7CaAEih9q1i7V4CUZWi4770ztAM',
  YOUTUBE_CROSS_REMEMBER_PLAYLIST_ID: 'PLmjLQh7CaAEgB5qAp-wVZfZs6Y22WUAqF',
  YOUTUBE_ARSEN_PLAYLIST_ID_RU: 'PLLvSwT4yOq7v4g4N1hpQmkRTLxS8XChtJ',
  YOUTUBE_XNKO_PLAYLIST_ID_RU: 'PLLvSwT4yOq7tL-jvHQnfvmSjIqK0WzlX8',
  YOUTUBE_MILAZIM_PLAYLIST_ID_RU: 'PLLvSwT4yOq7vzzXA-K4BK3f91ghcN0Yf3',
  YOUTUBE_ARMEN_PLAYLIST_ID_RU: 'PLLvSwT4yOq7v0aLQtLGtC1k2Waua2ytnL',
  YOUTUBE_PEOPLE_QUESTIONS: 'PLcitxLO6RSoTw8hOyh0TS_pI23IOE1eFp',
  YOUTUBE_BELIEVE_AND_RECOGNIZE: 'PLcitxLO6RSoTQLtIqTz97x-l60yEGY8-W',
  YOUTUBE_GOD_KINGDOM: 'PLcitxLO6RSoRZjxFcThn9FOHrCvh6XTcT',
};

export const YOUTBE_PLAYLIST_VIDEOS = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&key=${YOUTUBE_API_KEY}`;
export const YOUTUBE_PLAYLIST = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${YOUTUBE_VIDEOS_CHANNEL_ID}&key=${YOUTUBE_API_KEY}`;
export const YOUTUBE_FACETS = `https://youtube.googleapis.com/youtube/v3/activities?part=snippet%2C%20contentDetails&channelId=${YOUTUBE_FACETS_CHANNEL_ID}&key=${YOUTUBE_API_KEY}`;

export enum APP_LANGUAGES_ENUM {
  am = 'am',
  en = 'en',
  ru = 'ru',
  ku = 'ku',
}
export const LANGUAGES_SHORTCUTS = {
  am: 'Հայ',
  en: 'En',
  ru: 'Рус',
  ku: 'Kurd',
};
export const APP_DEFAULT_LANGUAGE = APP_LANGUAGES_ENUM.am;
export const APP_LANGUAGES: APP_LANGUAGES_ENUM[] = [APP_LANGUAGES_ENUM.am, APP_LANGUAGES_ENUM.ku, APP_LANGUAGES_ENUM.en, APP_LANGUAGES_ENUM.ru];

export const LESSON_TRANSLATIONS = {
  [APP_LANGUAGES_ENUM.am]: 'Հոգևոր դասեր',
  [APP_LANGUAGES_ENUM.ku]: 'Darsên ruhanî',
  [APP_LANGUAGES_ENUM.ru]: 'Духовное учение',
  [APP_LANGUAGES_ENUM.en]: 'Spiritual lessons',
};

export const VIDEOS_TRANSLATIONS = {
  [APP_LANGUAGES_ENUM.am]: 'Հայերեն',
  [APP_LANGUAGES_ENUM.ru]: 'Русский',
};

export const TRANSLATIONS_IDS_MAP: Record<APP_LANGUAGES_ENUM, number> = {
  [APP_LANGUAGES_ENUM.am]: 1,
  [APP_LANGUAGES_ENUM.ku]: 5,
  [APP_LANGUAGES_ENUM.ru]: 2,
  [APP_LANGUAGES_ENUM.en]: 3,
};

export const isDev = process.env.NODE_ENV === 'development';

export const BASE_API = isDev ? 'http://localhost:3001' : 'https://api.biblecbjc.com';
export const STEAM_BASE_API = isDev ? 'http://localhost:3002' : 'https://api.biblecbjc.com';

export const BIBLE_API = `${BASE_API}/bible`;
export const AUTH_API = `${BASE_API}/api/auth`;
export const CONTACT_US_API = `${BASE_API}/api/customer/customer-email`;
export const MEMOS_API = `${BASE_API}/api/memos`;
export const BOOKMARKS_API = `${BASE_API}/api/bookmarks`;
export const NOTES_API = `${BASE_API}/api/notes`;
export const STREAM_API = `${STEAM_BASE_API}/stream`;
