import { useSearchParams } from 'react-router-dom';
import './style.scss';

function ManDirect() {
  const [searchParams] = useSearchParams();
  const currentTranslation = searchParams.get('lang');

  return (
    <div className="man-direct">
      <img
        className="man-direct__img"
        src={`./images/manDirect/${currentTranslation}.jpg`}
        alt=""
      />
    </div>
  );
}

export default ManDirect;
