import { useState } from 'react';
import { useLanguage } from 'core/hooks';
import Image from 'components/common/image';
import Overlay from 'components/common/overlay';
import { useAppDispatch } from 'store/hooks';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import { APP_LANGUAGES, APP_LANGUAGES_ENUM, LANGUAGES_SHORTCUTS, TRANSLATIONS_IDS_MAP } from 'config/constants';

function AppLanguage() {
  const dispatch = useAppDispatch();
  const { headerLanguageShortcut, setCurrentLanguage } = useLanguage();

  const [showLanguageList, setShowLanguageList] = useState(false);

  const setLanguage = (lang: APP_LANGUAGES_ENUM) => {
    setCurrentLanguage(lang);
    setShowLanguageList(false);
    const relatedTranslationId = TRANSLATIONS_IDS_MAP[lang];
    dispatch(bibleTranslationsSlice.actions.addTranslation(relatedTranslationId));
  };

  const openLanguageList = () => {
    setShowLanguageList(true);
  };

  const outsideClick = () => {
    setShowLanguageList(false);
  };

  return (
    <div className="navbar__language">
      <button
        onClick={openLanguageList}
        className="navbar__language__btn"
      >
        <Image
          name="language.svg"
          type="icons"
        />
        { headerLanguageShortcut }
      </button>
      { showLanguageList && (
      <ul className="navbar__language__list">
        { APP_LANGUAGES.map(lang => (
          <li
            key={lang}
            onClick={() => setLanguage(lang)}
            className={
              headerLanguageShortcut === lang
                ? 'navbar__language__item active'
                : 'navbar__language__item'
            }
          >
            { LANGUAGES_SHORTCUTS[lang] }
          </li>
        ))}
      </ul>
      )}
      {showLanguageList && <Overlay outsideClick={outsideClick} />}
    </div>
  );
}

export default AppLanguage;
