import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Menu, Typography, Space, Button } from 'antd';
import { useLanguage, useQueryParams } from 'core/hooks';
import Image from 'components/common/image';
import Flex from 'components/common/Flex';
import QrCodeModal from 'components/common/Modals/QrCodeModal';
import { FaBible } from 'react-icons/fa';
import { CiStreamOn } from 'react-icons/ci';
import { MdAudiotrack, MdOutlineOndemandVideo } from 'react-icons/md';
import { IoLibrary } from 'react-icons/io5';
import { IconType } from 'react-icons';

import { generateSidebarItems, getItemRoute, KEY_TO_ROUTE } from './sidebarHelpers';
import { getKeyByValue, constructSidebarActiveKey } from './utils';

import './style.scss';

const { Text } = Typography;
interface IIcons {
  [key: string]: IconType;
}
function SideBar() {
  const [collapsed, setCollapsed] = useState(false);
  const queries = useQueryParams();
  const [defaultSelectedKeys, setDefaultSelectedKey] = useState<string[]>([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [chosenQr, setChosenQr] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const lang = queries.get('lang');
  const lessonLang = queries.get('lessonlang');
  const { currentLanguage } = useLanguage();
  const sidebarItems = generateSidebarItems(currentLanguage);

  const emptyMenuActiveItems = () => {
    setDefaultOpenKeys([]);
    setDefaultSelectedKey([]);
  };

  const onClick: MenuProps['onClick'] = e => {
    const navigateTo = getItemRoute(e.key as keyof typeof KEY_TO_ROUTE);
    emptyMenuActiveItems();
    navigate(navigateTo);
  };

  const icons: IIcons = useMemo(() => ({
    MdOutlineOndemandVideo,
    MdAudiotrack,
    FaBible,
    IoLibrary,
    CiStreamOn,
  }), []);

  const handleStoreIconClick = (e: any) => {
    if (e.target.src.includes('app-store')) {
      setChosenQr('app-store.png');
    } else {
      setChosenQr('google-play.png');
    }

    setShowQrCodeModal(true);
  };

  const sidebarItemsWithIcons = useMemo(() => {
    const items = sidebarItems.map(item => {
      const Icon = icons[item.icon];
      return {
        ...item,
        icon: <Icon style={{ fontSize: '18px', color: 'var(--main-primary)' }} />,
      };
    });
    return items;
  }, [icons, sidebarItems]);
  useEffect(() => {
    const path = location.pathname + location.search;
    const keyRoute = getKeyByValue(KEY_TO_ROUTE, path);
    if (!keyRoute) return;
    setDefaultSelectedKey(prevArray => [...prevArray, keyRoute]);
    const openKeys = constructSidebarActiveKey(keyRoute);
    openKeys.pop();
    setDefaultOpenKeys(prevArray => [...prevArray, ...openKeys]);
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/') {
      emptyMenuActiveItems();
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/videos') {
      const selectedKey = `videos/preaching/${lang}`;
      const openKeys = constructSidebarActiveKey(selectedKey);
      setDefaultSelectedKey(prevArray => [...prevArray, selectedKey]);
      setDefaultOpenKeys(prevArray => [...prevArray, ...openKeys]);
    } else if (location.pathname === '/learning') {
      const selectedKey = `videos/lessons/${lessonLang}`;
      const openKeys = constructSidebarActiveKey(selectedKey);
      setDefaultSelectedKey(prevArray => [...prevArray, selectedKey]);
      setDefaultOpenKeys(prevArray => [...prevArray, ...openKeys]);
    }
  }, [lang, lessonLang, location.pathname]);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, []);
  return (
    <div className="side-bar-container">
      {!['/bible', '/login', '/signup', '/reset-password'].includes(location.pathname) && (
        <div className={collapsed ? 'side-bar' : 'side-bar side-bar__opened'}>
          <Menu
            className="side-bar__menu"
            mode="inline"
            inlineCollapsed={collapsed}
            key={`${defaultSelectedKeys.length}-${defaultOpenKeys[0]}`}
            defaultSelectedKeys={[...defaultSelectedKeys]}
            defaultOpenKeys={[...defaultOpenKeys]}
            items={sidebarItemsWithIcons}
            onClick={onClick}
          />
          {!collapsed && (
            <div>
              <Flex
                className="side-bar__footer"
                padding="var(--gap-main)"
                alignItems="center"
              >
                <Flex
                  marginRight="var(--gap-small)"
                >
                  <Button
                    style={{ padding: 0 }}
                    size="large"
                    type="ghost"
                    onClick={handleStoreIconClick}
                  >
                    {['google-play.png', 'app-store.png'].map(name => (
                      <Image
                        key={name}
                        name={name}
                        type="images"
                        marginLeft="var(--gap-small)"
                      />
                    ))}
                  </Button>
                </Flex>
                <Space.Compact size="small" direction="vertical">
                  <Text strong italic>{currentLanguage.getMobileApp}</Text>
                  <Text type="secondary" strong italic>© CBJC</Text>
                </Space.Compact>
              </Flex>

            </div>
          )}
        </div>
      )}
      <QrCodeModal
        open={showQrCodeModal}
        onCancel={() => setShowQrCodeModal(false)}
        chosenQr={chosenQr}
      />
    </div>
  );
}

export default SideBar;
