import { Link } from 'react-router-dom';
import Image from 'components/common/image';
import { Typography } from 'antd';

const { Paragraph } = Typography;

function Logo({
  isMobileDevice,
  headerText,
  headerShortText,
}: {
  isMobileDevice: boolean;
  headerText: string;
  headerShortText: string;
}) {
  return (
    <div className="navbar__logo">
      <Link to="/">
        <div className="navbar__logo__container">
          <Image
            name="logo.png"
            type="images"
          />
          {!isMobileDevice ? (
            <Paragraph
              style={{
                fontFamily: 'var(--font-bold)',
                marginLeft: 'var(--gap-main)',
                marginBottom: 0,
                color: 'var(--main-white)',
              }}
            >
              <span>
                {headerText}
              </span>
            </Paragraph>
          ) : (
            <Paragraph
              style={{
                fontFamily: 'var(--font-bold)',
                marginLeft: 'var(--gap-main)',
                marginBottom: 0,
                color: 'var(--main-white)',
              }}
            >
              <span>
                {headerShortText}
              </span>
            </Paragraph>
          )}
        </div>
      </Link>
    </div>
  );
}

export default Logo;
