import { useMemo } from 'react';
import { TypeSearchResult, ISearchResult } from 'types';
import { Typography, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import '../style.scss';

const { Paragraph } = Typography;
interface IContentProps {
  backToWordsList: (back: boolean) => void;
  setSearchItem: (searchResultItem: ISearchResult) => void;
  selectedKey: string;
  results: TypeSearchResult;
}

export default function ResultsGroupItems({
  backToWordsList,
  setSearchItem,
  selectedKey,
  results,
}: IContentProps) {
  const searchedWordResults = useMemo(() => (
    results[selectedKey]?.map(item => ({
      ...item,
      text: item?.text?.replace(
        selectedKey,
        `<span style="padding: 0 var(--gap-small); background-color: var(--main-primary); font-family: 'var(--font-bold)'; color: white">
          ${selectedKey}
        </span>`,
      ),
    }))
  ), [results, selectedKey]);

  return (
    <div className="search__result-content">
      <div className="search__result-content__navigation">
        <Button
          shape="circle"
          onClick={() => backToWordsList(false)}
        >
          <LeftOutlined />
        </Button>
        <Paragraph style={{ margin: 0, paddingLeft: 10 }} strong>
          {selectedKey}
        </Paragraph>
      </div>
      <div className="search__result-content__items">
        {searchedWordResults?.map(result => (
          <p
            key={result.text}
            className="search__result-content__item"
            onClick={() => setSearchItem(result)}
          >
            <span><b>{`${result.book.title} ${result.chapter.number}:`}</b></span>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: result.text }} />
          </p>
        ))}
      </div>
    </div>
  );
}
