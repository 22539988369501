import { IoCreate } from 'react-icons/io5';
import { Button } from 'antd';
import { useLanguage } from 'core/hooks';
import { memo } from 'react';

interface IVerseActions {
  handleMemoClick: () => void;
}

function VerseActions({
  handleMemoClick,
}: IVerseActions) {
  const { currentLanguage } = useLanguage();
  return (
    <div className="verse-actions">
      <Button
        type="primary"
        onClick={handleMemoClick}
        style={{ fontWeight: 'bold', alignItems: 'center', display: 'flex', margin: 20 }}
      >
        <IoCreate
          fontSize="var(--font-mid-size)"
          color="var(--main-white)"
          style={{ marginRight: 10 }}
        />
        {currentLanguage.memos}
      </Button>
    </div>
  );
}

export default memo(VerseActions);
