export default {
  title: 'CHRISTIAN CONFESSION',
  sections: [
    [
      {
        title: 'The Holy Bible',
        description: 'is the Word and Mind of God spoken by people, which has concluded in the Gospel. The Bible is perfect, touching every human need and feeling.',
      },
      {
        title: 'God',
        description: `
          is the trinity of Father, Son and Holy Spirit.
          He is one, unique and united and there is no other God besides Him.
          He is perfect in love, holiness, ability, knowledge and wisdom.
          He has created the heavens, earth and every being in them.
          Every being obtain their ability to live and find their ends through Him.
          He is everywhere, watching everything. He is forever the same and never changes.
          He is with everyone whom has created, and inside of the people who have chosen Him.
        `,
      },
      {
        title: 'The man',
        description: `
          is the generation of Adam had birth from Eva. They are both created by the hand of God.
          Man was created in image and likeness of God, to dominate as his Creator.
          But Adam broke the commandment and became sinful, because he sinned.
          He accepted the lawlessness from Satan and it changed holly nature in him.
          He went far a way from the life’s law, by it human was deprived of God’s glory.
          In him entered the law of death, and he became addicted to the sin, viz to wrongdoing (violation of right).
          Human being become culpable and mortal, his nature is sinful.
          And his corrupted essence became the inheritance of human.      
        `,
      },
      {
        title: 'God\'s law',
        description: `
          is holy and incomparably high from every kind of laws.
          Through it is revealed the right and sin and it directs man to the Christ.
        `,
      },
    ],
    [
      {
        title: 'Jesus Christ',
        description: `
          is one of the persons of Holy Trinity, the only Son of God’s essence.
          He came out from God, not created. With God's power, was born from virgin.
          He got human being's nature and body, and was worshiped in the same body.
          He was tempted as we, but without sinning, and as Holy Lamb has been sacrificed for us.
          By His holly blood He made atonement for our sins and sentenced the sin and death in His body.
          He opened the way of salvation through the crucifixion and rose from death with the same body.
          He was taken up to the heavens and sat down on the right hand of God. Heavens and earth were given His hand.
          He is the Savior of whole world, that who believes in Him be free of Evil.
          Jesus would return with Father’s glory and will raise the Holy dead.
          With them the Saints will be caught up to the Lord from earth.
          At the end of century our Lord Jesus will bring to the light everything what men did.
          Righteous people will go to eternal life, and unjust people will go to eternal torture.
          Lord Jesus will reign, and His authority will not end ever.
          God will take away the tears and death, with our ayes we will see the throne of our Lord.      
        `,
      },
      {
        title: 'The Holy Spirit',
        description: `
          is one of the persons of God's Holy Trinity – forever inseparable.
          He is equal with God and always in the same position, He is completely holy and never is changing.
          The Lord Jesus Christ is baptizing the man, saved by Him, in Holy Spirit.      
        `,
      },
      {
        title: 'The Church',
        description: `
          is the group of men who repented, saved and sanctified by Jesus Christ.
          So that they reach to the main purpose, to be alike of God and to His holy image.
          In every century the Church will witness about Jesus and preach the Gospel to the men.
          To baptizes believers and commit Lord’s Supper.      
        `,
      },
    ],
  ],
};
