import { useMemo } from 'react';
import { IBibleBook, TESTAMENTS } from 'types';
import Select from 'antd/es/select';
import { useLanguage } from 'core/hooks';

interface IBookListProps {
  defaultBook?: number;
  booksListData: IBibleBook[];
  onBookSelect: (number: number) => void;
}

interface ISelectOptions {
  label: string;
  options: { value: number; label: string }[];
}

function BooksList({ defaultBook, booksListData, onBookSelect }: IBookListProps) {
  const { currentLanguage } = useLanguage();

  const booksData = useMemo(
    () => booksListData.reduce((result, { number, title, testament }) => {
      if (testament === TESTAMENTS.OLD) result[0].options.push({ value: number, label: title });
      if (testament === TESTAMENTS.NEW) result[1].options.push({ value: number, label: title });
      return result;
    }, [{ label: currentLanguage.old_testament, options: [] },
      { label: currentLanguage.new_testament, options: [] }] as ISelectOptions[])
      .filter(({ options }) => Boolean(options.length)),
    [currentLanguage, booksListData],
  );

  return (
    <Select
      className="bible-navigation__books-select-element"
      showSearch
      defaultValue={defaultBook}
      placeholder="Select a person"
      optionFilterProp="children"
      onChange={value => onBookSelect(value)}
      filterOption={
        (input, option) => (option?.label ?? '')
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={booksData}
    />
  );
}

export default BooksList;
