import { YOUTUBE_PLAYLIST_IDS } from 'config/constants';
import { ITranslations } from 'types';
import translations from 'config/languages';

const {
  YOUTUBE_MILAZIM_PLAYLIST_ID,
  YOUTUBE_MILAZIM_PLAYLIST_ID_RU,
  YOUTUBE_GOD_KINGDOM,
} = YOUTUBE_PLAYLIST_IDS;

const number = 0;

const VIDEOS_ROUTE_ARM = `/videos?lang=am&playlistId=${YOUTUBE_MILAZIM_PLAYLIST_ID}`;
const VIDEOS_ROUTE_RU = `/videos?lang=ru&playlistId=${YOUTUBE_MILAZIM_PLAYLIST_ID_RU}`;
const LEARNING_ROUTE_ARM = `/learning?lessonlang=am&number=${number}`;
const LIB_LEARNING_ROUTE_ARM = `/lessons?lessonlang=am&number=${number}`;
const LEARNING_ROUTE_KU = `/learning?lessonlang=ku&number=${number}`;
const LIB_LEARNING_ROUTE_KU = `/lessons?lessonlang=ku&number=${number}`;
const LEARNING_ROUTE_RU = `/learning?lessonlang=ru&number=${number}`;
const LIB_LEARNING_ROUTE_RU = `/lessons?lessonlang=ru&number=${number}`;
const LEARNING_ROUTE_EN = `/learning?lessonlang=en&number=${number}`;
const LIB_LEARNING_ROUTE_EN = `/lessons?lessonlang=en&number=${number}`;

export const KEY_TO_ROUTE = {
  'videos/preaching/am': VIDEOS_ROUTE_ARM,
  'videos/preaching/ru': VIDEOS_ROUTE_RU,
  'videos/lessons/am': LEARNING_ROUTE_ARM,
  'videos/lessons/ku': LEARNING_ROUTE_KU,
  'videos/lessons/ru': LEARNING_ROUTE_RU,
  'videos/lessons/en': LEARNING_ROUTE_EN,
  'videos/programs': `/programs?playlistId=${YOUTUBE_GOD_KINGDOM}`,
  bible: '/bible',
  educationalLessons: LEARNING_ROUTE_ARM,
  'library/arm-lib/lessons': LIB_LEARNING_ROUTE_ARM,
  'library/arm-lib/church-acts': '/church-acts',
  'library/arm-lib/important-to-know-am/call-am': '/gods-calling?lang=am',
  'library/kurdish-lib/lessons': LIB_LEARNING_ROUTE_KU,
  'library/kurdish-lib/important-to-know-ku/call-ku': '/gods-calling?lang=ku',
  'library/russian-lib/lessons': LIB_LEARNING_ROUTE_RU,
  'library/russian-lib/important-to-know-ru/call-ru': '/gods-calling?lang=ru',
  'library/english-lib/important-to-know-en/call-en': '/gods-calling?lang=en',
  'library/arm-lib/important-to-know-am/man-direct-am': '/man-direct?lang=am',
  'library/kurdish-lib/important-to-know-ku/man-direct-ku': '/man-direct?lang=ku',
  'library/russian-lib/important-to-know-ru/man-direct-ru': '/man-direct?lang=ru',
  'library/english-lib/lessons': LIB_LEARNING_ROUTE_EN,
  'library/english-lib/important-to-know-en/man-direct-en': '/man-direct?lang=en',
  'audios/arm-audio': '/audio?lang=am',
  'audios/kurd-audio': '/audio?lang=ku',
  'library/kurdish-lib/ancestors-proverbs': '/ancestors-proverbs',
};

export const generateSidebarItems = (language: ITranslations) => ([
  {
    label: language.videos,
    key: 'videos',
    icon: 'MdOutlineOndemandVideo',
    children: [
      {
        label: language.preaching,
        key: 'videos/preaching',
        children: [
          {
            label: language.preachesAm,
            key: 'videos/preaching/am',
          },
          {
            label: language.preachesRu,
            key: 'videos/preaching/ru',
          },
        ],
      },
      {
        label: language.educational_lessons,
        key: 'videos/lessons',
        children: [
          {
            label: language.lessonsAm,
            key: 'videos/lessons/am',
          },
          {
            label: language.lessonsKu,
            key: 'videos/lessons/ku',
          },
          {
            label: language.lessonsRu,
            key: 'videos/lessons/ru',
          },
          {
            label: language.lessonsEn,
            key: 'videos/lessons/en',
          },
        ],
      },
      {
        label: language.kurdish_programs,
        key: 'videos/programs',
      },
    ],
  },
  {
    label: language.audios,
    key: 'audios',
    icon: 'MdAudiotrack',
    children: [
      {
        label: language.songbookAm,
        key: 'audios/arm-audio',
      },
      {
        label: language.songbookKu,
        key: 'audios/kurd-audio',
      },
    ],
  },
  {
    label: language.bible,
    key: 'bible',
    icon: 'FaBible',
  },
  {
    label: language.library,
    key: 'library',
    icon: 'IoLibrary',
    children: [
      {
        label: translations.am.menuItemLang,
        key: 'library/arm-lib',
        children: [
          {
            label: translations.am.spiritualLesson,
            key: 'library/arm-lib/lessons',
          },
          {
            label: translations.am.churchActs,
            key: 'library/arm-lib/church-acts',
          },
          {
            label: translations.am.importantToKnow,
            key: 'library/arm-lib/important-to-know-am',
            children: [
              {
                label: translations.am.call,
                key: 'library/arm-lib/important-to-know-am/call-am',
              },
              {
                label: translations.am.manDirect,
                key: 'library/arm-lib/important-to-know-am/man-direct-am',
              },
            ],
          },
        ],
      },
      {
        label: translations.ku.menuItemLang,
        key: 'library/kurdish-lib',
        children: [
          {
            label: translations.ku.spiritualLesson,
            key: 'library/kurdish-lib/lessons',
          },
          {
            label: 'MESELE-METELOKÊN KAL Û BAVAN',
            key: 'library/kurdish-lib/ancestors-proverbs',
          },
          {
            label: translations.ku.importantToKnow,
            key: 'library/kurdish-lib/important-to-know-ku',
            children: [
              {
                label: translations.ku.call,
                key: 'library/kurdish-lib/important-to-know-ku/call-ku',
              },
              {
                label: translations.ku.manDirect,
                key: 'library/kurdish-lib/important-to-know-ku/man-direct-ku',
              },
            ],
          },
        ],
      },
      {
        label: translations.ru.menuItemLang,
        key: 'library/russian-lib',
        children: [
          {
            label: translations.ru.spiritualLesson,
            key: 'library/russian-lib/lessons',
          },
          {
            label: translations.ru.importantToKnow,
            key: 'library/russian-lib/important-to-know-ru',
            children: [
              {
                label: translations.ru.call,
                key: 'library/russian-lib/important-to-know-ru/call-ru',
              },
              {
                label: translations.ru.manDirect,
                key: 'library/russian-lib/important-to-know-ru/man-direct-ru',
              },
            ],
          },
        ],
      },
      {
        label: translations.en.menuItemLang,
        key: 'library/english-lib',
        children: [
          {
            label: translations.en.spiritualLesson,
            key: 'library/english-lib/lessons',
          },
          {
            label: translations.en.importantToKnow,
            key: 'library/english-lib/important-to-know-en',
            children: [
              {
                label: translations.en.call,
                key: 'library/english-lib/important-to-know-en/call-en',
              },
              {
                label: translations.en.manDirect,
                key: 'library/english-lib/important-to-know-en/man-direct-en',
              },
            ],
          },
        ],
      },
    ],
  },
]);

type KeyToRouteKey = keyof typeof KEY_TO_ROUTE;

export const getItemRoute = (key: KeyToRouteKey) => KEY_TO_ROUTE[key];
