import { BIBLE_API } from 'config/constants';
import { IBibleBook } from 'types';

import Fetch from '../fetch';

export const getBooksListByTranslationId = async (translationId: number): Promise<IBibleBook[]> => {
  const response = await Fetch.get(`${BIBLE_API}/books?translation_id=${translationId}`, {
    isCache: true,
    cache: 'force-cache',
  });

  return response as IBibleBook[];
};

export const getBookByNumber = async (bookNumber: number, translation_id: number): Promise<any> => {
  const response = await fetch(`${BIBLE_API}/books/${translation_id}/${bookNumber}`);
  const bookByNumber = await response.json();
  return bookByNumber;
};

export const smt = {};
