/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INote } from 'types';

import {
  editNote,
  removeNote,
  createNote,
  getCertainNotes,
} from './actions';

interface INotesState {
  notes: INote[];
  loading?: boolean;
}

const initialState: INotesState = {
  notes: [],
  loading: false,
};

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(createNote.pending, state => {
        state.loading = true;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.loading = false;
        const createdNote = action.payload;
        state.notes = [
          createdNote,
          ...state.notes,
        ];
      })
      .addCase(createNote.rejected, state => {
        state.loading = false;
      })
      .addCase(getCertainNotes.pending, state => {
        state.loading = true;
      })
      .addCase(getCertainNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(getCertainNotes.rejected, state => {
        state.loading = false;
      })
      .addCase(editNote.pending, state => {
        state.loading = true;
      })
      .addCase(editNote.fulfilled, (state, action) => {
        state.loading = false;
        const editedNote = action.payload;
        if (!editedNote) {
          return;
        }

        state.notes = state.notes.map(note => (note._id === editedNote._id ? editedNote : note));
      })
      .addCase(editNote.rejected, state => {
        state.loading = false;
      })
      .addCase(removeNote.pending, state => {
        state.loading = true;
      })
      .addCase(removeNote.fulfilled, (state, action) => {
        state.loading = false;
        const { meta } = action;
        const index = state.notes.findIndex(note => note._id === meta.arg);
        if (index !== -1) {
          state.notes.splice(index, 1);
        }
      })
      .addCase(removeNote.rejected, state => {
        state.loading = false;
      });

    // .addCase(removeBookmark.pending, state => {
    //   state.loading = true;
    // })
    // .addCase(removeBookmark.fulfilled, (state, action) => {
    //   state.loading = false;
    //   const { meta } = action;
    //   const index = state.bookmarks.findIndex(bookmark => bookmark._id === meta.arg.id);
    //   if (index !== -1) {
    //     state.bookmarks.splice(index, 1);
    //   }
    // })
    // .addCase(removeBookmark.rejected, state => {
    //   state.loading = false;
    // });
  },
});

export default notesSlice;
