import { RootState } from 'store/types';
import { IMemo } from 'types';

interface IMemosSliceState {
  memos: IMemo[];
  memo: IMemo;
  loading?: boolean;
}

export const getMemosState = (state: RootState): IMemosSliceState => state.memos;
export const getUserMemos = (state: RootState): IMemo[] => getMemosState(state).memos;
export const getMemoById = (state: RootState): IMemo => getMemosState(state).memo;
export const getFirstMemo = (state: RootState): IMemo => getMemosState(state).memos[0];
