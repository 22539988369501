import { useMemo, useState } from 'react';
import { Button, Popconfirm, Collapse } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import { useMemoSelector } from 'store/hooks';
import { getBookmarksByTranslationId, getBookmarksTranslationIds } from 'store/selectors/bookmarks';
import { removeBookmark } from 'store/slices/bookmarks/actions';
import { IBookmark } from 'types';
import Flex from 'components/common/Flex';
import { useDispatch } from 'react-redux';
import { getBibleTranslationsIds } from 'store/selectors/bibleTranslations';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import { useBibleTranslations, useLanguage } from 'core/hooks';
import BookmarksEmptyState from 'components/common/emptyState/bookmarksEmptyState';

import useBookNavigation from './useBookNavigation';

const { Panel } = Collapse;

function Bookmarks() {
  const dispatch = useDispatch();
  const { currentLanguage } = useLanguage();
  const { bibleTranslations } = useBibleTranslations();
  const { setCurrentBook, setCurrentChapter, setCurrentVerse } = useBookNavigation();
  const bookmarksTranslationIds = useMemoSelector(getBookmarksTranslationIds);
  const selectedTranslations = useMemoSelector(getBibleTranslationsIds);
  const [selectedBookmarksTranslationId, setSelectedBookmarksTranslationId] = useState<number>(bookmarksTranslationIds[0]);
  const bookmarks = useMemoSelector(getBookmarksByTranslationId(selectedBookmarksTranslationId));

  const handleBookmarkOpen = (bookmark: IBookmark) => {
    const { bookNumber, chapterNumber, verseIndex, translationId } = bookmark;
    if (!selectedTranslations.includes(translationId)) {
      dispatch(bibleTranslationsSlice.actions.addTranslation(translationId));
    }

    setCurrentBook(bookNumber);
    setCurrentChapter(chapterNumber);
    setCurrentVerse(verseIndex);
  };

  const handleRemove = (id: string) => {
    dispatch(removeBookmark({ id }));
  };

  const translationsByGroup = useMemo(() => (
    bibleTranslations.filter(
      ({ id }) => bookmarksTranslationIds.includes(id),
    )
  ), [bibleTranslations, bookmarksTranslationIds]);

  return (
    <div style={{ height: 'var(--full)' }}>
      {!translationsByGroup?.length ? (
        <BookmarksEmptyState />
      ) : (
        <Collapse
          accordion
          className="bookmarks__accordion"
          bordered={false}
          defaultActiveKey={[selectedBookmarksTranslationId]}
          onChange={translationId => setSelectedBookmarksTranslationId(+translationId)}
        >
          {translationsByGroup.map(tr => (
            <Panel
              key={tr.id}
              header={`${tr.name} ${tr.translator_name}`}
            >
              {bookmarks?.map((bookmark: IBookmark) => (
                <Flex
                  key={bookmark._id}
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom="var(--gap-main)"
                  marginTop="var(--gap-main)"
                >
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => handleBookmarkOpen(bookmark)}
                  >
                    {`${bookmark.title} ${bookmark.chapterNumber}:${bookmark.verseIndex}`}
                  </Button>
                  <Popconfirm
                    title={currentLanguage.bookmark_delete_confirmation}
                    onConfirm={() => handleRemove(bookmark._id)}
                    okText={currentLanguage.agree}
                    cancelText={currentLanguage.cancel}
                  >
                    <Button
                      style={{
                        marginLeft: 'var(--gap-main)',
                        alignItems: 'center',
                        paddingBottom: 0,
                      }}
                    >
                      <AiFillDelete />
                    </Button>
                  </Popconfirm>
                </Flex>
              ))}
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
}

export default Bookmarks;
