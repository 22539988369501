export default [
  {
    src: 'Dora min girt ye pêlên be\'rê.mp3',
    name: 'Dora min girt ye pêlên be\'rê',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Ji cimat',
    lyrics: `
  <b>Dora min girt ye pêlên be'rê</b>
  <span class="about">Nivîisk'ar - Milazim Rasoyan Miqam - Ji cimat</span>
  
  1. Dora min girt ye pêlên be'rê,
  Dilê min şkestye mîna darê,
  Lê dengekî delal şîrin,
  Gazî dike, were cem min.
  2. Ruhê min t'imê melûl e,
  Rihetyê xwer'a diger'e,
  Û ew dengê qenc û şîrin,
  Gazî dike, were cem min.
  3. Hatin bîra min gunehên min,
  Min dêşîne îsafa min,
  Tirsa mirinê hate ser min,
  Îsa deng da, were cem min.
  4. Dinyayê derbaz be were,
  Jîyîna xwe unda neke,
  Îsa Xûn rêtye bona te,
  Dilê xwe tu ber wî veke.
  5. Xudan Îsa hatime cem te,
  Dilê min tu paqij bike,
  Afû bike gunehên min,
  Û rizgar bike tu ruhê min.
  `,
  },
  {
    src: 'Emê malên ezmênda bin.mp3',
    name: 'Emê malên ezmênda bin',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b>Emê malên ezmênda bin</b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1. Ser vî erdî em k’oçer in, em xerîb in li vê dinê,
  Ji me her kes jî wê her’e, û namîne vê dinyayê,
  T’enê ezmênda- cem Xwedê, / rihetî ye, -2
  Li wir her der paqijî ye, / pîrozî ye. -2
  Wek’ - Emê wir bin-2, Emê malên ezmênda bin,
  Warê jorin-2, Heta-ha-tê emê wir bin.
  2. Civîna Mesîhe pîroz, wir t’op dibe û dimîne,
  Em in kesên wê civînê: mala Xwedê, lawên Xwedê
  Wira hert’im haştî heye, / şer’ t’une ye, -2
  Îsa Xudan wir P’adşa ye, / rehm wir heye. -2
  3. Welatê meyî ezmanî, zef bedew e gelek rind e,
  Hebûka wira narize, Xwedê merd e, ha wê bide,
  Rewş û rûmet bi awaz in, / ronayî ye, -2
  Binelyên wir pîroz in, / xwedêtî ye. -2
  `,
  },
  {
    src: 'Eva roja Mizgînyê ye.mp3',
    name: 'Eva roja Mizgînyê ye',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b>Eva roja Mizgînyê ye</b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Eva roja Mizgînyê ye,
  Mizgînya Încîla Xwedê ye,
  Mesîh rizgarî anye,
  Ew boy her miletan e.
  Wek’ De qebûl ke vê Mizgînyê,
  Ev roj wê her’in derbaz bin,
  Xwedê me wê ji ezmanan bê,
  De rabe qebûl ke Kur'ê Xwedê.
  2) Mesîh e Kur'ê Xwedê,
  Hakimê ezmên û erdê,
  K’ê qebûl ke wê aza be,
  Cyê wî wê buhiştê be.
  `,
  },
  {
    src: 'Îsa binyatêda Xwedê bû.mp3',
    name: 'Îsa binyatêda Xwedê bû',
    author: 'Milazim Rasoyan',
    artist: 'Gayanê Badalyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b>Îsa binyatêda Xwedê bû</b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Îsa binyatêda Xwedê bû,
  Her alîva wek Bavê bû,
  Pey nek’et weke Xwedê be,
  Xwe şkênand ku meriv be,
  Hat dinyayê xwe nimiz kir,
  Binyata xulêmda xwe eyan kir,
  Bi xaçê xwe rê hazir kir,
  Lema Xwedê ew bilind kir.
  2) Navekî zor Îsa kirye,
  Bi hikumê xwe emir kirye,
  Ku her çok ber wî bitewe,
  Îqrara rast bi zar bide,
  Ku Îsa ye şîrinê Bavê,
  Ez nak’evim ber dîwanê,
  Bêje: <Ew e Xudanê min,
  Rûmeta Xwedê, jîyîna min>.
  `,
  },
  {
    src: 'Îsa Mesîh zêndî ye.mp3',
    name: 'Îsa Mesîh zêndî ye',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan, Armên Barseqyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
      <b>Îsa Mesîh zêndî ye</b>
      <span class="about">Nivîsk'ar - Milazim Rasoyan
      Miqam - Slava Galoyan</span>
  
      1) Îsa Mesîh zêndî ye,
      Ew P’adşayê nemirî ye,
      Ew nixuryê Xwedê ye,
      Ser her efirînanr’a ye.
      Çi ku li erd - ezmênda ne,
      Bi destê wî efirî ne,
      Xudantî bin, serwêrtî bin,
      T’extp’adşa bin yan hukumet bin.
        Wek’- Şikilê Xwedêyî nek’ifş e,
      Avilheyat ji dêst dik’işe,
      Her çi hene wî çêkirine,
      Çi ku k’ifş e û ne k’ifş e.
      2) Ew berî her tiştî hebû,
      Bi saya wî ew cîwar bûn,
      Bi saya Îsa dimînin,
      Bi wî jîyînê distînin.
      Ew e serê civîna xwe,
      Û Nixurye ji nav miryan,
      Xwedê xwest ku ew t’im pêş be,
      Û binyata Xwedêda be.
      3) Bi destê wî haştî çêbû,
      Boy her kesî xwexa xaç bû,
      Xûna wî guneh afû kir,
      Erd - ezmênda haştî çêkir.
  `,
  },
  {
    src: 'Îsayê Nisretê.mp3',
    name: 'Îsayê Nisretê',
    author: 'Milazim Rasoyan',
    artist: 'Armên Barseqyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Îsayê Nisretê</b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Îsayê Nisretê - 2,
  Dixwazim mîna te bim, Îsayê Nisretê,
  Çawa ku tu yî, dixwazim usa bim,
  Îsayê Nisretê - 2
  2) Îsayê Nisretê - 2,
  Dixwazim t'evî te bim, Îsayê Nisretê,
  K'u derê tu bî, ez jî wê derê bim,
  Îsayê Nisretê - 2
  3) Îsayê Nisretê - 2,
  Miletê min were rehmê, Îsayê Nisretê,
  Dilê wan bik'işîne xwe, gunehên wan afû ke,
  Îsayê Nisretê - 2
    `,
  },
  {
    src: 'Kur’ê Xwedê hat vê dinê.mp3',
    name: 'Kur’ê Xwedê hat vê dinê',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Kur’ê Xwedê hat vê dinê </b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1. Kur’ê Xwedê hat vê dinê, ku merivan ew bê rehmê,
  Li ezmanan ew P’adşa bû, lê ser dinyayê xulam bû,
  Ser vê dinyayê ew ger’ya, k’erema Xwedê pêr’a bû,
  Cin derxistin, kor qenckirin û k’otî jî paqij kirin.
  2. Li ser xaçê birîndar bû, birîndarê huba min bû,
  Xuliqdarê avê av xwest, lê dewsa wê sirke danê,
  T’acê stirî sêrî kirin, t’û dikirinê, qerf pê kirin,
  Pey mirinê defin kirin, kevirê t’irbê jî mor kirin.
  3. Roja sisyan mor xirab bû, kevir ji ber t’irbê hol bû,
  Bav Xwedê mer’a K’erem kir, Îsa ji nav miryan rakir,
  Îsa Xudan me hiz dike, mirin ser wî hikum nake,
  Ewê zûtirekê veger’e, mirin ser me jî nikare.
  `,
  },
  {
    src: 'P\'oşman bûme.mp3',
    name: 'P\'oşman bûme',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b>P'oşman bûme</b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) P'oşman bûme Xwedê min,
  Bibaxşîne gunehên min,
  Destê min bigire Xwedê min,
  Berxê teyî xalifî me.
    Wek’- P'oşman bûme Xwedê min-2,
  Bibaxşîne gunehên min,
  P'oşman bûme Xwedê min
  2) T'ev-hev bûye ruhê min,
  Tarî bûye fikira min,
  K’eser girtye dilê min,
  Û ez niha heznî me.
  3) Naxwazim şaşyan bikim,
  Û bimînim t'im hêsîr,
  Ez bêm cem te hêsa bim,
  Hizkirina te bistirêm.
  4) Alî min bike hişyar bim,
  T’imê gur’a teda bim,
  Jîyîna minî hevk’etî,
  Tuê rast bikî bihuştî.
  `,
  },
  {
    src: 'Xudan tu yî efirandarê.mp3',
    name: 'Xudan tu yî efirandarê',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Xudan tu yî efirandarê </b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Xudan tu yî efirandarê,
  Erd û ezmên û her tiştî,
  T’u qewat nagihîje ya te,
  Zanebûna te bilind e.
    Wek’ Werin hevr’a em bistirên,
  Pesnê Xwedê jorin bidin.
  2) T’u cîk t’une, ku gunehk’ar,
  Xwe ji te li wir veşêre,
  Ç’evê te ser her deran e,
  Cîkî xewle boy te nine.
  3) Xwedê me gelek mezin e,
  Bêy şirîkî bêy heval e,
  Ew yekî merivhiz e,
  Xuliqdarê her milet e.
  `,
  },
  {
    src: 'Xwede.mp3',
    name: 'Xwede',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan, Armên Barseqyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Xwedê </b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Erd û ezman, hemû efirîn hûn ker' bin,
  Ezê rastyê bêjime we hûn guhdar bin,
  Ev gotina min bona Xwedê rehmê ye,
  Ku bizanibin, ew k'î ye û çawa ye.
  2) Xwedê ji Bav, Kur' û Ruhê Pîroz e,
  Ewî yek e, bêy şirîk e, yekbûyî ye,
  Erd - ezmênda mîna wî pîroz t'une,
  Ew e P'adşê-p'adşan û t'imê heye.
  3) Ew Heyînê pêşda hebû, Xwedê bû,
  Hemû heyîn eynî bona wî çêbûn,
  Qewata jîyanê ji wî distînin,
  Û bi wî jî axreta xwe dibînin.
  4) Xwedê ruh e, herî zor e, zane ye,
  Ji her - heyî çawa hebû, usa ye,
  Her çi hene, wî şeş rojanda efirand,
  Erd û ezman, heyînên nav xuliqand.
  5) Xwedê kes e, her deran e, hizkirin e,
  Li ser hemû xuliqyan ew dinihêr'e,
  Ewî t'evî milyak'et û merivan e,
  Li nav ruhê her pîrozên xweda ye.
  `,
  },
  {
    src: 'Xwedê rizgarbûn hazir kir.mp3',
    name: 'Xwedê rizgarbûn hazir kir',
    author: 'Milazim Rasoyan',
    artist: 'Slava Galoyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Xwedê rizgarbûn hazir kir </b>
  <span class="about">Nvîsk'ar - Milazim Rasoyan</span>
  
  1) Xwedê rizgarbûn hazir kir,
  Gazî hemû miletan kir,
  De hûn werin vê qebûl kin,
  Û ruhê xwe unda nekin.
  Ev k’erem ji Bav Xwedê tê,
  Bi qewata Ruhê wî tê,
  Ew ji xaçê Kur’ê wî tê,
  Xwedê yek e, kesên wî sê.
  2) Rizgarbûn sozê Xwedê ye,
  K’ê bawer kir p’ara wî ye,
  Îsa bona hemûyan mir,
  Li bawermendan afû kir.
  Roja sisyan dîsa rabû,
  Bawermendanva eyan bû,
  T’emya mezin wanar’a hişt,
  Û çû li cem Bavê rûnişt.
  3) Ev t’emî ji hizkirina min,
  Her miletan binixêmin,
  Ezê eseyî veger’im,
  T’u car we êt’îm nahêlim.
    P’adşê jorin wê veger’e,
  Mî - bizinan veqetîne,
  Zêndî werin, mirî rabin,
  Û ber mîrê xwe cabdar bin.
  `,
  },
  {
    src: 'Xwedê tu yî eşqa min.mp3',
    name: 'Xwedê tu yî eşqa min',
    author: 'Milazim Rasoyan',
    artist: 'Armên Barseqyan',
    composer: 'Milazim Rasoyan',
    lyrics: `
  <b> Xwedê tu yî eşqa min </b>
  <span class="about">Nivîsk'ar - Milazim Rasoyan</span>
  
  1) Xwedê tu yî eşqa min,
  Gotina te dewleta min,
  Gotina te mîna hewê,
  Bînê dide ruhê min.
    Wek’- K'î min heye ezmanan,
  Ser dinê jî wî dixwazim,
  Kesekî din naxwazim,
  Ez t'enê wî dixwazim.
  2) Xeberên Xwey Xwedê min,
  Ava jîyanê dane min,
  Û qet tiştek mîna wan,
  Hêsabûnê nadine min.
  `,
  },
];
