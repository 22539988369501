import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import Flex from 'components/common/Flex';
import { useQueryParams, useYoutubePlaylistVideos } from 'core/hooks';

import { programsData } from './programsData';
import ProgramsContent from './ProgramsContent';

import './style.scss';

function Programs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queries = useQueryParams();
  const playlistIdFromUrl = queries.get('playlistId') || '';
  const [playlistId, setPlaylistId] = useState(playlistIdFromUrl);
  const {
    playlistVideos,
  } = useYoutubePlaylistVideos({ playlistId });

  const replaceQueries = useCallback((id: string) => {
    navigate({
      pathname,
      search: `playlistId=${id}`,
    });
  }, [navigate, pathname]);

  const handleMenuClick = useCallback(({ key }) => {
    const currentPlaylistId = key;
    setPlaylistId(currentPlaylistId);
    replaceQueries(currentPlaylistId);
  }, [replaceQueries]);

  return (
    <Flex width="var(--full)">
      <ProgramsContent
        programsData={playlistVideos}
      />
      <div className="programs__sider">
        <Menu
          mode="inline"
          key={playlistId}
          items={programsData}
          defaultSelectedKeys={[playlistId]}
          onClick={handleMenuClick}
        />
      </div>
    </Flex>
  );
}

export default Programs;
