import { useCallback, useRef } from 'react';
import Container from 'components/common/container';
import { Typography, Form, Input, Button, notification } from 'antd';
import { useContactUs, useLanguage } from 'core/hooks';
import { openNotificationWithIcon } from 'config/notification';
import type { FormInstance } from 'antd/es/form';
import Flex from 'components/common/Flex';
import { NotificationType } from 'store/slices/auth/types';

import './style.scss';

const { Title } = Typography;
const { TextArea } = Input;

const phoneNumberPattern = /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-\s.]?\d{3}[-\s.]?\d{4}$/;

function ContactUs() {
  const formRef = useRef<FormInstance>(null);
  const { sendEmail, loading } = useContactUs();
  const { currentLanguage } = useLanguage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const onReset = () => {
    formRef.current?.resetFields();
  };

  const onFinish = useCallback((values: {
    name: string;
    email: string;
    phoneNumber: string;
    message: string;
  }) => {
    onReset();
    sendEmail(values).then(res => openNotificationWithIcon({
      api: notificationApi,
      message: 'Message',
      type: res?.type === 'success' ? NotificationType.success : NotificationType.error,
      description: currentLanguage[res?.key],
      placement: 'top',
    }));
  }, [sendEmail, notificationApi, currentLanguage]);

  return (
    <Container>
      <div className="contact-us">
        {notificationContextHolder}
        <Flex marginBottom="var(--gap-large)" justifyContent="center">
          <Title>
            {currentLanguage?.contactUs}
          </Title>
        </Flex>
        <Flex justifyContent="center">
          <div className="contactus__form">
            <Form
              ref={formRef}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: currentLanguage.nameRequireErrorMessage }]}
              >
                <Input placeholder={currentLanguage.name} type="text" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: currentLanguage.emailRequireErrorMessage }]}
              >
                <Input placeholder={currentLanguage.email} type="email" />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                rules={[
                  { required: true, message: currentLanguage.phoneNumberErrorMessage },
                  {
                    pattern: phoneNumberPattern,
                    message: currentLanguage.phoneNumberErrorMessage,
                  },
                ]}
              >
                <Input placeholder={currentLanguage.phoneNumberErrorMessage} />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[{ required: true, message: currentLanguage.messageErrorMessage }]}
              >
                <TextArea placeholder={currentLanguage.messageErrorMessage} rows={4} />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: 'var(--full)' }}
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                >
                  {currentLanguage?.send}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Flex>
      </div>
    </Container>
  );
}

export default ContactUs;
