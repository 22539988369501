import isEmpty from 'lodash/isEmpty';
import { YoutubeService } from 'core/services';
import { IPlaylistData, ISnippet } from 'types';
import { useCallback, useEffect, useState } from 'react';

interface IPlaylistProps {
  playlistId: string | null;
  pageToken?: string | null;
}

const useYoutubePlaylist = ({ playlistId, pageToken }: IPlaylistProps) => {
  const [playlistVideos, setPlaylistVideos] = useState<ISnippet[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>('');
  const [prevPageToken, setPrevPageToken] = useState<string | undefined>('');

  const fetchPlaylists = useCallback((id: string, token: string) => {
    YoutubeService
      .getPlaylistVideos(id, token)
      .then((data: IPlaylistData) => {
        setNextPageToken(data?.nextPageToken);
        setPrevPageToken(data?.prevPageToken);

        const playlistData = data.items.map(({ snippet }) => snippet)
          .filter(item => !isEmpty(item?.thumbnails));
        setPlaylistVideos(playlistData);
      });
  }, []);

  useEffect(() => {
    if (playlistId && pageToken) {
      fetchPlaylists(playlistId, pageToken);
    } else if (playlistId) {
      fetchPlaylists(playlistId, '');
    }
  }, [playlistId, pageToken, fetchPlaylists]);

  const getNextPage = useCallback(() => {
    if (nextPageToken && playlistId) {
      fetchPlaylists(playlistId, nextPageToken);
    }
  }, [fetchPlaylists, nextPageToken, playlistId]);

  const getPrevPage = useCallback(() => {
    if (prevPageToken && playlistId) {
      fetchPlaylists(playlistId, prevPageToken);
    }
  }, [fetchPlaylists, prevPageToken, playlistId]);

  return {
    playlistVideos,
    nextPageToken,
    prevPageToken,
    getNextPage,
    getPrevPage,
  };
};

export default useYoutubePlaylist;
