import { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { getAuthState } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import { useLanguage, useMemos, useMount } from 'core/hooks';
import { IMemo } from 'types';

import { versesMemoReducer, combinedMemoText } from './helpers';
import MemosList from './MemosList';
import MemosCreation from './MemosCreation';
import { IVersesMemo } from './interfaces';
import './style.scss';

interface IMemoModalProps {
  isOpen: boolean;
  selectedVerses: IVersesMemo[];
  onClose: (successMessage?: string | null) => void;
}
function MemoCreationModal({ isOpen, onClose, selectedVerses }: IMemoModalProps) {
  const [memos, setMemos] = useState<IMemo[]>([]);
  const [currentMemo, setCurrentMemo] = useState<IMemo | null>(null);
  const [creationMode, setCreationMode] = useState<boolean>(false);
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const { currentLanguage } = useLanguage();
  const { userInfo } = useSelector(getAuthState);
  const { createMemo, updateMemo, getMemoById, getMemosByUserId } = useMemos();
  const versesMemos = useMemo(() => {
    const sortedVersesMemo = [...selectedVerses].sort((a, b) => a.translationId - b.translationId);
    const result = sortedVersesMemo.reduce(versesMemoReducer, {});
    return combinedMemoText(result);
  }, [selectedVerses]);

  const handleMemoItemClick = (memoId: string | undefined) => {
    if (!memoId) return;
    getMemoById(memoId).then(res => {
      const { text } = res;
      const mergedText = `${text}\n${versesMemos}`;
      setCurrentMemo({
        ...res,
        text: mergedText,
      });
    });
    setUpdateMode(true);
  };

  const onFinish = (values: { title: string; text: string }) => {
    const { title, text } = values;
    if (!userInfo?._id) return;
    if (updateMode) {
      updateMemo({
        id: currentMemo?._id as string,
        title,
        text,
      }).then(() => onClose(currentLanguage.memo_updated_success));
    }

    if (creationMode) {
      createMemo({
        userId: userInfo?._id,
        title,
        text,
      }).then(() => onClose(currentLanguage.memo_created_success));
    }
  };

  const createNewMemo = () => {
    setCreationMode(true);
    setUpdateMode(false);
    setCurrentMemo(null);
  };

  const backToMemosList = () => {
    setCreationMode(false);
    setUpdateMode(false);
    setCurrentMemo(null);
  };

  useMount(() => {
    getMemosByUserId().then(res => {
      if (res) setMemos(res);
    });
  });

  return (
    <Modal
      className="memo-modal"
      title={currentLanguage.memos}
      open={isOpen}
      footer={null}
      onCancel={() => onClose(null)}
    >
      {creationMode || updateMode ? (
        <MemosCreation
          currentMemo={currentMemo}
          versesMemos={versesMemos}
          backToMemosList={backToMemosList}
          onFinish={onFinish}
        />
      ) : (
        <MemosList
          memos={memos}
          createNewMemo={createNewMemo}
          handleMemoItemClick={handleMemoItemClick}
        />
      )}
    </Modal>
  );
}

export default MemoCreationModal;
