import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { INote } from 'types';
import { editNote, createNote, removeNote } from 'store/slices/notes/actions';
import { useLanguage } from 'core/hooks';
import { GoIssueClosed } from 'react-icons/go';
import { Button, Tooltip } from 'antd';

import './style.scss';

interface INotesProps {
  note: INote;
  bookNumber: number;
  chapterNumber: number;
  verseIndex: number;
}

export default function Note({
  note,
  bookNumber,
  chapterNumber,
  verseIndex,
}: INotesProps) {
  const { currentLanguage } = useLanguage();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>(note?.text);

  const handleRemove = () => {
    if (!note?._id) {
      return;
    }

    dispatch(removeNote(note._id));
  };

  const handleSave = () => {
    if (!noteText?.length && !note?._id) {
      return setEditMode(false);
    }

    if (!noteText?.length) {
      handleRemove();
      return;
    }

    if (note?.text && note?._id) {
      dispatch(editNote({
        _id: note._id,
        text: noteText,
        chapterNumber,
        verseIndex,
        bookNumber,
      }));
    } else {
      dispatch(createNote({
        text: noteText,
        verseIndex,
        bookNumber,
        chapterNumber,
      }));
    }
  };

  const resizeTextArea = () => {
    if (!textAreaRef.current) {
      return;
    }

    const textareaContentHeight = `${textAreaRef.current.scrollHeight}px`;
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = textareaContentHeight;
    textAreaRef.current.style.minHeight = textareaContentHeight;
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(e.target.value);
  };

  useEffect(() => {
    setNoteText(note?.text || '');
  }, [bookNumber, chapterNumber, note]);

  useEffect(() => {
    resizeTextArea();
    window.addEventListener('load', resizeTextArea);
  }, [textAreaRef]);

  return (
    <div className={`note ${editMode ? 'note__active' : ''}`}>
      <textarea
        ref={textAreaRef}
        className={`note__textarea ${editMode ? 'note__textarea__active' : ''}`}
        placeholder={currentLanguage.notesTextareaPlaceholder}
        value={noteText}
        onFocus={() => setEditMode(true)}
        onBlur={(e: any) => {
          e.preventDefault();
          setEditMode(false);
          handleSave();
        }}
        onChange={e => {
          handleNoteChange(e);
          resizeTextArea();
        }}
      />
      <Tooltip
        placement="top"
        title={currentLanguage.save}
      >
        <Button
          className="note__action"
          onMouseDown={handleSave}
        >
          <GoIssueClosed />
        </Button>
      </Tooltip>
    </div>
  );
}
