import { useState } from 'react';
import Drawer from 'components/common/drawer';
import { useLanguage } from 'core/hooks';

import Navigation from './Navigation';
import TranslationsViewer from './TranslationsViewer';
import Bookmarks from './Bookmarks';

function Bible() {
  const { currentLanguage } = useLanguage();
  const [openDrawer, setOpenDrawer] = useState(false);

  const showBookmarkDrawer = () => {
    setOpenDrawer(true);
  };

  const onBookmarkDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="bible">
      <Navigation showBookmarkDrawer={showBookmarkDrawer} />
      <TranslationsViewer />
      <Drawer
        className="bible__bookmarks-drawer"
        placement="left"
        open={openDrawer}
        title={currentLanguage.bookmarks}
        onClose={onBookmarkDrawerClose}
      >
        <Bookmarks />
      </Drawer>
    </div>
  );
}

export default Bible;
