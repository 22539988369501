import moment from 'moment';

import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { getAuthState } from 'store/selectors/auth';
import { getUserMemos } from 'store/selectors/memos';
import { useDispatch, useSelector } from 'react-redux';
import { useMemoSelector } from 'store/hooks';
import Container from 'components/common/container';
import Flex from 'components/common/Flex';
import { IMemo } from 'types';
import { getMemosByUserId, updateMemo, createMemo, removeMemo } from 'store/slices/memos/actions';
import { useLanguage } from 'core/hooks';

import MemosMenu from './MemosMenu';
import MemoPreview from './MemoPreview';

import './style.scss';

function Memos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(getAuthState);
  const { currentLanguage } = useLanguage();
  const memos = useMemoSelector(getUserMemos);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeMemo, setActiveMemo] = useState<{title: string; text: string; _id?: string } | null>(null);

  const fetchUserMemos = useCallback(() => {
    if (!userInfo?._id) return;
    dispatch(getMemosByUserId());
  }, [dispatch, userInfo?._id]);

  const updateCurrentMemo = ({ title, text }: { title: string; text: string }) => {
    dispatch(updateMemo({ id: activeMemo?._id as string, title, text }));
  };

  const createNewMemo = () => {
    const title = `Memo ${moment().format('DD/MM/YYYY hh:mm')}`;
    const text = '';
    setEditMode(true);
    setActiveMemo({ title, text });
  };

  const saveMemo = ({ title, text }: { title: string; text: string }) => {
    dispatch(createMemo({ userId: userInfo?._id as string, title, text }));
  };

  const handleRemoveMemo = () => {
    if (!activeMemo?._id) return;
    dispatch(removeMemo({ id: activeMemo?._id }));
  };

  const selectMemo = (memoItem: IMemo) => {
    setActiveMemo(memoItem);
    if (activeMemo?._id !== memoItem._id) {
      setEditMode(false);
    }
  };

  useEffect(() => {
    fetchUserMemos();
  }, [fetchUserMemos]);

  useEffect(() => {
    if (memos?.length) {
      setActiveMemo(memos[0]);
    } else {
      setActiveMemo(null);
    }
  }, [memos]);
  useEffect(() => {
    if (!userInfo?._id) {
      navigate('/');
    }
  }, [navigate, userInfo?._id]);
  return (
    <div className="memos">
      <Container fluid>
        {!activeMemo && !memos.length ? (
          <Flex
            className="memos__empty-state"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              className="memos__add-memo"
              type="primary"
              onClick={createNewMemo}
            >
              {currentLanguage.add_new_memo}
            </Button>
          </Flex>
        ) : (
          <Flex
            justifyContent="space-between"
            width="--full"
          >
            <MemoPreview
              editMode={editMode}
              memo={activeMemo}
              setEditMode={setEditMode}
              updateCurrentMemo={updateCurrentMemo}
              saveMemo={saveMemo}
              setActiveMemo={setActiveMemo}
            />
            <MemosMenu
              memos={memos}
              createNewMemo={createNewMemo}
              selectMemo={selectMemo}
              setEditMode={setEditMode}
              handleRemoveMemo={handleRemoveMemo}
            />
          </Flex>
        )}
      </Container>
    </div>
  );
}

export default Memos;
