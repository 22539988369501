export default {
  title: 'ŞEHDENEMA MESÎHYÊ',
  sections: [
    [
      {
        title: 'BÎBLÎYA:',
        description: `
          Xeber û fikira Xwedê ye,
          Bi zargotina merivan ew daye.
          Ew k'arekî k'amil û  t'am e,
          Bi Mizgînya Îsa Mesîh t'emam e.
          Û wêda hemû hewcetî û cêribandinên merivan hene.        
        `,
      },
      {
        title: 'XWEDÊ:',
        description: `
          Ruh e, ew bi xwe heye: ne efirî ye.
          Ew ji Yektya Bav, Kur' û Ruhê Pîroz e:
          Yek e, yekbit'en e, yekbûyî ye
          Û pêştirî wî xwedêkî din t'une.
          Ew hizkirinêda, pîrozyêda, qewat û zanebûnêda,
          Û serwaxtyêda k'amil e û heta-ha-tê dîsa ew e.
          Erd, ezman û her çi nava wanda hene,
          Bi destê wî çê bûne,
          Û ew bi Xwedê qewata jîyanê distînin
          û bi wî jî axreta xwe dibînin.
          Xwedê her der, ser her efirînanr'a ye, t'evî her kesî ye,
          Û nava pîrozên xweda ye.
        `,
      },
      {
        title: 'MERIV:',
        description: `
          Zuretên Adem in, ji Hêwayê bûyî,
          Herdu jî mîna şikil û nîgara Xwedê çêbûyî,
          Ku hikum bikin ser vî dinyayî.
          Adem bû gunehk'ar, çimkî neheqî kir:
          Bi fêla Şeytan, t'emya Xwedê wî xirab kir.
          Binyata Ademe pîroz hat guhastinê:
          Û ew bû elê gunehkirinê.
          Dilê wîda cîyê rastyê neheqî cî bû,
          Û ew binyat derbazî her zuretên wî bû.
          Û dewsa qanûna jîyînê, nava merivda,
          Qanûna mirinê hikumê xwe da.
          Meriv ji rûmeta Xwedê dûr çûn,
          Lê bi Mesîh wanr'a rizgarî çê bû.
        `,
      },
      {
        title: 'QANÛNA XWEDÊ:',
        description: `
          pîroz û k'amil e û ji her qanûnan jortir e.
          Ew heqyê û neheqyê aşkere dike,
          û rêberya meriv, berbi Mesîh dike.
        `,
      },
    ],
    [
      {
        title: 'ÎSA MESÎH:',
        description: `
          Kesekî ji Sêyektya Xwedê ye
          Û bi hemdê xwe ew nimiz bûye.
          Ji Xwedê derk'et, bû Kur'ê Xwedê,
          Lawê hizkirî, delalyê Bavê.
          Kur'ê Tayê tenê, bi hikumê Xwedê,
          Ji qîza bikir bû, hate vê dinê.
          Qalibê mêrivda ma dîsa Xwedê,
          Hat hebandinê, bi heqya rastyê.
          Binyata merivê elê gunehda,
          Hate cêribandin, her derecanda.
          Ew bêy guneh ma û bona dinê,
          Xûna wî rijya, hate kuştinê.
          Binyata guneh bi xwer'a xaç kir, mir,
          Derê rizgarîyê boy dinê vekir.
          Bi canya xûn-goşt, ji mirinê rabû,
          Pey çil rojanr'a berbi ezman çû.
          Wîr'a hat dayîn, hikumê erd-ezman,
          Ku em aza bin, ji hikumê Şeytan.
          Ewê vegere û pîrozên wî:
          Mirî û zêndî wê herin cem wî.
          Îsa Xudan wê axirzemanê,
          Mirî û zêndyan bîne dîwanê.
          Yên rast wê bijîn, heta ha wê bê,
          Lê nerast wê her'in, agirê her-heyê.
          Îsa Mesîh wê padşatyê bike,
          Û ew padşatî qet xilaz nabe.
          Xwedê wê hêsir û mirinê hilde,
          Û t'extê Xudan wê aşkere be.    
        `,
      },
      {
        title: 'RUHÊ PÎROZ:',
        description: `
          is one of the persons of God's Holy Trinity – forever inseparable.
          He is equal with God and always in the same position, He is completely holy and never is changing.
          The Lord Jesus Christ is baptizing the man, saved by Him, in Holy Spirit.      
        `,
      },
      {
        title: 'CVÎN:',
        description: `
          K'oma bawermendên Îsa Mesîhe yekbûyî ye,
          Ku vegeryane berbi Xwedê û pîroz bûne.
          Ku bigihîjine meremê Xwedêyî sereke:
          Bibine mîna nîgara wî û ya Îsa Mesîh.
          Û her qurnanda, Cvîn gerekê Mizgînyê hîn ke,
          (Ne zêde ke, ne jî jê kêm ke),
          Û bi navê Bav, Kur' û Ruhê Pîroz
          Bawermendan bi avê binixême,
          Û «Şîva Xudan» ew derbaz bike.      
        `,
      },
    ],
  ],
};
