import Typography from 'antd/es/typography';
import { useLanguage } from 'core/hooks';
import { IoSearchSharp } from 'react-icons/io5';

import Flex from '../Flex';

const { Title } = Typography;

function SearchEmptyState() {
  const { currentLanguage } = useLanguage();
  return (
    <Flex justifyContent="center">
      <Flex
        justifyContent="center"
        direction="column"
        alignItems="center"
        marginTop="var(--gap-large)"
        marginBottom="var(--gap-small)"
      >
        <IoSearchSharp fontSize="var(--font-larger-size)" color="#00000073" />
        <Title style={{ marginTop: 'var(--gap-small)' }} type="secondary" level={5}>
          {currentLanguage.search_empty}
        </Title>
      </Flex>
    </Flex>
  );
}

export default SearchEmptyState;
