import { ITranslations } from 'types';
import { APP_LANGUAGES_ENUM } from 'config/constants';

import am from './am.json';
import en from './en.json';
import ku from './ku.json';
import ru from './ru.json';

export default {
  am,
  en,
  ku,
  ru,
} as Record<APP_LANGUAGES_ENUM, ITranslations>;
