import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registerUser } from 'store/slices/auth/actions';
import { Typography, Input, Button, Form, notification } from 'antd';
import { useLanguage } from 'core/hooks';
import { useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';
import { NotificationType } from 'store/slices/auth/types';
import { openNotificationWithIcon } from 'config/notification';
import './style.scss';

const { Title } = Typography;

interface RegisterFormData {
  full_name: string;
  username: string;
  password: string;
  confirmPassword: string;
}

function RegisterScreen() {
  const { loading, message } = useMemoSelector(getAuthState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();
  const [api, contextHolder] = notification.useNotification();
  const submitForm = ({ confirmPassword, ...data }: RegisterFormData) => {
    if (data.password !== confirmPassword) return openNotificationWithIcon({
      api,
      type: NotificationType.error,
      message: 'Message',
      description: currentLanguage.passwordsDoNotMatch,
      placement: 'top',
    });
    const submittedData = {
      ...data,
      username: data?.username?.toLowerCase(),
    };
    dispatch(registerUser(submittedData));
  };

  useEffect(() => {
    if (!message) return;

    return openNotificationWithIcon({
      api,
      type: message.type,
      message: 'Message',
      description: currentLanguage[message.key] || message.message,
      placement: 'top',
    });
  }, [api, message, currentLanguage]);

  useEffect(() => {
    if (message?.key === 'userCreated' && message?.type === 'success') navigate('/login');
  }, [navigate, message]);

  return (
    <div className="signup">
      {contextHolder}
      <div className="signup__container">
        <Title className="signup__text" level={4}>{currentLanguage.signup}</Title>
        <Form className="signup__form" onFinish={submitForm}>
          <Form.Item
            name="full_name"
            rules={[{ required: true, message: currentLanguage.nameRequireErrorMessage }]}
          >
            <div className="signup__form__item">
              <Input
                placeholder={currentLanguage.full_name}
                type="text"
                className="signup__form__item__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="username"
            rules={[{ required: true, message: currentLanguage.emailRequireErrorMessage }]}
          >
            <div className="signup__form__item">
              <Input
                placeholder={currentLanguage.username}
                type="text"
                className="signup__form__item__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: currentLanguage.passwordRequireErrorMessage }]}
          >
            <div className="signup__form__item">
              <Input
                placeholder={currentLanguage.password}
                type="password"
                className="signup__form__item__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: currentLanguage.passwordRequireErrorMessage }]}
          >
            <div className="signup__form__item">
              <Input
                placeholder={currentLanguage.confirm_password}
                type="password"
                className="signup__form__item__input"
              />
            </div>
          </Form.Item>
          <div className="signup__form__item">
            <Button className="signup__submit" htmlType="submit" type="primary" disabled={loading}>
              {loading
                ? (
                  <>
                    <span>...</span>
                    <span>{currentLanguage.loading}</span>
                  </>
                )
                : currentLanguage.signup}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RegisterScreen;
