import { IEndNotInfo } from 'types';

interface IEndNotInfoProps {
  endNotInfo: IEndNotInfo;
}
function EndNoteInfo({
  endNotInfo,
}: IEndNotInfoProps) {
  return (
    <div>
      <p>{endNotInfo.title}</p>
      <p>{endNotInfo.subjects?.[0]}</p>
    </div>
  );
}

export default EndNoteInfo;
