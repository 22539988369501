import Flex from 'components/common/Flex';
import { useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';
import { Typography, Dropdown, Button, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/slices/auth/actions';
import { UserOutlined } from '@ant-design/icons';

const { Title } = Typography;

function AuthActions({
  logoutLabel,
  signupLabel,
  loginLabel,
}: {
  logoutLabel: string;
  signupLabel: string;
  loginLabel: string;
}) {
  const dispatch = useDispatch();
  const { userInfo } = useMemoSelector(getAuthState);
  const items = [
    {
      key: '1',
      label: <Button onClick={() => dispatch(logoutUser())}>{logoutLabel}</Button>,
    },
  ];
  return (
    <div>
      {userInfo ? (
        <Flex alignItems="center">
          <Title style={{ color: 'white', marginBottom: 0 }} level={5}>
            {userInfo.full_name}
          </Title>
          <Dropdown className="navbar__items__button" menu={{ items }} placement="bottomLeft">
            <Avatar style={{ cursor: 'pointer' }} size="default" icon={<UserOutlined />} />
          </Dropdown>
        </Flex>
      ) : (
        <Flex alignItems="center">
          <Link to="/login">
            <Title className="navbar__items__text" level={5}>
              {loginLabel}
            </Title>
          </Link>
          <Link to="/signup">
            <Button className="navbar__items__button">
              <Title className="navbar__items__button__text" level={5}>
                {signupLabel}
              </Title>
            </Button>
          </Link>
        </Flex>
      )}
    </div>
  );
}

export default AuthActions;
