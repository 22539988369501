import { IBibleTranslation } from 'types';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { BibleTranslationService } from 'core/services';

const useBibleTranslations = () => {
  const dispatch = useAppDispatch();
  const { getAllBibleTranslations } = BibleTranslationService;
  const [bibleTranslations, setBibleTranslations] = useState<IBibleTranslation[]>([]);

  useEffect(() => {
    getAllBibleTranslations()
      .then(data => {
        setBibleTranslations(data);
      });
  }, [dispatch, getAllBibleTranslations]);

  return {
    bibleTranslations,
  };
};

export default useBibleTranslations;
