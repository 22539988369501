import { useMemo, useState } from 'react';
import { Button, Popover, Typography } from 'antd';
import { IBibleBook } from 'types';
import { useMemoSelector } from 'store/hooks';
import { ReadOutlined } from '@ant-design/icons';
import { getCurrentBook, getCurrentChapter } from 'store/selectors/bibleTranslations';
import { useLanguage } from 'core/hooks';

import BooksList from './BooksList';
import ChaptersList from './ChaptersList';
import VersesNumbersList from './VersesNumbersList';

const { Paragraph } = Typography;
interface IBookSelectionProps {
  booksListData: IBibleBook[];
  onBookSelect: (bookNumber: number) => void;
  onVerseSelect: (chapterNumber: number) => void;
  onChapterSelect: (chapterNumber: number) => void;
}

function BooksSelection({
  booksListData,
  onBookSelect,
  onVerseSelect,
  onChapterSelect,
}: IBookSelectionProps) {
  const [open, setOpen] = useState(false);
  const { number: currentBookNumber, chaptersCount } = useMemoSelector(getCurrentBook);
  const { currentLanguage } = useLanguage();
  const { currentBookChaptersCount, currentChapter } = useMemoSelector(state => ({
    currentBookChaptersCount: getCurrentBook(state).chaptersCount,
    currentChapter: getCurrentChapter(state),
  }));

  const currentBook = useMemo(() => (
    booksListData.find(({ number }) => number === currentBookNumber)
  ), [booksListData, currentBookNumber]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      title={currentLanguage.selectBook}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottom"
      content={(
        <div className="bible-navigation__book-selection">
          <BooksList
            key={currentBook?.title}
            defaultBook={currentBook?.number}
            booksListData={booksListData}
            onBookSelect={onBookSelect}
          />
          <Paragraph>{currentLanguage.selectChapter}</Paragraph>
          {chaptersCount && (
            <ChaptersList
              currentChapter={currentChapter}
              currentBookChaptersCount={currentBookChaptersCount}
              onChapterSelect={onChapterSelect}
            />
          )}
          <Paragraph>{currentLanguage.selectVerse}</Paragraph>
          {currentChapter && <VersesNumbersList onVerseSelect={onVerseSelect} closePopover={() => setOpen(false)} />}
        </div>
      )}
    >
      <Button className="bible-navigation__book-selection-button" icon={<ReadOutlined />}>
        {currentBook?.title}
        <div />
      </Button>
    </Popover>
  );
}

export default BooksSelection;
