import { Drawer as AntDrawer } from 'antd';

function Drawer({
  open,
  title,
  children,
  placement,
  className,
  drawerStyle,
  onClose,
}: {
  open: boolean;
  title?: string;
  className?: string;
  children?: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  drawerStyle?: React.CSSProperties;
  onClose: () => void;
}) {
  return (
    <AntDrawer
      open={open}
      title={title}
      className={className}
      placement={placement}
      onClose={onClose}
      style={{ ...drawerStyle }}
    >
      {children || null}
    </AntDrawer>
  );
}

export default Drawer;

Drawer.defaultProps = {
  title: '',
  placement: 'right',
};
