/* eslint-disable no-console */

import config from './config';

const DEFAULT_NAMESPACE = config.defaults.namespace;

type ILogTypes = '[INFO]' | '[WARN]' | '[ERROR]';

const getDate = () => new Date().toISOString();

const getLogFunction = (type: ILogTypes) => (message: any, namespace?: string) => {
  if (typeof message === 'string') {
    console.log(`[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] ${type} ${message}`);
  } else {
    console.log(`[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] ${type}`, message);
  }
};

export const logInfo = getLogFunction('[INFO]');
export const logWarn = getLogFunction('[WARN]');
export const logError = getLogFunction('[ERROR]');
