import { useCallback } from 'react';
import { useBooks } from 'core/hooks';
import { useAppDispatch, useMemoSelector } from 'store/hooks';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import { getCurrentBook } from 'store/selectors/bibleTranslations';

const useBookNavigation = () => {
  const dispatch = useAppDispatch();

  const { booksList } = useBooks();
  const currentBookNumber = useMemoSelector(state => getCurrentBook(state).number);

  const setCurrentVerse = useCallback((verseNumber: number) => {
    dispatch(bibleTranslationsSlice.actions.setCurrentVerseNumber(verseNumber));
  }, [dispatch]);

  const setCurrentChapter = useCallback((chapterNumber: number) => {
    dispatch(bibleTranslationsSlice.actions.setCurrentChapterNumber(chapterNumber));
  }, [dispatch]);

  const setCurrentBook = useCallback((bookNumber: number, chapterNumber = 1) => {
    const currentBook = booksList?.find(book => book.number === bookNumber);
    if (currentBook) {
      dispatch(
        bibleTranslationsSlice.actions.setCurrentBook({
          number: currentBook.number,
          chaptersCount: currentBook.chapters_count,
        }),
      );
      setCurrentChapter(chapterNumber);
    }
  }, [booksList, dispatch, setCurrentChapter]);

  const findSideBook = useCallback((direction: 1 | -1) => () => {
    const currentBookIndex = booksList?.findIndex(book => book.number === currentBookNumber);
    const nextBookIndex = (booksList.length + currentBookIndex + direction) % booksList.length;
    return booksList?.[nextBookIndex];
  }, [currentBookNumber, booksList]);

  return {
    setCurrentBook,
    setCurrentChapter,
    setCurrentVerse,
    booksListData: booksList,
    getNextBook: findSideBook(1),
    getPrevBook: findSideBook(-1),
  };
};

export default useBookNavigation;
