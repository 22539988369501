import { Typography } from 'antd';

import '../style.scss';

const { Paragraph } = Typography;

interface IResults {
  searchResult: any;
  setSelectedWord: (word: string) => void;
  setShowWordsList: (val: boolean) => void;
}

export default function ResultsGroup({
  searchResult,
  setSelectedWord,
  setShowWordsList,
}: IResults) {
  const onSearchResultItemClick = (word: string) => {
    setSelectedWord(word);
    setShowWordsList(true);
  };

  return (
    <div className="search__result-word-list">
      <div className="search__result-word-container">
        {Object.keys(searchResult).map(word => (
          <div
            key={word}
            className="search__result-item"
            onClick={() => onSearchResultItemClick(word)}
          >
            <Paragraph
              className="search__result-word"
            >
              {word}
            </Paragraph>
            <Paragraph>{searchResult[word].length}</Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
}
