export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function constructSidebarActiveKey(route) {
  const keys = route.split('/');
  return keys.reduce((result, item) => {
    let prev = '';
    if (result.length) {
      prev = `${result[result.length - 1]}/`;
    }

    result.push(prev + item);
    return result;
  }, []);
}
