/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { IBookmark } from 'types';

import {
  createBookmark,
  getBookmarks,
  removeBookmark,
} from './actions';

interface IBookmarksState {
  bookmarks: IBookmark[];
  loading?: boolean;
}

const initialState: IBookmarksState = {
  bookmarks: [],
  loading: false,
};

const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(createBookmark.pending, state => {
        state.loading = true;
      })
      .addCase(createBookmark.fulfilled, (state, action) => {
        state.loading = false;
        const createdBookmark = action.payload;
        state.bookmarks = [
          createdBookmark,
          ...state.bookmarks,
        ];
      })
      .addCase(createBookmark.rejected, state => {
        state.loading = false;
      })
      .addCase(getBookmarks.pending, state => {
        state.loading = true;
      })
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.loading = false;
        state.bookmarks = action.payload;
      })
      .addCase(getBookmarks.rejected, state => {
        state.loading = false;
      })
      .addCase(removeBookmark.pending, state => {
        state.loading = true;
      })
      .addCase(removeBookmark.fulfilled, (state, action) => {
        state.loading = false;
        const { meta } = action;
        const index = state.bookmarks.findIndex(bookmark => bookmark._id === meta.arg.id);
        if (index !== -1) {
          state.bookmarks.splice(index, 1);
        }
      })
      .addCase(removeBookmark.rejected, state => {
        state.loading = false;
      });
  },
});

export default bookmarksSlice;
