/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import uniq from 'lodash/uniq';
import { APP_DEFAULT_LANGUAGE, TRANSLATIONS_IDS_MAP } from 'config/constants';

import { IBibleSliceState, ICurrentBook } from './types';

const NOTES = 'notes';

const defaultTranslationId = TRANSLATIONS_IDS_MAP[APP_DEFAULT_LANGUAGE];
const initialState: IBibleSliceState = {
  currentTranslations: [defaultTranslationId],
  currentBook: {
    number: 40,
    chaptersCount: 28,
  },
  currentChapter: 1,
  currentVerse: 0,
};

const bibleSlice = createSlice({
  name: 'bibleTranslations',
  initialState,
  reducers: {
    changeTranslations: (state, { payload }: PayloadAction<(number | string)[]>) => {
      if (payload.length === 1 && payload[0] === NOTES) {
        state.currentTranslations = [...payload, defaultTranslationId];
      } else {
        state.currentTranslations = payload.length ? payload : [defaultTranslationId];
      }
    },
    addTranslation: (state, action: PayloadAction<number>) => {
      state.currentTranslations = uniq([...state.currentTranslations, action.payload]);
    },
    setCurrentBook: (state, action: PayloadAction<ICurrentBook>) => {
      state.currentBook = action.payload;
    },
    setCurrentChapterNumber: (state, action: PayloadAction<number>) => {
      state.currentChapter = action.payload;
    },
    setCurrentVerseNumber: (state, action: PayloadAction<number>) => {
      state.currentVerse = action.payload;
    },
  },
});

export default bibleSlice;
