export default [
  {
    src: '101-Երբ որ նա ամպերով վերադառնա.mp3',
    name: 'Երբ որ նա ամպերով վերադառնա',
    author: 'Հոգևոր երգարան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ԵՐԲ ՈՐ ՆԱ ԱՄՊԵՐՈՎ ՎԵՐԱԴԱՌՆԱ</b>
  
  1.Երբ որ նա ամպերով վերադառնա,
  Իմ աչքերով պիտի նրա դեմքին նայեմ,
  Ուրախությամբ և փառք տալով\` Աստծո Որդուն,
  Օդերի մեջ դիմավորեմ:
  Օ՜, ի՜նչ մեծ փառք է, ի՜նչ մեծ շնորհք է,
  Երջանիկ հույս է Աստծո կողմից:
  Կրկ. - Ուրախ եմ, որքա՜ն ուրախ եմ,
  Հիշելով այս մեծ հույսը\` ցնծում եմ:
  2.Ի՜նչ մեծ խնջույք է լինելու երկնքի մեջ,
  Երբ որ բոլոր սուրբերը հավաքվեն,
  Միասին պետք է փառաբանենք Աստծո Գառին,
  Արժանի է ամեն փառքի:
  Հարսը պատրաստ է, շուտով արի, Տե՛ր,
  Զարդարված հոգով սպասում է:
  3.Ահա, Տերը գալու է բյուրավոր սուրբերով,
  Հրեշտակների փողի ձայնով,
  Հաստատելու է մի մեծ թագավորություն
  Եւ խաղաղություն երկրի վրա:
  Օ՜, ի՜նչ մեծ փառք է, ի՜նչ մեծ շնորհք է,
  Երջանիկ հույս է Աստծո կողմից:
  `,
  },
  {
    src: '115-Ժողովուրդ Աստծո.mp3',
    name: 'Ժողովուրդ Աստծո',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ԺՈՂՈՎՈւՐԴ ԱՍՏԾՈ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Գենադի Դավթյանի</span>
  
  1. Ժողովու՛րդ Աստծո, դուք մի՛ վախեցեք
  Զորեղ է Աստված՝ նրան հուսացեք,
  Երկինք ու երկիր նա է ստեղծել,
  Եւ ձեզ զորավոր փրկիչ ուղարկել։
  Կրկն․ Տարբեր ազգերից հրաշք մի ժողովք,
  Տարբեր ազգերից, բայց՝ մեկ ժողովուրդ,
  Տարբեր ազգերից՝ արյունով գնված,
  Աստծո Որդին է նրանց զոհ դարձած։
  2. Անունը՝ Հիսուս, այսինքն՝ Փրկող,
  Մեղքից ու մահից նա է ազատող։
  Մեզ համար մեռավ, հարություն առավ
  Եւ փառքով դեպի երկինք համբարձվավ։
  3. Ճերմակ ձի հեծած՝ բազկով զորավոր,
  Հավատարիմ է, զորապետ՝ հաղթող
  Իր ժողովուրդն էլ՝ իրեն հետևող
  Հավատարիմ է նրա խոսքերով։
  `,
  },
  {
    src: '117-Հիսուս Քրիստոս.mp3',
    name: 'Հիսուս Քրիստոս',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ՀԻՍՈւՍ ՔՐԻՍՏՈՍ</b>
  <span class="about">Խմբագրումը՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Գենադի Դավթյանի</span>
  
  1. Հիսուս Քրիստոս՝ մեկ անձն է Սուրբ աստվածության,
  Աստծուց դուրս եկած, ոչ թե արարված։
  Կույսից ծնվեց, մարդու մարմին, բնույթ վերցրեց
  Եւ այդ մարմնում նա երկրպագվեց։
  Մեզ նման փորձվեց, սակայն մեղք չգործեց,
  Եւ որպես Սուրբ Գառ՝ մեզ համար զոհվեց։
  Իր սուրբ արյունով մեղքերը մեր նա քավեց,
  Մեղքն ու մահը իր մարմնում դատեց։
  2. Փրկություն տվեց նա մեզ իր խաչի մահով,
  Հարություն առավ հենց նույն մարմնով։
  Երկինք ելնելով՝ Հոր աջում փառքով նստեց,
  Երկինք ու երկիր իր ձեռքը տրվեց։
  Նա է հզոր Փրկիչը ամբողջ աշխարհի,
  Որ ով հավատա, Չարից ազատվի։
  Հիսուսը Հոր փառքով կվերադառնա,
  Սուրբ մեռյալներին հարություն կտա։
  3. Նրանց հետ մեկտեղ երկրից ողջ սուրբերը,
  Կհափշտակվեն դեպ իրենց Տերը։
  Դարերի վերջում Հիսուս Քրիստոս մեր Տերը
  Ի լույս կբերի մարդկանց գործերը։
  Արդարը կժառանգի հավերժական կյանք,
  Անիրավը կգնա հավերժ տանջանք։
  Եւ Տեր Հիսուս Քրիստոսը կթագավորի,
  Իր իշխանությանն էլ վերջ չի լինի։
  Աստված կվերցնի արցունքն ու մահը,
  Աչքով կտեսնենք մեր Տիրոջ գահը։
  `,
  },
  {
    src: '118-Սկզբում էր կյանքի խոսքը.mp3',
    name: 'Սկզբում էր կյանքի խոսքը',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Հերմինե Քոչարյան',
    lyrics: `
  <b>ՍԿԶԲՈւՄ ԷՐ ԿՅԱՆՔԻ ԽՈՍՔԸ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Հերմինե Քոչարյանի</span>
  
  1. Սկզբումն էր կյանքի խոսքը՝
  Աստծո մոտ էր այն,
  Եւ Աստված էր այդ սուրբ խոսքը՝
  Նրանով եղանք։
  Կրկ․ Կյանքը կոչվեց Հիսուս Փրկիչ՝
  Մեղքից, մահից ազատող,
  Նա մեզ ասաց․ «Մի՛ վախեցեք,
  Ես եմ ձեզ փրկող»։
  2. Ամեն ինչ նրանով եղավ,
  Նրանով՝ բարին,
  Եւ այդ խոսքը մարմին եղավ,
  Լույսը՝ աշխարհին։
  3. Երկինք-երկիր նրան տրվեց,
  Եղավ նա Իշխան,
  Հավատացողին հույս բերեց՝
  Կապրենք հավիտյան։
  `,
  },
  {
    src: '119-Անձի տեղ Անձ.mp3',
    name: 'Անձի տեղ Անձ',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արսեն Վարդանյան',
    composer: 'Հերմինե Քոչարյան',
    lyrics: `
  <b>ԱՆՁԻ ՏԵՂ ԱՆՁ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Հերմինե Քոչարյանի</span>
  
  1. Տերը վերջին ընթրիքն արեց,
  Իրեններին մխիթարեց,
  Ասեց․ «Ես ձեզ որբ չեմ թողնի,
  Ձեզ կուղարկեմ Սուրբ Հոգին։
  Հիսուսը համբարձվեց,
  Եւ Հոր Աջում նստեց,
  Սուրբ Հոգին ուղարկեց,
  Իրեններին մկրտեց։
  2. Անձի տեղ նա անձ ուղարկեց,
  Աստծո Հոգով նա մեզ կնքեց,
  Սուրբ Հոգին ի վերուստ եկավ,
  Ժառանգության գրավ եղավ։
  Հոգին կառաջնորդի,
  Ճշմարտությանը կտանի,
  Նա, ինքն է մեզ մխիթարում,
  Եւ զորությամբ պաշտպանում։
  3. Սուրբ Հոգին է մեզ խնամում,
  Պարգևներով մեզ զարդարում,
  Նա է տալիս իմաստություն,
  Մեծ զորություն, գիտություն։
  Մեզ մղում աղոթքի,
  Բացում միտքը Խոսքի,
  Եկեղեցին լցնում Հոգով,
  Առաքինի պտուղով։
  `,
  },
  {
    src: '16-Գեղեցիկ մի առավոտ էր.mp3',
    name: 'Գեղեցիկ մի առավոտ էր',
    author: 'Ռաֆայել Խաչատրյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Ռաֆայել Խաչատրյան',
    lyrics: `
  <b>ԳԵՂԵՑԻԿ ՄԻ ԱՌԱՎՈՏ ԷՐ</b>
  <span class="about">Հեղինակ՝ Ռաֆայել Խաչատրյան</span>
  
  1.Գեղեցիկ մի առավոտ էր,
  Արևը դեռ չէր բարձրացել,
  Կանայք խունկեր պատրաստեցին
  Եւ գերեզման այցելեցին:
  Կրկ. - Օ՜հ, այս ի՜նչ հրաշք ենք տեսնում,
  Դատարկ գերեզման առջևում,
  Հարություն է առել Տերը,
  Բազմել երկնքի բարձրերը:
  2.Այս լուրն, անշուշտ, պիտի պատմեմ
  Եւ ամենքին հայտարարեմ\`
  Հիսուսը չկա գերեզմանում,
  Այլ նստած է նա Հոր աջում:
  3.Ահեղ մահը չդիմացավ,
  Երբ Հիսուսն հարություն առավ,
  Այն պարտվեց, խայտառակվեց,
  Իր հզոր ուժը կորցրեց:
  `,
  },
  {
    src: '18-Գեթսեմանի պարտեզում.mp3',
    name: 'Գեթսեմանի պարտեզում',
    author: 'Գենադի Դավթյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ԳԵԹՍԵՄԱՆԻ ՊԱՐՏԵԶՈւՄ</b>
  <span class="about">Հեղինակ՝ Գենադի Դավթյան</span>
  
  1.Գեթսեմանի մեծ պարտեզում
  Ճակատից քրտինքն էր հոսում.
  Տերն աղոթում էր Հայր Աստծուն:
  Դառը բաժակ կար խմելու,
  Իր Հոր կամքին խոնարհվելու\` սրտով հոժար:
  Տառապանքի խաչն էր սպասում,
  Չարչարանքի ուղին դժվար, նրա համար:
  Բայց նա աղոթում էր դարձյալ.
  «Թո՛ղ քո կամքը լինի, Սու՛րբ Հայր,
  Աստված Բարձրյալ»:
  Կրկ. - Սիրո կանթեղն էր սուրբ սրտում վառվում,
  Խաչի ճամփան իր արյամբ ներկում,
  Սիրո կանթեղն էր սուրբ սրտում վառվում,
  Մարդկության համար ճամփա հարթում:
  2.Ահա մարդիկ դաժան, անգութ,
  Խփում էին, ծաղրում նրան,
  Իսկ նա լուռ էր\` սիրով լցված:
  Փշե պսակն այնպես ծակում,
  Սուրբ ճակատն էր արնահոսում\` լուռ էր դարձյալ:
  Ահա խոնարհ իր խաչն ուսին,
  Սարն էր ելնում Գողգոթայի\` սարը մահվան:
  Դաժան, անգութ մարդկանց համար,
  Պիղծ, չարագործ անձանց համար, մեղաց համար:
  3.Ահա խաչին գամված էր նա,
  Ձեռքերն, ոտքերն արյունլվա\`
  Կամենում էր փրկել մարդկանց:
  Բայց նրան ջրի փոխարեն
  Քացախ տվեցին սպունգի վրա դաժանաբար:
  Իսկ նա աղոթում էր Աստծուն,
  Խնդրում էր այդ մարդկանց համար.
  «Ների՛ր նրանց, չէ՞ որ կույր են, չեն հասկանում,
  Ձեռքդ մեկնիր, օգնիր նրանց,
  Իմ Հայր Աստված»:
  Հիսուս Քրիստոսը ձեզ համար փրկության ճամփա է հարթել։
  `,
  },
  {
    src: '19-Ով դուք ծարավներ.mp3',
    name: 'Ով դուք ծարավներ',
    author: 'Հոգևոր երգարան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>Ո՛Վ ԴՈւՔ, ԾԱՐԱՎՆԵ՛Ր</b>
  
  1.Ո՛վ դուք, ծարավնե՛ր, եկե՛ք ջրի մոտ,
  Դուք, որ փող չունեք, եկե՛ք ու առեք,
  Առանց վճարի, ձրի վերցրեք,
  Ինձանից խնդրե՛ք, բարիք ստացեք:
  Ջուր պիտի թափեմ ծարավի վրա,
  Ինչպես անձրևներ չորության վրա,
  Հոգիս պիտ թափեմ ամենքի վրա,
  Իմ օրհնությունները\` սերնդիդ վրա:
  Կրկ. - Օ՜հ, ալելույա՜, - 4
  Հոգիս պիտ թափեմ ամենքի վրա,
  Իմ օրհնությունները\` սերնդիդ վրա:
  2.Սա Հովելի մարգարեությունն է,
  Որ այս օրերում այն կատարվում է,
  Եւ Երեմիայով տրված խոստումն է\`
  Նոր ուխտի խոսքը, որ հաստատվում է:
  Տաճարում կանգնած Տերն աղաղակեց.
  «Ով որ ծարավ է, թո՛ղ նա ինձ մոտ գա,
  Ինձ հավատացողը, ինչպես որ գրվեց,
  Նրա սրտիցը աղբյուր կբխե»:
  `,
  },
  {
    src: '2-Աստվածաշունչ.mp3',
    name: 'Աստվածաշունչ',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ԱՍՏՎԱԾԱՇՈւՆՉ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Իր մեջ ունի պատմություններ,
  Սուրբ Օրենքներ ու կանոններ,
  Իմաստություն ու գիտություն,
  Մարգարեություն և ուսուցում:
  
  Կրկ. - Այդ թերթերով Տերն է խոսող,
  Ակը\` զուլալ, ջուրը\` հոսող,
  Սուրբ Հոգու միտք մարդկանց բերնով
  Կյանք է տվել նա դարերով: - 2
  Աստվածաշո՜ւնչ - 2
  2.Կատարյալ է և անթերի,
  Մխիթարանք\` կյանք սրտերի,
  Տրվել է, որ լինի անմերժ
  Ու կյանք տա նա մարդկանց հավերժ:
  Կրկ. - Հազարամյակներ է անցել,
  Մարդկանց առջև դուռ է բացել,
  Որ Աստծո խոսքին հավատան
  Կատարյալ ու անբիծ դառնան: - 2
  Աստվածաշո՜ւնչ - 2
  `,
  },
  {
    src: '21-Հիսուսը ծնվեց.mp3',
    name: 'Հիսուսը ծնվեց',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արսեն Վարդանյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ՀԻՍՈւՍԸ ԾՆՎԵՑ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Հիսուսը ծնվեց, այս աշխարհում,
  Հովիվներն դաշտում հոտերն էին պահում,
  Հրեշտակը նրանց ավետիս տվեց.
  «Այսօր ձեզ համար Փրկիչը ծնվեց»:
  Կրկ. Երանի՜ նրանց, որ Փրկչին տեսան,
  Տեսնելով\` հույս ու հավատք ստացան:
  2.Երկնակամարում աստղը փայլեց,
  Երբ կույս Մարիամից Փրկիչը ծնվեց,
  Մոգերը եկան երկրպագելու,
  Մանուկ Հիսուսին նվերներ տալու:
  3.Հիսուսը Դավթի քաղաքում ծնվեց,
  Ամբողջ Երուսաղեմը շփոթվեց,
  Մեզ համար Աստծուց Թագավոր ծնվեց,
  Աստված մարմնով մեր մեջ հայտնվեց:
  4.Հիսուսը ծնվեց, մսուրում դրվեց,
  Երկինք ու երկիր փառքով ողջունեց,
  Զորք հրեշտակաց երգեր երգեցին,
  Աստծո Որդուն երկրպագեցին:
  `,
  },
  {
    src: '23-Քրիստոսը հարություն առավ.mp3',
    name: 'Քրիստոսը հարություն առավ',
    author: 'ՀՔՀԵ Երիտասարդական խումբ',
    artist: 'Արմեն Բարսեղյան, Գայանե Բադալյան, Արսեն Վարդանյան',
    composer: 'ՀՔՀԵ Երիտասարդական խումբ',
    lyrics: `
  <b>ՔՐԻՍՏՈՍԸ ՀԱՐՈւԹՅՈւՆ ԱՌԱՎ</b>
  <span class="about">Հեղինակ՝ ՀՔՀԵ երիտասարդական խումբ</span>
  
  1.Քրիստոսը հարություն առավ,
  Որքա՜ն ուրախ ենք մենք,
  Այս խորհուրդը հասկանալով\`
  Ցնծում ենք ահա մենք:
  Կրկ. – Հարյա՜վ Քրիստոսը, հարյա՜վ Փրկիչը,
  Հարյա՜վ առաջնորդը կյանքի:
  2.Հարությամբ նա հաղթեց մահին,
  Անմահ ենք մենք հիմա,
  Խայտառակեց սատանային
  Եւ մեր պարծանքն է նա:
  3.Արդարության հանդերձ հագած\`
  Երգում ենք մենք նրան
  Եւ ամենուր հայտարարում\`
  Հիսուսն է մեր Արքան:
  `,
  },
  {
    src: '30-Մեծ ավետիք.mp3',
    name: 'Մեծ ավետիք',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ՄԵԾ ԱՎԵՏԻՔ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Աստված այնպես ամբողջ աշխարհը սիրեց,
  Որ մեր փրկության համար իր Որդուն տվեց,
  Սուրբ Հոգու զորությամբ նա Մարիամից ծնվեց,
  Ի նշան\` նրա երկնքում աստղ փայլեց:
  Կրկ. - Մեծ ավետիք\` համայն աշխարհին,
  Փրկիչ ծնվեց բոլոր ազգերին,
  Ով ընդունի, նա կփրկվի,
  Աստծո շնորհքով հավետ կապրի:
  2.Հիսուսը մեզ նման մարմնում ապրեց
  Եւ իր ամբողջ կյանքում նա մեղք չգործեց,
  Նա որպես Աստծո Սուրբ Գառ\` մեզ համար զոհվեց
  Ու աստվածային կտակ նա մարդկանց թողեց:
  `,
  },
  {
    src: '37-Իմ կյանքը ես տվի.mp3',
    name: 'Իմ կյանքը ես տվի',
    author: 'Հոգևոր երգարան',
    artist: 'Արսեն Վարդանյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ԻՄ ԿՅԱՆՔԸ ԵՍ ՏՎԻ</b>
  <span class="about">1.Իմ կյանքը ես տվի, արյունս հեղեցի,</span>

  Որ քո մեղքը թողվի, իմ անձը զոհեցի,
  Ես կյանքս տվի քեզ համար,
  Իսկ դու ի՞նչ ինձ համար:
  2.Լուսո երկնից եկա\` իմ փառաց աթոռից,
  Ծառայի պես եղա, ծաղր ու նախատինքից,
  Այս ամենը լոկ քեզ համար,
  Իսկ դու ի՞նչ ինձ համար:
  3.Կրեցի փշե պսակ, ցավ, տառապանք և ահ,
  Խմեցի դառը բաժակ\` հնազանդ մինչ ի մահ,
  Այս չարչարանքս էլ քեզ համար,
  Իսկ դու ի՞նչ ինձ համար:
  4.Մեղքերի թողություն հիմա տալիս եմ քեզ,
  Կյանք և երանություն ձրի շնորհից առ քեզ,
  Այս ամենը քեզ եմ տալիս,
  Դու էլ սիրտդ տուր ինձ:
  `,
  },
  {
    src: '4-Հիսուսն Աստծո բնությամբ էր.mp3',
    name: 'Հիսուսն Աստծո բնությամբ էր',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ՀԻՍՈւՍՆ ԱՍՏԾՈ ԲՆՈւԹՅԱՄԲ ԷՐ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Հիսուսն Աստծո բնությամբ էր,
  Ամեն ինչով Հոր նման էր,
  Բայց իր փառքով չզմայլվեց,
  Մարդացավ ու շատ խոնարհվեց:
  Իջավ երկիր, ունայնացավ,
  Ծառայի բնություն ստացավ,
  Իր խաչով նա ճամփա հարթեց,
  Հայրն էլ նրան շատ բարձրացրեց:
  2.Հիսուս անունը փառավորեց,
  Իր զորությամբ հրամայեց,
  Որ ամեն ծունկ պիտի ծռվի,
  Ճշմարտությունը դավանի:
  Որ Հիսուսն Աստծո Որդին է,
  Դատաստանից Ազատողն է,
  Ասա դու ես Աստծո փառքը,
  Իմ Փրկիչը և իմ կյանքը:
  `,
  },
  {
    src: '48-Հավիտենական թագավորը.mp3',
    name: 'Հավիտենական թագավորը',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ՀԱՎԻՏԵՆԱԿԱՆ ԹԱԳԱՎՈՐԸ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Գենադի Դավթյանի</span>
  
  1.Ամեն ինչից առաջ նա կար\` Նա Տեր է։
  Չերևացող, հզոր Աստծո պատկերն է։
  Արարվեցին երկինք-երկիր նրանով,
  Եւ նրանց մեջ եղածն ամենն իր ձեռքով։
  Կրկն․ Հիսուս Տերը խաչին գամվեց, չարչարվեց,
  Իր սուրբ արյունը նա թափեց, մեզ փրկեց։
  Երրորդ օրը Հոր զորությամբ նա հարյավ,
  Հավիտենական թագավոր նա եղավ։
  2. Նրանով են առել սկիզբ ամեն բան,
  Եւ նրանից չկա ծածուկ ոչ մի բան։
  Աստված նրանով է կյանքի հիմք դրել,
  Նրա մահվամբ և հարությամբ\` փառք բերել։
  3. Ուստի, ով մա՛րդ, որ լսում ես, հավատա՛,
  Տեր Հիսուսը քեզ սիրում է, կյանք կտա։
  Հիսուսը Փրկիչ է, Տերը Տերերի,
  Հավերժական թագավորը բոլորի։
  `,
  },
  {
    src: '49-Աստված.mp3',
    name: 'Աստված',
    author: 'Միլազիմ Ռասոյան',
    artist: 'ՀՔՀԵ երգչախումբ',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ԱՍՏՎԱԾ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Երկինք-երկի՛ր, արարածնե՛ր, լու՛ռ կացեք,
  Ճշմարտության խոսքին դուք ականջ դրեք,
  Իմ այս խոսքը սիրող Աստծո մասին է,
  Իմացեք, թե ո՞վ է նա, ինչպիսի՞ն է:
  2.Միակն է նա\` կա ճշմարիտ մեկ Աստված,
  Հայրը, Որդին և Սուրբ Հոգին մեկացած,
  Տիեզերքում նրա նման սուրբ չկա,
  Թագավորաց-Թագավոր է և մի՛շտ կա:
  3.Սկզբում էր Գոյը և Գոյն Աստված է,
  Ամեն գոյերն նրանով են գոյացել,
  Նրանով են ապրելու ուժ ձեռք բերում
  Եւ նրանով իրենց վախճանը գտնում:
  4.Նա հոգի է և ինքնագո, իմաստուն,
  Հավիտենից անփոփոխ է և գիտուն,
  Ողջ տիեզերք նա ստեղծեց վեց օրում,
  Երկինք-երկիր և ինչոր կա նրանցում:
  5.Ամենուրեք է Աստված, ամենազոր,
  Իր ստեղծածներին հսկում է ամեն օր,
  Իր սուրբերի սրտում և հոգում է նա,
  Ամեն ստեղծված էակի հետ է նա:
  Ճշմարտության խոսքին դուք ականջ դրեք,
  Երկինք-երկի՛ր դուք սուրբ Աստծուն փառք տվեք։
  `,
  },
  {
    src: '53-Քրիստոսը մեզ կանչում է փրկության.mp3',
    name: 'Քրիստոսը մեզ կանչում է փրկության',
    author: 'Հոգևոր երգարան',
    artist: 'Գայանե Բադալյան, Արմեն Բարսեղյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ՔՐԻՍՏՈՍԸ ՄԵԶ ԿԱՆՉՈւՄ Է ՓՐԿՈւԹՅԱՆ</b>
  <span class="about">1.Քրիստոսը մեզ կանչում է փրկության</span>

  Եւ հավիտենական խաղաղության,
  Ի՞նչ ես դանդաղում վերջին օրերում,
  Դու, խե՛ղճ պանդուխտ, այս մեղքի աշխարհում:
  2.Նա քեզանից ոչինչ չի պահանջում,
  Այլ կորստից քեզ փրկել է ուզում,
  Մի՛ մերժիր այդ սիրո հրավերը,
  Հիսուսի համար բացի՛ր քո սիրտը:
  3.Այստեղ թշնամին մարդկային հոգու
  Ուզում է, որ դու ապրես խավարում,
  Բայց դեռ վառվում է փրկության լույսը,
  Քեզ է կանչում հավիտենությունը:
  4.Քանզի հաճախ ինքդ էլ ես հասկանում,
  Որ մեղքի կյանքն է անիմաստություն,
  Բայց դեռ անհոգության մեջ քնած ես
  Եւ կործանման մասին մոռացել ես:
  5.Դեռ ժամանակը քեզ է սպասում,
  Եւ Աստված քեզ փրկության է կանչում,
  Ի՞նչ ես մտածում այս սուղ օրերում,
  Դու, խե՛ղճ պանդուխտ, այս մեղքի աշխարհում:
  `,
  },
  {
    src: '61-Աստված խոստացավ մեզ փրկել.mp3',
    name: 'Աստված խոստացավ մեզ փրկել',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Գայանե Բադալյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ԱՍՏՎԱԾ ԽՈՍՏԱՑԱՎ ՄԵԶ ՓՐԿԵԼ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Գենադի Դավթյանի</span>
  
  1.Աստված խոստացավ մեզ փրկել
  Եւ բոլոր մեղքերը քավել,
  Տեր Հիսուսին նա ուղարկեց,
  Բոլոր մեր մեղքերը ներեց:
  Այս խոստումը ձրի տվեց,
  Աստված մեծ սիրով մեզ սիրեց,
  Բոլոր ազգերին նա կանչեց,
  Փրկության խոստում շնորհեց:
  2.Հիսուս Քրիստոսը խոստացավ,
  Որ Սուրբ Հոգին մեզ կուղարկի,
  Ընդմիշտ նա մեզ հետ կլինի,
  Որ երբեք մենակ չթողնի:
  Տեր Հիսուսը Հոր մոտ գնաց
  Եւ Սուրբ Հոգին մեզ ուղարկեց,
  Իր խաչով նա մահը վերցրեց,
  Մեր հոգուն կյանք նա պարգևեց:
  3.Աստծո խոստումները շատ են,
  Ավետարանում գրված են,
  Մեր հոգու հացն են ու ջուրն են
  Եւ հավիտյան մեզ կպահեն:
  Տեր Հիսուսին ես սիրում եմ,
  Իր խոստումներով ցնծում եմ,
  Տերը չի ստում, հավատա՛,
  Երջանիկ կյանք քեզ նա կտա:
  4.Տերը խոստացավ, որ կգա
  Ու մեզ երկինք՝ վեր կտանի,
  Այնտեղ այլևս հոգս չկա,
  Նա միշտ մեզ համար կհոգա:
  Երկնքում էլ մահ չի՛ լիլնի
  Եւ այնտեղ սուգ էլ չի՛ լինի,
  Վիշտ ու ցավեր էլ չենք տեսնի
  Եւ արցունքներ էլ չի լինի:
  `,
  },
  {
    src: '63-Աստված փրկություն պատրաստեց.mp3',
    name: 'Աստված փրկություն պատրաստեց',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արսեն Վարդանյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ԱՍՏՎԱԾ ՓՐԿՈւԹՅՈւՆ ՊԱՏՐԱՍՏԵՑ</b>
  <span class="about">Խոսք՝ Միլազիմ Ռասոյանի, երաժշտությունը՝ Գենադի Դավթյանի</span>
  
  1.Աստված փրկություն պատրաստեց,
  Բոլոր ազգերին կանչեց,
  Դե՛ հիմա եկեք ընդունեք
  Եւ կորստից փրկվեք:
  Այս շնորհքը Հայր Աստծուց է,
  Ամենազոր Սուրբ Հոգուց է,
  Տեր Հիսուսի սուրբ խաչից է,
  Եռամիասնությունից է:
  2.Փրկությունն Աստծո խոստումն է,
  Հավատացողի բաժինն է,
  Քրիստոս խաչի վրա զոհվեց,
  Մեղքի թողություն տվեց:
  Երրորդ օրը մահին հաղթեց,
  Աշակերտներին հայտնվեց,
  Մեծ պատվերը նրանց թողեց,
  Համբարձվեց, Հոր աջում նստեց:
  3.«Այս պատվերն ազգերին տարե՛ք,
  Աշակերտե՛ք, մկրտե՛ք:
  Ամեն օր ձեզ հետ կլինեմ,
  Որբ չեմ թողնի, կպահեմ»:
  Տիեզերքի Արքան կգա,
  Բոլորին հատուցում կտա,
  Կդատի Ավետարանով,
  Ամեն մարդու\` իր գործերով:
  `,
  },
  {
    src: '72- Ուրախացեք մարդիկ.mp3',
    name: 'Ուրախացեք մարդիկ',
    author: 'Հոգևոր երգարան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ՄԱՐԳԱՐԵՆԵՐՆ ԱՍԱՑԻՆ ՓՐԿՉԻ ԳԱԼՍՏՅԱՆ ՄԱՍԻՆ</b>
  <span class="about">1.Մարգարեներն ասացին Փրկչի գալստյան մասին,</span>

  Որ շուտով աշխարհ կգա, | որ փրկի մարդկանց: - 2
  «Հիսուս» պիտի կոչվի նա, «Քրիստոս» պիտի կոչվի նա
  Ու կտանի նա մարդկանց | մահից դեպի կյանք: - 2
  Կրկ. – Ուրախացե՛ք դուք, ո՛վ մարդիկ,
  Հիսուսն է աշխարհ եկել,
  Ուրախացե՛ք դուք, ո՛վ մարդիկ,
  Մեզ համար Փրկիչ է ծնվել:
  2.Լուսնկա մի գիշեր էր, երբ ծնվեց Լուսո Որդին,
  Երկնքում աստղ փայլեց, | լույս փայլատակեց, - 2
  Հայտնվեց հրեշտակը մի քանի հովիվների.
  «Ավետիս եմ բերում ձեզ\` | Հիսուսն է ծնվել»: - 2
  3.Աստված մեզ այնպես սիրեց, որ իր Որդին ուղարկեց,
  Որ ազատի մեզ մեղքից | ու փրկի մահից: - 2
  Դե՛ հիմա եկե՛ք, մարդիկ, ցնծացե՛ք, Աստծո՛ որդիք,
  Փառաբանենք մեր Աստծուն\` | Փրկիչ է ծնվել: - 2
  `,
  },
  {
    src: '83-Ուրախությամբ սպասում ենք քեզ.mp3',
    name: 'Ուրախությամբ սպասում ենք քեզ',
    author: 'Հոգևոր երգարան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ՈւՐԱԽՈւԹՅԱՄԲ ՍՊԱՍՈւՄ ԵՆՔ ՔԵԶ</b>
  <span class="about">1. Ուրախությամբ սպասում ենք քեզ Հիսու՛ս մեր Տեր,</span>
  
  Հավատում ենք\` շուտով կգաս տանելու մեզ,
  Հավերժության մեջ կտեսնենք երեսը քո
  Ու կօրհնենք քեզ, կմեծարենք Աստծո Հոգով:
  Կրկ. – Հիսուս կգա, Հիսուս կգա,
  Ուրախացեք\` Տերը կգա:
  Արի Հիսուս, արի Փրկիչ, արի մեր Տեր,
  Ուրախությամբ, Հոգու սիրով սպասում ենք քեզ:
  2. Հարսը պատրաստ զարդարված է սուրբ զարդերով,
  Քո զորությամբ սպասում ենք քեզ սուրբ հանդեսով,
  Կատարվել են սուրբ խոսքերը այս աշխարհում,
  Արի, Հիսու՛ս, կարոտել ենք, քեզ ենք սպասում:
  `,
  },
  {
    src: '85-Եկեք տեսեք այս հրաշքը.mp3',
    name: 'Եկեք տեսեք այս հրաշքը',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ԵԿԵՔ ՏԵՍԵՔ ԱՅՍ ՀՐԱՇՔԸ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Եկեք տեսեք այս հրաշքը,
  Թող իմանա ողջ աշխարհքը\`
  Աստծո Որդին մարմին հագած\`
  Մարդու Որդի է նա դարձած:
  Կրկ. - Քեզ համար եմ ցածրացել ես,
  Որ ցավերից ազատեմ քեզ,
  Իսկ դու զղջումով աղոթիր,
  Գործած մեղքերդ խոստովանիր:
  2.Նա ծնվել է, չի ստեղծվել,
  Փառքը թողած\` ծառա դարձել,
  Հոժար կամքով խաչն հանձն առավ,
  Մեզ փրկելու զոհ նա դարձավ:
  3.Թե դու սրտանց խոստովանես,
  Քո մեղքերը կներեմ ես,
  Կբժշկեմ, կսրբեմ քեզ,
  Եւ հավիտյան ինձ հետ կապրես:
  `,
  },
  {
    src: '88-Իջիր Սուրբ Հոգով.mp3',
    name: 'Իջիր Սուրբ Հոգով',
    author: 'Հոգևոր երգարան',
    artist: 'Մարինե Վարդանյան',
    composer: 'Գենադի Դավթյան',
    lyrics: `
  <b>ԻՋԻՐ ՍՈւՐԲ ՀՈԳՈՎ</b>
  <span class="about">Խոսք՝ Հոգևոր երգարանից </br> Երաժշտության հեղինակ՝ Գենադի Դավթյանի</span>
  
  1. Իջիր Սուրբ Հոգով մեր տաճարում,
  Ծարաված քեզ ենք սպասում,
  Վառիր սիրտը մեր քո կրակով,
  Արի՝ լցրու մեզ քո հոգով:
  Կրկ. - Սուրբ Հոգով իջիր Տեր,
  Մեկ սիրտ, կանչում ենք մենք քեզ,
  Պենտեկոստեի պես մեզ վառիր,
  Լեզուներ մեզ պարգևիր:
  2. Սու՛րբ Հոգի, իջիր քեզնով լցվենք,
  Քեզնից զորություն առնենք,
  Վկաներ լինենք, ու քարոզենք,
  Ազգերին ողջ աշակերտենք:
  3. Սու՛րբ Հոգի օրհնիր մեզ, որ լինենք
  Սիրող, ուրախ և խաղաղ,
  Երկայնամիտ, քաղցր ու բարի,
  Հավատարիմ, հեզ ու ժուժկալ:
  4. Սու՛րբ Հոգի, իջիր ու մեզ օրհնիր,
  Պարգևներով դու գործիր\`
  Մարգարեություն, թարգմանություն,
  Հրաշք, նշաններ, զորություն։
  `,
  },
  {
    src: '9-Ես լսել եմ ինձ սիրում ես.mp3',
    name: 'Ես լսել եմ ինձ սիրում ես',
    author: 'Միլազիմ Ռասոյան',
    artist: 'Արմեն Բարսեղյան',
    composer: 'Միլազիմ Ռասոյան',
    lyrics: `
  <b>ԵՍ ԼՍԵԼ ԵՄ\` ԻՆՁ ՍԻՐՈւՄ ԵՍ</b>
  <span class="about">Հեղինակ՝ Միլազիմ Ռասոյան</span>
  
  1.Ես լսել եմ\` ինձ սիրում ես, Տե՛ր Հիսուս Փրկիչ,
  Եւ ուզում ես որ ինձ փրկես, դու իմ Արարիչ:
  Ես էլ քո կարիքն ունեմ, դու ինքդ գիտես: - 4
  2.Հիսու՛ս իմ Տեր, մեղավոր եմ, մեղքերս ծով են,
  Բայց ես գիտեմ քո արյունով քավություն ունեմ:
  Ես խնդրում եմ, որ ինձ մաքրես ու ինձ սուրբ անես: - 4
  3.Հիսուս Տերը ձայնս լսեց ու ինձ ողորմեց,
  Իր արդարությամբ իմ Տերը ինձ արդարացրեց:
  Այժմ ես Աստծո զավակ եմ, իր ժառանգորդն եմ: - 4
  `,
  },
  {
    src: '91-Հիսուսն է միայն ամեն բան.mp3',
    name: 'Հիսուսն է միայն ամեն բան',
    author: 'Հոգևոր երգարան',
    artist: 'Գայանե Բադալյան',
    composer: 'Հոգևոր երգարան',
    lyrics: `
  <b>ՀԻՍՈւՍՆ Է ՄԻԱՅՆ ԱՄԵՆ ԲԱՆ</b>

  1. Հիսուսն է միայն ամեն բան, նա է Ալֆան ու Օմեղան,
  Առաջինը ու վերջինը իմ կյանքի մեջ:
  Փոթորիկներ երբ որ գան, կյանքս անելու ցիրուցան,
  Իր զորավոր բազուկներով գրկում է ինձ:
  Ուստի երգում եմ բարձրաձայն\` իր անունին վստահելով,
  Իմ բոլոր մեղքերը ներեց ու ցավերից ինձ ազատեց:
  Հիսուս միայն ինձ համար ամեն բան է:
  2. Շատերն այսպես անցնում, գնում, առանց հավատքի են մնում,
  Հավիտենական պատիժին արժանանում:
  Հիսուս սրա համար եկավ, խաչափայտի վրա մեռավ,
  Որ ամեն ով որ հավատա նա փրկվի:
  Ուստի նրան դու վստահիր, մեղքերդ իրեն խոստովանիր,
  Հույսդ միայն Հիսուսն արա, նեղ ճամփայով մտիր գնա:
  Հիսուս միայն ինձ համար ամեն բան է:
  Հիսուս միայն, Հիսուս միայն, Հիսուս միայն:
  `,
  },
];
