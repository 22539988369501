export * from './bible';

// TOD0: Split into multiple files

export const TESTAMENTS_TYPES = {
  old: 'old',
  new: 'new',
};

export type ITranslations = Record<string, string | undefined>

export interface ITranslation {
  language: string;
  language_name: string;
  name: string;
  translator_abr: string;
  translator_name: string;
  id: string;
}

// export interface ITranslations
export interface IYoutubPlaylist {
  etag: string;
  items: any[];
  kind: string;
  pageInfo: {};
}
export interface ILessonsMenuItem {
  title: string;
  number: number;
  url: string;
}

export interface ILessonConclusion {
  title: string;
  text: string;
}
export interface ILessonSubLesson {
  sectionTitle: string;
  bodyText: string;
}
export interface ISectionsInfo {
  title: string;
  sections: string[];
  sectionsLinks: Record<string, number>;
}
export interface IForeword {
  text: string;
}
export interface IEndNotInfo {
  title: string;
  subjects: string[];
}
export interface ILesson {
  YTVideoId: string;
  foreword: IForeword;
  conclusion: ILessonConclusion;
  number: number;
  sectionsInfo: ISectionsInfo;
  subLessons: ILessonSubLesson[];
  title: string;
  versesLinks: Record<string, string>;
  endnotinfo: IEndNotInfo;
}

export interface IWhatIBelieveSectionItem {
  title: string;
  description: string;
}
export interface IWhatIBelieve {
  title: string;
  sections: IWhatIBelieveSectionItem[][];
}

interface IPlItem {
  snippet: ISnippet;
}

export interface IPlaylistData {
  pageInfo: IPageInfo;
  items: IPlItem[];
  nextPageToken?: string;
  prevPageToken?: string;
}
export interface ISnippet {
  channelId: string;
  channelTitle: string;
  description: string;
  playlistId: string;
  position: number;
  publishedAt: string;
  resourceId: IResourceId;
  title: string;
  thumbnails: {};
}
export interface IResourceId {
  kind: string;
  videoId: string;
}
export interface IPageInfo {
  resultsPerPage: number;
  totalResults?: number;
}

export interface IMemo {
  userId: string;
  text: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
  _id: string;
}

export interface IBookmark {
  _id: string;
  userId: string;
  bookNumber: number;
  chapterNumber: number;
  verseIndex: number;
  title: string;
  translationId: number;
  createdAt: string;
  updatedAt: string;
}

export interface INote {
  _id?: string;
  verseIndex: number;
  text: string;
  bookNumber: number;
  chapterNumber: number;
}

export type ICreateMemo = Omit<IMemo, '_id' | 'createdAt' | 'updatedAt'>;
