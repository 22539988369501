// load string from localStorage and convert into an Object

import { logWarn } from 'config/logging';

// invalid output must be undefined
export const getFromLocalStorage = <T>(item: string): T | undefined => {
  try {
    const serializedItem = localStorage.getItem(item);
    if (!serializedItem || serializedItem === 'undefined') return;
    return JSON.parse(serializedItem);
  } catch (e) {
    logWarn(e);
    return undefined;
  }
};

// convert object to string and store in localStorage
export const setToLocalStorage = (key: string, value: any) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
    return getFromLocalStorage(key);
  } catch (e) {
    logWarn(e);
    return undefined;
  }
};

export const removeFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    logWarn(e);
  }
};
