import { RootState } from 'store/types';
import { INote } from 'types';

interface INotesSliceState {
  notes: INote[];
  loading?: boolean;
}

export const getNotesState = (state: RootState): INotesSliceState => state.notes;
export const getNotes = (state: RootState): INote[] => getNotesState(state).notes;
