export const formatVerses = (text: string, versesLinks: Record<string, string>) => {
  const versesKeys = Object.keys(versesLinks);
  let formattedBodyText = text;
  versesKeys.forEach(key => {
    const regexp = new RegExp(key, 'g');
    formattedBodyText = formattedBodyText.replace(regexp, `<b class="sublessons__item__verse" data-name="${encodeURI(key)}">${key}</b>`);
  });

  return formattedBodyText;
};
