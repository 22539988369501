import {
  YOUTUBE_PLAYLIST_IDS,
} from 'config/constants';
import { useLanguage, useQueryParams } from 'core/hooks';

const {
  YOUTUBE_ARSEN_PLAYLIST_ID_RU,
  YOUTUBE_XNKO_PLAYLIST_ID_RU,
  YOUTUBE_MILAZIM_PLAYLIST_ID_RU,
  YOUTUBE_ARMEN_PLAYLIST_ID_RU,
  YOUTUBE_XNKO_PLAYLIST_ID,
  YOUTUBE_MILAZIM_PLAYLIST_ID,
  YOUTUBE_ARSEN_PLAYLIST_ID,
  YOUTUBE_TIGRAN_PLAYLIST_ID,
  YOUTUBE_ARMEN_PLAYLIST_ID,
  YOUTUBE_DAVIT_PLAYLIST_ID,
  YOUTUBE_EPHESIANS_PLAYLIST_ID,
  YOUTUBE_MYSTERIOUS_SECRETS_PLAYLIST_ID,
  YOUTUBE_CROSS_REMEMBER_PLAYLIST_ID,
} = YOUTUBE_PLAYLIST_IDS;

const usePreachesSidebar = () => {
  const queries = useQueryParams();
  const { currentLanguage } = useLanguage();
  const lang = queries.get('lang');

  const speakersData = [
    ...(lang === 'am' ? [
      {
        label: currentLanguage.milazim,
        key: YOUTUBE_MILAZIM_PLAYLIST_ID,
        photo: '',
      },
      {
        label: currentLanguage.xnko,
        key: YOUTUBE_XNKO_PLAYLIST_ID,
        photo: '',
      },
      {
        label: currentLanguage.arsen,
        key: YOUTUBE_ARSEN_PLAYLIST_ID,
        photo: '',
      },
      {
        label: currentLanguage.tigran,
        key: YOUTUBE_TIGRAN_PLAYLIST_ID,
        photo: '',
      },
      {
        label: currentLanguage.armen,
        key: YOUTUBE_ARMEN_PLAYLIST_ID,
        photo: '',
      },
      {
        label: currentLanguage.davit,
        key: YOUTUBE_DAVIT_PLAYLIST_ID,
        photo: '',
      },
    ] : []),
    ...(lang === 'ru' ? [
      {
        label: currentLanguage.milazim,
        key: YOUTUBE_MILAZIM_PLAYLIST_ID_RU,
        photo: '',
      },
      {
        label: currentLanguage.xnko,
        key: YOUTUBE_XNKO_PLAYLIST_ID_RU,
        photo: '',
      },
      {
        label: currentLanguage.arsen,
        key: YOUTUBE_ARSEN_PLAYLIST_ID_RU,
        photo: '',
      },
      {
        label: currentLanguage.armen,
        key: YOUTUBE_ARMEN_PLAYLIST_ID_RU,
        photo: '',
      },
    ] : []),
  ];

  const groups = lang === 'am' ? [
    {
      label: currentLanguage.ephesiansPlaylist,
      key: YOUTUBE_EPHESIANS_PLAYLIST_ID,
    },
    {
      label: currentLanguage.mysteriousSecretsPlaylist,
      key: YOUTUBE_MYSTERIOUS_SECRETS_PLAYLIST_ID,
    },
    {
      label: currentLanguage.crossRememberPlaylist,
      key: YOUTUBE_CROSS_REMEMBER_PLAYLIST_ID,
    },
  ] : [];

  return {
    speakersData,
    groups,
  };
};

export default usePreachesSidebar;
