import languages from 'config/languages';
import generalSlice from 'store/slices/generalSlice';
import { useCallback } from 'react';
import { APP_DEFAULT_LANGUAGE, APP_LANGUAGES_ENUM, LANGUAGES_SHORTCUTS } from 'config/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import selectLanguages from 'store/selectors/selectLanguages';

const useLanguage = (shortcut?: APP_LANGUAGES_ENUM) => {
  const dispatch = useAppDispatch();

  const appLangShortcut = useAppSelector(selectLanguages);
  const key = shortcut || appLangShortcut || APP_DEFAULT_LANGUAGE;

  const currentLanguage = languages[key];

  const setCurrentLanguage = useCallback((language: APP_LANGUAGES_ENUM) => dispatch(generalSlice.actions.setLanguage(language)), [dispatch]);

  return {
    langShortcut: appLangShortcut,
    headerLanguageShortcut: LANGUAGES_SHORTCUTS[appLangShortcut],
    currentLanguage,
    setCurrentLanguage,
  };
};

export default useLanguage;
