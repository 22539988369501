import moment from 'moment';

import { useEffect, useState } from 'react';

const isReadyToCheck = () => {
  const now = moment().locale('hy-am');
  const today = now.day();
  const wednesdayStart = moment('18:00', 'HH:mm');
  const wednesdayEnd = moment('20:35', 'HH:mm');
  const sundayStart = moment('11:00', 'HH:mm');
  const sundayEnd = moment('14:35', 'HH:mm');

  if ((today === 3 && now.isBetween(wednesdayStart, wednesdayEnd)) || (today === 0 && now.isBetween(sundayStart, sundayEnd))) {
    return true;
  }

  return false;
};

const useYoutubeChannel = () => {
  const [channelIsLive, setChannelIsLive] = useState(false);
  const [streamTitle] = useState('');

  useEffect(() => {
    if (isReadyToCheck()) {
      setChannelIsLive(true);
    }
  }, []);

  return {
    channelIsLive,
    streamTitle,
  };
};

export default useYoutubeChannel;
