import Typography from 'antd/es/typography';
import { useLanguage } from 'core/hooks';
import { FaBookmark } from 'react-icons/fa';

import Flex from '../Flex';

const { Title } = Typography;

function BookmarksEmptyState() {
  const { currentLanguage } = useLanguage();
  return (
    <Flex height="var(--full)" alignItems="center" justifyContent="center">
      <Flex
        justifyContent="center"
        direction="column"
        alignItems="center"
        marginTop="var(--gap-main)"
        marginBottom="var(--gap-small)"
        opacity={0.3}
      >
        <FaBookmark fontSize="var(--font-larger-size)" color="var(--main-coral)" />
        <Title style={{ marginTop: 'var(--gap-small)', color: 'var(--main-white)' }} level={5}>
          {currentLanguage.bookmarks_empty}
        </Title>
      </Flex>
    </Flex>
  );
}

export default BookmarksEmptyState;
