import { APP_LANGUAGES_ENUM } from 'config/constants';

import { SongsLanguageKey, TSong } from '../types';

import armenianSongs from './armenianLyrics';
import kurdishSongs from './kurdishLyrics';

export default {
  [APP_LANGUAGES_ENUM.am]: armenianSongs,
  [APP_LANGUAGES_ENUM.ku]: kurdishSongs,
} as any as Record<SongsLanguageKey, TSong[]>;
