/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { APP_DEFAULT_LANGUAGE, APP_LANGUAGES_ENUM } from 'config/constants';

export interface generalSliceState {
  language: APP_LANGUAGES_ENUM;
}

const initialState: generalSliceState = {
  language: APP_DEFAULT_LANGUAGE,
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<APP_LANGUAGES_ENUM>) => {
      state.language = action.payload;
    },
  },
});

export default generalSlice;
