import { IBibleTranslation } from 'types';
import { BIBLE_API } from 'config/constants';

import Fetch from '../fetch';

export const getAllBibleTranslations = async (): Promise<IBibleTranslation[]> => {
  const response = await Fetch.get(`${BIBLE_API}/translations`, {
    isCache: true,
    cache: 'force-cache',
    // headers: {
    //   'Cache-Control': 'force-cache max-age=60000',
    // },
  });

  return response as IBibleTranslation[];
};

export const smt = {};
