import { useMemo, useState } from 'react';
import { Typography, List } from 'antd';
import { useQueryParams } from 'core/hooks';
import Flex from 'components/common/Flex';
import { APP_LANGUAGES_ENUM } from 'config/constants';
import { FaMusic } from 'react-icons/fa';

import AudioItemAction from './AudioItemAction';
import audiosData from './audiosLyrics';
import Lyrics from './Lyrics';
import { AUDIO_PATH } from './data';
import Player from './Player';

const { Text } = Typography;

function Audios() {
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(0);
  const [playing, setPlaying] = useState(false);
  const lang = useQueryParams().get('lang') as APP_LANGUAGES_ENUM;
  const audioData = useMemo(() => audiosData[lang || APP_LANGUAGES_ENUM.am], [lang]);

  const handleAudioSelect = (index: number) => {
    if (currentTrackIndex === index) {
      return playing ? setPlaying(false) : setPlaying(true);
    }

    setCurrentTrackIndex(index);
    setPlaying(true);
  };

  return (
    <Flex className="audios" width="100%">
      <div className="audios__playlist">
        <List
          itemLayout="horizontal"
          dataSource={audioData}
          renderItem={(item, index) => (
            <List.Item
              onClick={() => handleAudioSelect(index)}
              style={{
                cursor: 'pointer',
                padding: '5px 24px',
                backgroundColor: currentTrackIndex === index ? 'var(--main-light)' : 'transparent',
              }}
            >
              <List.Item.Meta
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                avatar={<AudioItemAction activeItem={currentTrackIndex === index} playing={playing} />}
                title={<Text strong>{item.name}</Text>}
                description={<Text type="secondary">{item.artist}</Text>}
              />
            </List.Item>
          )}
        />
      </div>
      <div className="audios__current-song-details">
        <div className="audios__player">
          <Player
            playlist={audioData.map(item => `/audios/${AUDIO_PATH[lang]}/${item.src}`)}
            currentTrackIndex={currentTrackIndex}
            playing={playing}
            setPlaying={setPlaying}
            setCurrentTrackIndex={setCurrentTrackIndex}
          />

        </div>
        {audioData[currentTrackIndex]?.lyrics
          ? (
            <Lyrics lyrics={audioData[currentTrackIndex]?.lyrics} />
          ) : (
            <Flex alignItems="center" justifyContent="center" className="audios__empty-state">
              <FaMusic color="var(--main-secondary)" fontSize="250px" />
            </Flex>
          )}
      </div>
    </Flex>
  );
}

export default Audios;
