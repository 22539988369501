import { YOUTUBE_PLAYLIST_IDS } from 'config/constants';

const {
  YOUTUBE_GOD_KINGDOM,
  YOUTUBE_BELIEVE_AND_RECOGNIZE,
  YOUTUBE_PEOPLE_QUESTIONS,
} = YOUTUBE_PLAYLIST_IDS;

export const programsData = [
  {
    label: 'PADŞATYA XWEDÊ',
    key: YOUTUBE_GOD_KINGDOM,
    photo: '',
  },
  {
    label: 'BAWERKRiN Û NASKRiN',
    key: YOUTUBE_BELIEVE_AND_RECOGNIZE,
    photo: '',
  },
  {
    label: 'PIRSÊN CIMETÊ - JI K\'ITÊBA PÎROZ',
    key: YOUTUBE_PEOPLE_QUESTIONS,
    photo: '',
  },
];
