import { BOOKMARKS_API } from 'config/constants';
import { logError } from 'config/logging';

import Fetch from './fetch';

enum IRequestCredentials {
  include = 'include',
}

interface ICreateBookmarks {
  title: string;
  verseIndex: number;
  bookNumber: number;
  chapterNumber: number;
  translationId: number;
}

const fetchConfigs = {
  headers: { 'Content-Type': 'application/json' },
  credentials: IRequestCredentials.include,
};

export const createBookmarks = async ({ bookNumber, chapterNumber, verseIndex, translationId, title }: ICreateBookmarks): Promise<any> => {
  try {
    const response = await Fetch.post(BOOKMARKS_API, {
      body: {
        verseIndex,
        bookNumber,
        chapterNumber,
        translationId,
        title,
      },
      ...fetchConfigs,
    });
    return response;
  } catch (error) {
    logError('Error creating bookmark:', JSON.stringify(error));
    throw error;
  }
};

export const getBookmarks = async (): Promise<any> => {
  try {
    const response = await Fetch.get(`${BOOKMARKS_API}`, fetchConfigs);
    return response;
  } catch (error) {
    logError('Error fetching bookmark:', JSON.stringify(error));
    throw error;
  }
};

export const removeBookmark = async (id: string): Promise<any> => {
  try {
    const response = await Fetch.delete(`${BOOKMARKS_API}/${id}`, fetchConfigs);
    return response;
  } catch (error) {
    logError('Error removing bookmark:', JSON.stringify(error));
    throw error;
  }
};
