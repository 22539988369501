import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Flex from 'components/common/Flex';

import NavbarItems from './NavbarItems';

function MobileWrapper({
  toMemos,
  setMenuOpened,
}: {
  toMemos: () => void;
  setMenuOpened: (value: boolean) => void;
}) {
  return (
    <div className="navbar__mobile">
      <Flex justifyContent="flex-end">
        <Button
          type="ghost"
          onClick={() => setMenuOpened(false)}
        >
          <CloseCircleOutlined
            style={{
              fontSize: 'var(--font-mid-size)',
              color: 'white',
            }}
          />
        </Button>
      </Flex>
      <NavbarItems
        className="navbar__mobile-menu"
        toMemos={toMemos}
        isMobileDevice
      />
    </div>
  );
}

export default MobileWrapper;
