import { useCallback, useState } from 'react';
import { TypeSearchResult } from 'types';
import { chapterSearch, IQueryParams } from 'core/services/bible/chapters.search.service';

interface IChaptersSearch {
  translationId?: number;
  searchedWord?: string;
  queryParams?: IQueryParams;
}
const useChaptersSearch = () => {
  const [searchResult, setSearchResult] = useState<TypeSearchResult | null>();

  const searchForWord = useCallback(({ translationId, searchedWord, queryParams }: IChaptersSearch) => {
    if (!searchedWord) {
      setSearchResult(null);
      return;
    }

    chapterSearch(searchedWord, translationId, queryParams)
      .then(result => !result.errorMessage && setSearchResult(result));
  }, []);

  return {
    searchResult,
    searchForWord,
  };
};

export default useChaptersSearch;
