const FROM_NUM_TO_NUM_REGEXP = / {0,3}(?<=\s|^)\d+/g;

const addSpaceAfterDigitIfNotExist = (text: string) => {
  const digit = text.match(/\d+/)?.[0];
  return text?.replace(/\d+ ?/, `${digit} `);
};

interface IGenerateBookmarkIdProps {
  bookNumber: number;
  chapterNumber: number;
  verseIndex: number;
  translationId: number;
}

export const getSplittedText = (text: string, isPreface = false): string[] => {
  if (isPreface) {
    return [text];
  }

  let match;
  const indexes = [];
  let sentenceArr = [];
  while (match !== null) {
    match = FROM_NUM_TO_NUM_REGEXP.exec(text);
    // This is necessary to avoid infinite loops with zero-width matches
    if (match?.index === FROM_NUM_TO_NUM_REGEXP.lastIndex) FROM_NUM_TO_NUM_REGEXP.lastIndex++;
    if (match?.index) indexes.push(match.index);
  }

  indexes.push(text.length);
  for (let i = 0; i < indexes.length - 1; i++) {
    sentenceArr.push(`${text.slice(indexes[i], indexes[i + 1])}`);
  }

  sentenceArr = sentenceArr.map((sent, index) => {
    let newSent = addSpaceAfterDigitIfNotExist(sent).replace(/\n/g, '');
    if (index === 0) newSent = newSent?.replace(/(?<!\d)0/, '');
    return newSent;
  });
  const numbersInFirstVerse = sentenceArr[0].match(/\d+ /gm);
  if (numbersInFirstVerse !== null) {
    sentenceArr.unshift('');
  }

  return sentenceArr;
};

export const generateBookmarkId = ({
  bookNumber,
  chapterNumber,
  verseIndex,
  translationId,
}: IGenerateBookmarkIdProps) => (
  `bookmark-${bookNumber}-${chapterNumber}-${verseIndex}-${translationId}`
);
