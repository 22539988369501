import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AUTH_API } from 'config/constants';

import { IAuthMessage, ILoginParams, IRegisterParams, ILoginResponse } from './types';

// configure header's Content-Type as JSON
const config = { headers: { 'Content-Type': 'application/json' }, credentials: 'include', withCredentials: true };

export const userLogin = createAsyncThunk<ILoginResponse, ILoginParams, { rejectValue: IAuthMessage | unknown }>(
  'auth/login',
  async (userParams, { rejectWithValue }) => {
    try {
      const url = `${AUTH_API}/login`;
      const response = await axios.post(url, userParams, config);

      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) return rejectWithValue(error);

      if (error.response) return rejectWithValue(error.response.data);
      if (error.request) return rejectWithValue(error.request);
      return rejectWithValue(error.toJSON());
    }
  },
);

export const registerUser = createAsyncThunk<IAuthMessage, IRegisterParams, { rejectValue: IAuthMessage | unknown }>(
  'auth/register',
  async (userParams, { rejectWithValue }) => {
    try {
      const url = `${AUTH_API}/register`;
      const response = await axios.post(url, userParams, config);

      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) return rejectWithValue(error);

      if (error.response) return rejectWithValue(error.response.data);
      if (error.request) return rejectWithValue(error.request);
      return rejectWithValue(error.toJSON());
    }
  },
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AUTH_API}/logout`;
      const response = await axios.get(url, config);

      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) return rejectWithValue(error);

      if (error.response) return rejectWithValue(error.response.data);
      if (error.request) return rejectWithValue(error.request);
      return rejectWithValue(error.toJSON());
    }
  },
);
