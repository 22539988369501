import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import isEqual from 'react-fast-compare';

import type { RootState, AppDispatch } from './types';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMemoSelector: TypedUseSelectorHook<RootState> = (
  selector,
  comparator = isEqual,
) => {
  const resultRef = useRef<any>(null);
  const isMemoEqualRef = useRef<boolean>(true);

  const result = useSelector(selector, (prev, next) => {
    const equality = comparator(prev, next);
    isMemoEqualRef.current = equality;
    return equality;
  });

  if (!isMemoEqualRef.current || !comparator(result, resultRef.current)) {
    resultRef.current = result;
    isMemoEqualRef.current = true;
  }

  return resultRef.current;
};
