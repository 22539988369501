import { Skeleton } from 'antd';

import './style.scss';

export default function NavigationLoader() {
  return (
    <div className="bible-navigation-loader">
      <Skeleton.Button style={{ width: 300 }} active />
      <div>
        <Skeleton.Avatar active />
        <Skeleton.Button style={{ margin: '0 24px', width: 300 }} active />
        <Skeleton.Avatar active />
      </div>
      <div />
    </div>
  );
}
