import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'antd/es/button';
import Popover from 'antd/es/popover';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import useChapterText from 'core/hooks/bible/useChapterText';
import { useBibleTranslations, useLanguage } from 'core/hooks';
import { IoBookmarkSharp } from 'react-icons/io5';
import Flex from 'components/common/Flex';
import { getAuthState } from 'store/selectors/auth';
import { useAppDispatch, useMemoSelector } from 'store/hooks';
import bibleTranslationsSlice from 'store/slices/bibleTranslationsSlice';
import { getBibleTranslationsIds } from 'store/selectors/bibleTranslations';

import NavigationLoader from '../Loaders/NavigationLoader';
import useBookNavigation from '../useBookNavigation';

import Translations from './Translations';
import BooksSelection from './BooksSelection';

import '../style.scss';

const { Text } = Typography;

function Navigation({
  showBookmarkDrawer,
}: {
  showBookmarkDrawer: () => void;
}) {
  const dispatch = useAppDispatch();
  const { userInfo } = useMemoSelector(getAuthState);
  const selectedTranslationsSelector = useMemoSelector(getBibleTranslationsIds);
  const { booksListData, setCurrentBook, setCurrentChapter, setCurrentVerse } = useBookNavigation();
  const { currentLanguage, langShortcut } = useLanguage();
  const { bibleTranslations } = useBibleTranslations();
  const [selectedTranslations, setSelectedTranslations] = useState<(string | number)[]>(selectedTranslationsSelector);
  const { getNextChaptersTexts, getPrevChaptersTexts, chaptersTexts } = useChapterText();
  const currentChapter = useMemo(() => chaptersTexts.find(({ language }) => language === langShortcut), [chaptersTexts, langShortcut]);

  const handleNavigate = (navigate?: string) => {
    if (navigate === 'next') return getNextChaptersTexts();
    return getPrevChaptersTexts();
  };

  const handleBookSelection = (bookNumber: number) => {
    setCurrentBook(bookNumber);
  };

  const handleChapterSelection = (chapterNumber: number) => {
    setCurrentChapter(chapterNumber);
  };

  const handleVerseSelection = (chapterNumber: number) => {
    setCurrentVerse(chapterNumber);
  };

  useEffect(() => {
    setSelectedTranslations(selectedTranslationsSelector);
  }, [selectedTranslationsSelector]);

  const handleTranslationsSelection = useCallback((checkedValues: (string | number)[]) => {
    if (checkedValues.length === 0) checkedValues.push(1);
    if (checkedValues.length === 1 && checkedValues[0] === 'notes') {
      checkedValues.push(1);
    }

    dispatch(bibleTranslationsSlice.actions.changeTranslations(checkedValues));
    setSelectedTranslations(checkedValues);
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo?._id) {
      const translations = [...selectedTranslations];
      const notesIndexInTranslation = translations.findIndex(item => item === 'notes');
      if (notesIndexInTranslation !== -1) {
        translations.splice(notesIndexInTranslation, 1);
      }

      if (JSON.stringify(selectedTranslations) !== JSON.stringify(translations)) {
        setSelectedTranslations(translations);
        handleTranslationsSelection(translations);
      }
    }
  }, [handleTranslationsSelection, selectedTranslations, userInfo]);
  return (
    <div>
      {booksListData.length ? (
        <div className="bible-navigation">
          <Flex alignItems="center">
            {userInfo?._id && (
              <Tooltip placement="bottom" title={currentLanguage.bookmarks}>
                <Button
                  className="bible-navigation__bookmark-button"
                  onClick={showBookmarkDrawer}
                >
                  <IoBookmarkSharp />
                </Button>
              </Tooltip>
            )}
            <Popover
              title={currentLanguage.bibleTranslations}
              trigger="click"
              placement="bottom"
              content={(
                <Translations
                  bibleTranslations={bibleTranslations}
                  selectedTranslations={selectedTranslations}
                  onTranslationSelect={handleTranslationsSelection}
                />
              )}
            >
              <Button>{currentLanguage.bibleTranslations}</Button>
            </Popover>
          </Flex>
          <div className="chapter-title-container">
            <Text
              strong
              ellipsis={{ tooltip: `${currentChapter?.number}. ${currentChapter?.title}` }}
              style={{ width: 400 }}
            >
              {currentChapter?.number}
              {currentChapter?.title}
            </Text>
          </div>
          <div className="bible-navigation__buttons-container">
            <Button shape="circle" onClick={() => handleNavigate()}>
              <LeftOutlined />
            </Button>
            <BooksSelection
              booksListData={booksListData}
              onBookSelect={handleBookSelection}
              onChapterSelect={handleChapterSelection}
              onVerseSelect={handleVerseSelection}
            />
            <Button shape="circle" onClick={() => handleNavigate('next')}>
              <RightOutlined />
            </Button>
          </div>
        </div>
      ) : (
        <NavigationLoader />
      )}
    </div>
  );
}

export default Navigation;
