import { useMemo } from 'react';
import Button from 'antd/es/button';

function ChaptersList({
  currentChapter,
  currentBookChaptersCount,
  onChapterSelect,
}: {
  currentChapter: number;
  currentBookChaptersCount: number;
  onChapterSelect: (chapterNumber: number) => void;
}) {
  const chaptersNumbers = useMemo(() => (new Array(currentBookChaptersCount)).fill(''), [currentBookChaptersCount]);

  return (
    <div className="bible-navigation__chapters-container">
      {chaptersNumbers.map((_, index) => (
        <Button
        // eslint-disable-next-line react/no-array-index-key
          key={index.toString()}
          type={currentChapter === index + 1 ? 'primary' : 'default'}
          onClick={() => onChapterSelect(index + 1)}
        >
          {index + 1}
        </Button>
      ))}
    </div>
  );
}

export default ChaptersList;
