/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { IMemo } from 'types';

import {
  createMemo,
  getMemosByUserId,
  updateMemo,
  removeMemo,
} from './actions';

interface MemosState {
  memos: IMemo[];
  memo: any;
  loading?: boolean;
}

const initialState: MemosState = {
  memos: [],
  memo: {},
  loading: false,
};

const memosSlice = createSlice({
  name: 'memos',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(createMemo.pending, state => {
        state.loading = true;
      })
      .addCase(createMemo.fulfilled, (state, action) => {
        state.loading = false;
        const createdMemo = action.payload;
        state.memos = [
          createdMemo,
          ...state.memos,
        ];
      })
      .addCase(createMemo.rejected, state => {
        state.loading = false;
        state.memo = {};
      })
      .addCase(getMemosByUserId.pending, state => {
        state.loading = true;
      })
      .addCase(getMemosByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.memos = action.payload;
      })
      .addCase(getMemosByUserId.rejected, state => {
        state.loading = false;
        state.memos = [];
      })
      .addCase(updateMemo.pending, state => {
        state.loading = true;
      })
      .addCase(updateMemo.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.memos.findIndex(memo => memo._id === action.payload._id);
        if (index !== -1) {
          state.memos[index] = {
            ...state.memos[index],
            ...action.payload,
          };
        }
      })
      .addCase(updateMemo.rejected, state => {
        state.loading = false;
        state.memo = {};
      })
      .addCase(removeMemo.pending, state => {
        state.loading = true;
      })
      .addCase(removeMemo.fulfilled, (state, action) => {
        state.loading = false;
        const { meta } = action;
        const index = state.memos.findIndex(memo => memo._id === meta.arg.id);
        if (index !== -1) {
          state.memos.splice(index, 1);
        }
      })
      .addCase(removeMemo.rejected, state => {
        state.loading = false;
        state.memo = {};
      });
  },
});

export default memosSlice;
