import { APP_LANGUAGES_ENUM } from 'config/constants';

import amWhatIBelieve from './whatIBelieve_am';
import enWhatIBelieve from './whatIBelieve_en';
import ruWhatIBelieve from './whatIBelieve_ru';
import kuWhatIBelieve from './whatIBelieve_ku';

export type whatIBelieveTranslationKeys = APP_LANGUAGES_ENUM.am | APP_LANGUAGES_ENUM.ru | APP_LANGUAGES_ENUM.en | APP_LANGUAGES_ENUM.ku;

export default {
  [APP_LANGUAGES_ENUM.am]: amWhatIBelieve,
  [APP_LANGUAGES_ENUM.en]: enWhatIBelieve,
  [APP_LANGUAGES_ENUM.ku]: kuWhatIBelieve,
  [APP_LANGUAGES_ENUM.ru]: ruWhatIBelieve,
};
