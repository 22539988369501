interface IVersesMemo {
  text: string;
  shortTitle: string;
  translationId: number;
  verseNumber: number;
  chapterNumber: number;
  location: number[];
}

interface ICombinedVerses {
  text: string;
  verses: number[];
}

export const versesMemoReducer = (accumulator: any, verse: IVersesMemo) => {
  const { shortTitle, verseNumber, chapterNumber } = verse;

  const key = `${shortTitle} ${chapterNumber}`;

  if (!accumulator[key]) {
    accumulator[key] = {
      text: '',
      verses: [],
    };
  }

  accumulator[key].text += `${verse.text}\n`;
  accumulator[key].verses.push(verseNumber);

  return accumulator;
};

export const combinedMemoText = (memoObject: Record<string, ICombinedVerses>) => {
  let combinedText = '';

  for (const key in memoObject) {
    if (memoObject.hasOwnProperty(key)) {
      const verses = memoObject[key].verses.join(',');
      combinedText += `${key}: ${verses}\n${memoObject[key].text}\n`;
    }
  }

  return combinedText;
};
