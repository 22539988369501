interface IConclusionProps {
  title: string;
  description: string;
}

function Conclusion({ title, description }: IConclusionProps) {
  return (
    <div className="conclusion">
      <p
        className="conclusion__title"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
        className="conclusion__description"
      />
    </div>
  );
}

export default Conclusion;
