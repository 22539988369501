import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/types';
import { IBookmark } from 'types';

interface IBookmarksSliceState {
  bookmarks: IBookmark[];
  loading?: boolean;
}

export const getBookmarksState = (state: RootState): IBookmarksSliceState => state.bookmarks;
export const getBookmarks = (state: RootState): IBookmark[] => getBookmarksState(state).bookmarks;
export const getBookmarksByTranslationId = (translationId: number) => createSelector(
  [getBookmarks],
  (bookmarks: IBookmark[]) => bookmarks.filter(bookmark => bookmark.translationId === translationId),
);
export const getBookmarksTranslationIds = createSelector(
  [getBookmarks],
  (bookmarks: IBookmark[]) => [...new Set(bookmarks.map(bookmark => bookmark.translationId))],
);
