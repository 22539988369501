export default {
  title: 'ХРИСТИАНСКОЕ ВЕРОИСПОВЕДАНИЕ',
  sections: [
    [
      {
        title: 'БИБЛИЯ',
        description: `
          Слово и мысль Бога Она,
          Словами людей изречена,
          Евангельем обобщена.
          И включает в себя Она
          Все нужды и чувства человека,
          Ибо Библия соверщенна.
        `,
      },
      {
        title: 'БОГ',
        description: `
          Существо Триединое, Самосущий Дух
          Отец, и Сын, и Святой Дух
          Совершен Он любовью и святостью,
          Могуществом, знанием, мудростью.
          Он создал землю и небо
          И в них все существа.
          Он вездесущий, всë блюдëтся Им
          Он вечно тот же, не изменим.
          С личностью каждой Он
          И кто избрал Его, Он в нëм.
        `,
      },
      {
        title: 'ЧЕЛОВЕК',
        description: `
          Рождëнный от Евы, потомство Адама
          Десницею Божьей сотворëнные оба
          По образу и подобию Бога,
          Чтоб имели власт они оба.
          Однако Адам заповедь отверг
          Стал грешником, нарушив завет.
          Беззаконие принял от дьявола,
          В нëм изменилась природа святая.
          От закона жизни он отдалился,
          Так от славы Божией лишился.
          Смерти закон вошëл в него
          Ко греху устремились желанья его.
          К беззаконию стремится он
          Природу греховную приобрëл он
          И осквернëнная эта природа
          Стало наследством человеческого рода.
        `,
      },
      {
        title: 'ЗАКОН БОЖИЙ',
        description: `
          Свят, выше он законов всех
          И далëк от беззаконий всех
          Им открылось право и грех.
          Само же бесправие есть грех.
        `,
      },
    ],
    [
      {
        title: 'ИИСУС ХРИСТОС',
        description: `
          Одна из Личностей Святого Божества Он,
          Сын единородный, Бога естеством.
          Исшедший от Бога, но не сотворëн.
          Равный Богу, но унижëн.
          От Девы Марии, силоë Бога рождëн,
          Плоть и природу человека Он обрëл.
          Сын Божий, но на земле прожил
          И в этой плоти поклоняем был.
          Искушëн, ка мы, но не согрешил.
          И, как святой агнец, за нас распятым был.
          На кресте Господь смерть за нас вкусил
          И Своею Кровью грехи наши простил.
          Он Своею плотью греха природу осудил.
          Умер, но нам спасенье подарил.
          Воскрес в том же теле Иисус Христос
          Через сорок день в небо Бог Его вознëс
          Как Господь, воссел Он одесную Бога.
          В Его власти стали и земля, и небо.
          Иисус - Спасатель мироздания,
          Чтобы верящий спасëн был от лукавого.
          Он вернëтся снова славою Отца
          Чтоб святым усопшим воскресенье дать
          И с ними все святые к Господу Христу
          От земли возмутся Богу своему.
          Наш Господь Иисус в оный день суда
          Каждого осудит по его делам.
          В жизнь вечную лишь праведник пойдëт,
          А страдание нечестивца ждëт.
          И Царствие Господа Христа
          Будет твëрдым, не будет конца
          Там не будет боле этой горькой смерти
          И престол Господень узрят наши очи.
        `,
      },
      {
        title: 'СВЯТОЙ ДУХ',
        description: `
          Триединства Святой Божий
          Один из Личностей Божьей
          Вечно не отделимий
          Он Богу равный
          И в том же положении всегда.
          Совершенно свят, не изменим никогда.
          Иисус Христос Спасëнного Им человека
          Крестит в Духе Святом навечно.
        `,
      },
      {
        title: 'ЦЕРКОВЬ',
        description: `
          К Иисусу Христу обращëнная
          Группа людей, и освящëнная,
          Чтоб цели великой достигли они.
          Стали Богу подобны - 
          В образе святом Его росли.
          Чтоб Иисуса в веках свидетельствовать
          И Евангелие людям проповедовать.
          Верующего водой крестить
          И Вечерю Господню проводить.
        `,
      },
    ],
  ],
};
