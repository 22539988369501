import { Skeleton } from 'antd';

export default function TranslationsViewerLoader() {
  return (
    <div className="bible-translation-viewer-loader">
      <div className="bible-translation-viewer-loader__item">
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
      </div>
      <div className="bible-translation-viewer-loader__item">
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
      </div>
      <div className="bible-translation-viewer-loader__item">
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
        <Skeleton size="small" active />
      </div>
    </div>
  );
}
