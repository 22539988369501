import { useMemo } from 'react';

import { IImageProps } from './types';

import './style.scss';

function Image({
  name,
  type = 'images',
  externalLink,
  alt,
  className,
  ...props
}: IImageProps) {
  const imageStyle: React.CSSProperties = {
    ...props,
  };
  const src = useMemo(
    () => {
      if (!externalLink) {
        return `/${type}/${name}`;
      }

      return externalLink;
    },
    [externalLink, name, type],
  );
  return (
    <img
      className={className}
      src={src}
      alt={alt || src}
      data-type={type}
      style={imageStyle}
    />
  );
}

export default Image;

Image.defaultProps = {
  className: 'image',
};
