import React from 'react';

import { IFlexProps } from './types';

function Flex({
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  className,
  children,
  ...styles
}: IFlexProps) {
  const flexContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: direction,
    justifyContent,
    alignItems,
    ...styles,
  };

  return <div className={className} style={{ ...flexContainerStyle, ...styles }}>{children}</div>;
}

export default Flex;

Flex.defaultProps = {
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};
