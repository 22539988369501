import { createAsyncThunk } from '@reduxjs/toolkit';

import { MemosService } from 'core/services';

export const createMemo = createAsyncThunk(
  'memos/create',
  async ({ userId, title, text }: { userId: string; title: string; text: string }) => {
    const res = await MemosService.createMemo({ title, text, userId });
    return res;
  },
);

export const getMemosByUserId = createAsyncThunk(
  'memos/memosByUserId',
  async () => {
    const res = await MemosService.getMemosByUserId();
    return res;
  },
);

export const getMemoById = createAsyncThunk(
  'memos/memoById',
  async ({ id }: { id: string }) => {
    const res = await MemosService.getMemoById(id);
    return res;
  },
);

export const updateMemo = createAsyncThunk(
  'memos/update',
  async ({ id, title, text }: { id: string; title: string; text: string }) => {
    const res = await MemosService.updateMemo({ id, title, text });
    return res;
  },
);

export const removeMemo = createAsyncThunk(
  'memos/remove',
  async ({ id }: { id: string }) => {
    const res = await MemosService.removeMemo(id);
    return res;
  },
);
