import { Modal, Typography } from 'antd';
import Image from 'components/common/image';
import Flex from 'components/common/Flex';

const { Text } = Typography;

interface IQrCodeModal {
  open: boolean;
  chosenQr: string | null;
  onCancel: () => void;
}

export default function QrCodeModal({
  open,
  onCancel,
  chosenQr,
}: IQrCodeModal) {
  return (
    <Modal
      footer={null}
      open={open}
      onCancel={onCancel}
      width={600}
    >
      <Flex
        alignItems="center"
      >
        <div style={{ padding: 'var(--gap-main)' }}>
          <div>
            <Image
              marginRight="var(--gap-small)"
              width="24px"
              name={chosenQr ?? ''}
            />
            <Text strong>{chosenQr?.includes('app') ? 'Iphone Devices' : 'Android Devices'}</Text>
          </div>
          <Image
            marginTop="var(--gap-main)"
            name={chosenQr?.includes('app') ? 'appstoreqr.png' : 'playstoreqr.png'}
            type="images"
          />
        </div>
      </Flex>
    </Modal>
  );
}
