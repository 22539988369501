import { Navigate } from 'react-router-dom';
import { useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';

function StreamSettingsMiddleware({ Component }: { Component: JSX.Element }) {
  const { userInfo } = useMemoSelector(getAuthState);

  if (userInfo?.isAdmin) {
    return <Navigate to="/" />;
  }

  return Component;
}

export default StreamSettingsMiddleware;
