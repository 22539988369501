import moment from 'moment';

import { useEffect, useMemo, useRef } from 'react';
import Flex from 'components/common/Flex';
import { Form, Button, Input } from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { useLanguage } from 'core/hooks';

const { TextArea } = Input;

interface IMemosCreationProps {
  currentMemo: any;
  versesMemos: any;
  onFinish: (values: { title: string; text: string }) => void;
  backToMemosList: () => void;
}

function MemosCreation({
  currentMemo,
  versesMemos,
  backToMemosList,
  onFinish,
}: IMemosCreationProps) {
  const { currentLanguage } = useLanguage();
  const textareaRef = useRef<any>(null);
  const memo = useMemo(() => {
    let title = '';
    let text = '';
    if (currentMemo) {
      title = currentMemo.title;
      text = `${currentMemo?.text}\n${text}`;
    } else {
      title = `Memo ${moment().format('DD/MM/YYYY hh:mm')}`;
      text = versesMemos;
    }

    return {
      title,
      text,
    };
  }, [currentMemo, versesMemos]);
  const scrollToBottom = () => {
    if (textareaRef.current) {
      textareaRef.current.resizableTextArea.textArea.scrollTop = textareaRef.current.resizableTextArea.textArea.scrollHeight;
    }
  };

  useEffect(() => {
    if (memo.text.length) {
      scrollToBottom();
    }
  }, [memo]);

  return (
    <Form
      key={memo.title}
      layout="vertical"
      initialValues={memo}
      onFinish={onFinish}
    >
      <Flex
        alignItems="stretch"
      >
        <Button
          style={{ alignItems: 'center', justifyContent: 'center' }}
          onClick={backToMemosList}
        >
          <IoIosArrowBack />
        </Button>
        <Form.Item
          name="title"
          style={{ width: '100%', marginLeft: 10 }}
        >
          <Input placeholder={currentLanguage.memo_title} type="text" />
        </Form.Item>
      </Flex>
      <Form.Item
        name="text"
      >
        <TextArea
          ref={textareaRef}
          placeholder={currentLanguage.memo_text}
          style={{ minWidth: 280, minHeight: 400 }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          style={{ width: 'var(--full)' }}
          type="primary"
          htmlType="submit"
        >
          {currentLanguage.save}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default MemosCreation;
