interface ISectionInfoProps {
  lessonTitle?: string;
  title?: string;
  sections?: string[];
  sectionsLinks?: Record<string, number>;
  setVideoActiveMinute: (e: number) => void;
  setLessonActiveSection: (e: string) => void;
}

function SectionInfo({
  lessonTitle,
  title,
  sections,
  sectionsLinks,
  setVideoActiveMinute,
  setLessonActiveSection,
}: ISectionInfoProps) {
  const sectionsData = sectionsLinks || {};

  const handleSectionClick = (section: string) => {
    setVideoActiveMinute(sectionsData[section]);
    setLessonActiveSection(section.split(' ').join('-'));
  };

  return (
    <div className="section-info">
      <p className="section-info__facet-title">
        {lessonTitle}
      </p>
      <p className="section-info__title">
        {title}
      </p>
      <div className="section-info__description-wrapper">
        {(Object.keys(sectionsLinks || {}) || sections)?.map(section => (
          <p
            className="section-info__description"
            key={section}
            onClick={() => handleSectionClick(section)}
          >
            {section}
          </p>
        ))}
      </div>
    </div>
  );
}

export default SectionInfo;
