import { useEffect, useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Button from 'antd/es/button';
import ToolTip from 'antd/es/tooltip';
import { useLanguage } from 'core/hooks';

interface ICopyToClipboard {
  text: string;
}

function CopyToClipboard({ text }: ICopyToClipboard) {
  const { currentLanguage } = useLanguage();
  const [clipBoard, setClipBoard] = useState({
    copied: false,
    text,
  });

  useEffect(() => {
    if (!clipBoard.copied) {
      return;
    }

    setTimeout(() => {
      setClipBoard({ ...clipBoard, copied: false });
    }, 700);
  }, [clipBoard, clipBoard.copied]);

  return (
    <ReactCopyToClipboard
      text={text}
      onCopy={() => {
        setClipBoard({ ...clipBoard, copied: true });
      }}
    >
      <ToolTip
        placement="top"
        title={clipBoard.copied ? currentLanguage.copied : currentLanguage.copy}
      >
        <Button>
          <CopyOutlined />
        </Button>
      </ToolTip>
    </ReactCopyToClipboard>
  );
}

export default CopyToClipboard;
