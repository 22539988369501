export default {
  title: 'ՔՐԻՍՏՈՆԵԱԿԱՆ ՀԱՎԱՏԱՄՔ',
  sections: [
    [
      {
        title: 'ԱՍՏՎԱԾԱՇՈւՆՉ',
        description: `
          Աստծո խոսքն ու միտքն է մարդկանց բառերով, որն ամփոփված է Ավետարանով,
          Այն կատարյալ է, իր մեջն ընդգրկում, մարդու ամեն կարիք ու զգացում:
        `,
      },
      {
        title: 'ԱՍՏՎԱԾ',
        description: `
          Եռանձնյա էակ է, ինքնագո Հոգի. Հայրը, Որդին և Սուրբ Հոգին:
          Որը մեկ է, միակն է և մեկացած է, և իրենից բացի ուրիշ աստված չկա:
          Նա կատարյալ է սիրով, սրբությամբ, կարողությամբ, գիտությամբ և իմաստությամբ:
          Նա ստեղծեց երկինքն ու երկիրը, նրանցում եղած ամեն գոյերը:
          Եւ ամենը նրանով են ապրում, և նրանով իրենց վախճանը գտնում:
          Նա ամենուր է, ամեն ինչ հսկում, հավիտյան նույնն է և չի փոփոխվում:
          Ամեն ստեղծված անձի հետ է նա, իրեն ընտրած մարդկանց մեջ է նա:
        `,
      },
      {
        title: 'ՄԱՐԴԸ',
        description: `
          Ադամի սերունդն է, Եւայից ծնված, երկուսն էլ Աստծո ձեռքով արարված:
          Աստծո պատկերի և նմանության պես, որ մարդը իշխի իր Արարչի պես:
          Սակայն Ադամը պատվիրան խախտեց, դարձավ մեղավոր, քանզի մեղանչեց:
          Ընդունեց Չարից անօրինությունը, նրանում փոխվեց սուրբ բնությունը:
          Եւ նա հեռացավ կյանքի օրենքից, այդպես պակասեց նա Աստծո փառքից:
          Նրա մեջ մտավ մահվան օրենքը, հակումով դարձավ նա դեպի մեղքը,
          Այսինքն դեպի անիրավություն, մեղանչական է նրա բնություն:
          Եւ ապականված այս բնությունը, եղավ մարդկության ժառանգությունը:
        `,
      },
      {
        title: 'ԱՍՏԾՈ ՕՐԵՆՔԸ',
        description: `
          Սուրբ ու կատարյալ է, ամեն օրենքներից նա շատ բարձր է:
          Իրավունք ու մեղք է նա բացահայտում, և մարդկանց դեպի Քրիստոս ուղղորդում:      
        `,
      },
    ],
    [
      {
        title: 'ՀԻՍՈՒՍ ՔՐԻՍՏՈՍԸ',
        description: `
          Մեկ անձն է նա Սուրբ աստվածության, Միածին Որդին, Աստծո էության:
          Աստծուց դուրս եկավ, ոչ թե արարվեց, Աստծո զորությամբ կույսից նա ծնվեց:
          Մարդկային մարմին ու բնույթ վերցրեց, և այդ մարմնում նա երկրպագվեց:
          Մեզ նման փորձվեց, բայց մեղք չգործեց, և որպես Սուրբ Գառ մեզ համար զոհվեց:
          Իր սուրբ արյունով մեր մեղքերը քավեց, և մեղքն ու մահը իր մարմնում դատեց:
          Փրկություն տվեց նա խաչի մահով, հարություն առավ հենց նույն մարմնով:
          Երկինք ելնելով Հոր աջում նստեց, երկինք ու երկիր իր ձեռքը տրվեց:
          Նա է Փրկիչը ամբողջ աշխարհի, որ հավատացողը Չարից ազատվի:
          Հիսուսը Հոր փառքով կվերադառնա, Սուրբ մեռյալներին հարություն կտա:
          Նրանց հետ մեկտեղ երկրից սուրբերը, կհափշտակվեն դեպ իրենց Տերը:
          Դարերի վերջում մեր Հիսուս Տերը, ի լույս կբերի մարդկանց գործերը:
          Արդարը կգնա հավերժական կյանք, իսկ անիրավը դեպ հավերժ տանջանք:
          Եւ Տեր Հիսուսը կթագավորի, իր իշխանությանն էլ վերջ չի լինի:
          Աստված կվերցնի արցունքն ու մահը, աչքով կտեսնենք մեր Տիրոջ գահը:
        `,
      },
      {
        title: 'ՍՈւՐԲ ՀՈԳԻՆ',
        description: `
          Աստծո Սուրբ Եռամիասնության, Անձերից մեկն է, հավետ անբաժան:
          Աստծուն հավասար և միշտ նույն դիրքում, կատարյալ սուրբ է, երբեք չի փոխվում:
          Եւ Հիսուս Քրիստոս\` Տերը Սուրբ Հոգում, իրենով փրկված մարդ է մկրտում:      
        `,
      },
      {
        title: 'ԵԿԵՂԵՑԻՆ',
        description: `
          Հիսուս Քրիստոսին դարձած մարդկանց խումբն է, փրկված և սրբագործված,
          Որ հասնեն նրանք մեծ նպատակին\` նմանվեն Աստծուն՝ Իր սուրբ պատկերին:
          Որ ամեն դարում Հիսուս վկայեն, և Ավետարանը մարդկանց քարոզեն:
          Հավատացողին ջրով մկրտեն ու Տերունական ընթրիք կատարեն:      
        `,
      },
    ],
  ],
};
