import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider, Menu } from 'antd';
import { useLanguage, useQueryParams, useYoutubePlaylistVideos } from 'core/hooks';
import Flex from 'components/common/Flex';

import usePreachesSidebar from './usePreachesSidebar';
import PreachesContent from './PreachesContent';

function Preaches() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queries = useQueryParams();
  const { currentLanguage } = useLanguage();
  const lang = queries.get('lang');
  const playlistIdFromUrl = queries.get('playlistId') || '';
  const pageTokenFromUrl = queries.get('pageToken');
  const [playlistId, setPlaylistId] = useState(playlistIdFromUrl);
  const [pageToken, setPageToken] = useState(pageTokenFromUrl);

  const { speakersData, groups } = usePreachesSidebar();
  const {
    playlistVideos,
    prevPageToken,
    nextPageToken,
    getNextPage,
    getPrevPage,
  } = useYoutubePlaylistVideos({ playlistId, pageToken });
  const replaceQueries = (id: string, token?: string) => {
    navigate({
      pathname,
      search: `lang=${lang}&playlistId=${id}${pageToken ? `&pageToken=${token}` : ''}`,
    });
  };

  const handleMenuClick = (e: any) => {
    const currentPlaylistId = e.key;
    setPlaylistId(currentPlaylistId);
    setPageToken(null);
    replaceQueries(currentPlaylistId, '');
  };

  const handleNavigation = (navigationType = 'next') => {
    if (navigationType === 'prev') {
      if (prevPageToken) {
        setPageToken(prevPageToken);
      }

      playlistId && replaceQueries(playlistId, prevPageToken);
      return getPrevPage();
    }

    if (nextPageToken) {
      setPageToken(nextPageToken);
    }

    playlistId && replaceQueries(playlistId, nextPageToken);
    return getNextPage();
  };

  useEffect(() => {
    setPlaylistId(playlistIdFromUrl);
  }, [lang, playlistIdFromUrl]);

  return (
    <Flex width="var(--full)">
      <PreachesContent
        preachesData={playlistVideos}
        prevPageToken={prevPageToken}
        nextPageToken={nextPageToken}
        handleNavigation={handleNavigation}
      />
      <div className="preaches__sider">
        <Divider orientation="right" plain>
          <b>{currentLanguage.preachers}</b>
        </Divider>
        <Menu
          mode="inline"
          key={playlistId}
          items={speakersData}
          defaultSelectedKeys={[playlistId]}
          onClick={handleMenuClick}
        />
        {groups.length ? (
          <>
            <Divider orientation="right" plain>
              <b>{currentLanguage.groups}</b>
            </Divider>
            <Menu
              mode="inline"
              key={`group-${playlistId}`}
              items={groups}
              defaultSelectedKeys={[playlistId]}
              onClick={handleMenuClick}
            />
          </>
        ) : null}
      </div>
    </Flex>
  );
}

export default Preaches;
