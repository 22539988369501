import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogin } from 'store/slices/auth/actions';
import { getAuthState } from 'store/selectors/auth';
import { Input, Button, Typography, Form, notification } from 'antd';
import { useLanguage, useQueryParams } from 'core/hooks';
import { useMemoSelector } from 'store/hooks';
import { openNotificationWithIcon } from 'config/notification';
import { useEffect } from 'react';

import './style.scss';

const { Title } = Typography;

interface FormData {
  username: string;
  password: string;
}

export default function Login() {
  const navigate = useNavigate();
  const routeTo = useQueryParams().get('routeto');

  const { loading, message, userInfo } = useMemoSelector(getAuthState);
  const dispatch = useDispatch();
  const { currentLanguage } = useLanguage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    if (message?.key) {
      openNotificationWithIcon({
        api: notificationApi,
        message: 'Message',
        type: message.type,
        description: currentLanguage[message.key] || message.message,
        placement: 'top',
      });
    }
  }, [message, notificationApi, currentLanguage]);

  const submitForm = (data: FormData) => dispatch(userLogin(data));

  useEffect(() => {
    if (userInfo) {
      if (routeTo) {
        navigate(`/${routeTo}`);
      } else {
        navigate('/');
      }
    }
  }, [navigate, routeTo, userInfo]);

  return (
    <div className="login">
      {notificationContextHolder}
      <div className="login__container">
        <Title className="login__text" level={4}>{currentLanguage.signinPageTitle}</Title>
        <Form className="login__form" onFinish={submitForm}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: currentLanguage.missedParams }]}
          >
            <div className="login__form__item">
              <Input
                placeholder={currentLanguage.username}
                type="text"
                className="login__form__item__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: currentLanguage.missedParams }]}
          >
            <div className="login__form__item">
              <Input
                placeholder={currentLanguage.password}
                type="password"
                className="login__form__item__input"
              />
            </div>
          </Form.Item>
          <div className="login__form__item">
            <Button className="login__submit" htmlType="submit" type="primary" disabled={loading}>
              {loading
                ? (
                  <>
                    <span>...</span>
                    <span>{currentLanguage.loading}</span>
                  </>
                )
                : currentLanguage.login}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
