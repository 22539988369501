import { NOTES_API } from 'config/constants';
import { INote } from 'types';

import Fetch from './fetch';

const fetchConfigs = {
  headers: { 'Content-Type': 'application/json' },
  credentials: 'include' as RequestCredentials,
};

export const createNote = async ({ bookNumber, chapterNumber, verseIndex, text }: INote): Promise<any> => {
  const response = await Fetch.post(NOTES_API, {
    ...fetchConfigs,
    body: {
      text,
      verseIndex,
      bookNumber,
      chapterNumber,
    },
  });
  return response;
};

export const getNotesByBookAndChapter = async (bookNumber: number, chapterNumber: number): Promise<any> => {
  const response = await Fetch.get(`${NOTES_API}/book/${bookNumber}/chapter/${chapterNumber}`, fetchConfigs);
  return response;
};

export const editNote = async (id: string, text: string): Promise<any> => {
  const response = await Fetch.put(`${NOTES_API}/${id}`, {
    ...fetchConfigs,
    body: { text },
  });
  return response;
};

export const removeNote = async (id: string): Promise<any> => {
  const response = await Fetch.delete(`${NOTES_API}/${id}`, fetchConfigs);
  return response;
};
