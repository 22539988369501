import Container from 'components/common/container';

function StreamSettings() {
  return (
    <Container>
      <h1>StreamSettings</h1>
    </Container>
  );
}

export default StreamSettings;
