import { createAsyncThunk } from '@reduxjs/toolkit';

import { BookmarksService } from 'core/services';

export const createBookmark = createAsyncThunk(
  'bookmarks/create',
  async ({
    title,
    bookNumber,
    verseIndex,
    chapterNumber,
    translationId,
  }: {
    title: string;
    bookNumber: number;
    verseIndex: number;
    chapterNumber: number;
    translationId: number;
  }, { rejectWithValue }) => {
    try {
      const res = await BookmarksService
        .createBookmarks({
          title,
          bookNumber,
          verseIndex,
          chapterNumber,
          translationId,
        });
      return res;
    } catch (error) {
      rejectWithValue(error);
      return null;
    }
  },
);

export const getBookmarks = createAsyncThunk(
  'bookmarks/userBookmarks',
  async (_, { rejectWithValue }) => {
    try {
      const res = await BookmarksService.getBookmarks();
      return res;
    } catch (error) {
      rejectWithValue(error);
      return [];
    }
  },
);

export const removeBookmark = createAsyncThunk(
  'bookmarks/remove',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await BookmarksService.removeBookmark(id);
      return res;
    } catch (error) {
      rejectWithValue(error);
      return null;
    }
  },
);
