import { MEMOS_API } from 'config/constants';
import { IMemo, ICreateMemo } from 'types';
import { logError } from 'config/logging';

import Fetch from '../fetch';

enum IRequestCredentials {
  include = 'include',
}

const fetchConfigs = {
  headers: { 'Content-Type': 'application/json' },
  credentials: IRequestCredentials.include,
};

export const createMemo = async ({ userId, title, text }: ICreateMemo): Promise<any> => {
  try {
    const response = await Fetch.post(MEMOS_API, {
      body: {
        userId,
        title,
        text,
      },
      ...fetchConfigs,
    });
    return response;
  } catch (error) {
    logError(`Error creating memo:, ${error}`);
    throw error;
  }
};

export const getMemosByUserId = async (): Promise<IMemo[]> => {
  try {
    const response = await Fetch.get(MEMOS_API, fetchConfigs);
    return response;
  } catch (error) {
    logError(`Error fetching memos by user ID:, ${error}`);
    throw error;
  }
};

export const getMemoById = async (id: string): Promise<IMemo> => {
  try {
    const response = await Fetch.get(`${MEMOS_API}/${id}`, fetchConfigs);
    return response;
  } catch (error) {
    logError(`Error fetching memo by ID:, ${error}`);
    throw error;
  }
};

export const updateMemo = async ({ id, title, text }: { id: string; title: string; text: string }): Promise<IMemo> => {
  try {
    const response = await Fetch.put(`${MEMOS_API}/${id}`, {
      body: {
        id,
        title,
        text,
      },
      ...fetchConfigs,
    });
    return response;
  } catch (error) {
    logError(`Error updating memo: ${error}`);
    throw error;
  }
};

export const removeMemo = async (id: string): Promise<IMemo> => {
  try {
    const response = await Fetch.delete(`${MEMOS_API}/${id}`, {
      body: { id },
      ...fetchConfigs,
    });
    return response;
  } catch (error) {
    logError(`Error removing memo: ${error}`);
    throw error;
  }
};
