import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import './style.scss';

interface IVerseModalProps {
  currentVerse?: {
    title: string;
    description: string;
  };
}

function VerseModal({ currentVerse }: IVerseModalProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(!!currentVerse);
  }, [currentVerse]);

  return (
    <Modal
      className="verse-modal"
      footer={null}
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
    >
      <div className="verse-modal__title-wrapper">
        <p className="verse-modal__title">{currentVerse?.title}</p>
      </div>
      <p className="verse-modal__description">{currentVerse?.description}</p>
    </Modal>
  );
}

export default VerseModal;
