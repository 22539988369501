import Container from 'components/common/container';
import Typography from 'antd/es/typography';
import { useLanguage } from 'core/hooks';

import whatIBelieveData from './whatIBelieveData';

import './style.scss';

const { Title, Paragraph } = Typography;

function HomePage() {
  const { langShortcut } = useLanguage();
  const currentWhatIBelieve = whatIBelieveData[langShortcut];
  return (
    <div className="home router-container">
      <Container id="home__container">
        <div>
          <Title className="home__title" level={2}>{currentWhatIBelieve.title}</Title>
          <div className="home__column-container">
            {currentWhatIBelieve.sections.map(section => (
              <div key={section.length.toString()}>
                {
                  section.map(sectionItem => (
                    <div key={sectionItem.title}>
                      <Title level={4}>{sectionItem.title}</Title>
                      <Paragraph>{sectionItem.description}</Paragraph>
                    </div>
                  ))
                }
              </div>
            ))}
          </div>
        </div>
      </Container>
      <img className="home__background-image" src="/images/logo_big.png" alt="logo" />
    </div>
  );
}

export default HomePage;
