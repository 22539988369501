import { Button, Modal, Typography } from 'antd';
import { HiMinusSm } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';

import Flex from '../Flex';

const { Text } = Typography;
interface ISearchModal {
  title: string;
  open: boolean;
  children: JSX.Element;
  onMinimize: () => void;
  onCancel: () => void;
}

export default function SearchModal({
  title,
  open,
  children,
  onMinimize,
  onCancel,
}: ISearchModal) {
  return (
    <Modal
      title={(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text strong>{title}</Text>
          <Flex alignItems="center">
            <Button
              onClick={onMinimize}
              style={{ padding: 5, height: 25, display: 'flex', alignItems: 'center' }}
            >
              <HiMinusSm />
            </Button>
            <Button
              onClick={onCancel}
              style={{ padding: 5, marginLeft: 5, height: 25, display: 'flex', alignItems: 'center' }}
            >
              <IoCloseSharp />
            </Button>

          </Flex>
        </div>
      )}
      footer={null}
      open={open}
      width={600}
      closable={false}
    >
      {children}
    </Modal>
  );
}
