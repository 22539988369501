import { logWarn } from 'config/logging';
import store from 'store';
import { logoutUser } from 'store/slices/auth/actions';

import { ERROR_MESSAGES } from './constants';

interface IFetchOptions extends RequestInit {
  body?: any;
  isFormData?: boolean;
  isCache?: boolean;
  includeContentType?: boolean;
  timeout?: number;
  withoutLanguageHeader?: boolean;
}
const UNAUTHORIZED = 'Unauthorized';

const cache = new Map();
const { dispatch } = store;
export default class Fetch {
  /* GET (retrieve) */
  public static get = (path: string, options?: IFetchOptions) => Fetch.fetch(path, {
    ...options,
    method: 'GET',
  });

  /* POST (create) */
  public static post = (path: string, options?: IFetchOptions) => Fetch.fetch(path, {
    ...options,
    method: 'POST',
  });

  /* PUT (update) */
  public static put = (path: string, options?: IFetchOptions) => Fetch.fetch(path, {
    ...options,
    method: 'PUT',
  });

  /* DELETE (remove) */
  public static delete = (path: string, options?: IFetchOptions) => Fetch.fetch(path, {
    ...options,
    method: 'DELETE',
  });

  public static clearCache = () => cache.clear();

  private static async fetch(path: string, options: IFetchOptions = {}) {
    const {
      headers,
      method,
      body,
      isFormData,
      isCache = false,
      includeContentType = true,
      ...rest
    } = options;
    const { SERVER_ERROR } = ERROR_MESSAGES;

    const requestOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Language': document.documentElement.lang,
        ...headers,
      },
      method,
      ...rest,
    } as { [key: string]: any };

    if (includeContentType) {
      requestOptions.headers['Content-Type'] = 'application/json';
    }

    if (isFormData) {
      delete requestOptions.headers['Content-Type'];
    }

    if (body) {
      requestOptions.body = isFormData ? body : JSON.stringify(body);
    }

    if (isCache && cache.has(`${path}${isCache}`)) {
      const cacheItem = cache.get(`${path}${isCache}`);

      return cacheItem.data;
    }

    const requestPromise = await fetch(path, requestOptions);

    if (requestPromise.status === 401 && requestPromise.statusText.includes(UNAUTHORIZED)) {
      dispatch(logoutUser());
      return {
        errorMessage: 'Unauthorized user. Please log in.',
        status: 401,
      };
    }

    if (requestPromise.status >= 200 && requestPromise.status < 300) {
      try {
        const data = await requestPromise.json();

        if (isCache) {
          cache.set(`${path}${isCache}`, {
            data,
            cached: true,
          });
        }

        return data;
      } catch (e) {
        logWarn(e);
        return {
          errorMessage: SERVER_ERROR,
        };
      }
    } else if (requestPromise.status === 401) {
      return {};
    } else {
      const errorMessage = await requestPromise.text() || SERVER_ERROR;

      return {
        errorMessage,
      };
    }
  }
}
