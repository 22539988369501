import { useSearchParams } from 'react-router-dom';
import './style.scss';

function GodsCalling() {
  const [searchParams] = useSearchParams();
  const currentTranslation = searchParams.get('lang');

  return (
    <div className="gods-calling">
      <img
        className="gods-calling__img"
        src={`./images/godsCalling/${currentTranslation}.jpg`}
        alt=""
      />
    </div>
  );
}

export default GodsCalling;
