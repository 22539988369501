import { createAsyncThunk } from '@reduxjs/toolkit';

import { NotesService } from 'core/services';
import { INote } from 'types';

interface IGetCertainNotesProps {
  bookNumber: number;
  chapterNumber: number;
}
interface IEditNotesProps {
  _id: string;
  text: string;
  bookNumber: number;
  verseIndex: number;
  chapterNumber: number;
}

export const createNote = createAsyncThunk(
  'notes/create',
  async ({
    verseIndex,
    text,
    bookNumber,
    chapterNumber,
  }: INote, { rejectWithValue }) => {
    try {
      const res = await NotesService
        .createNote({
          verseIndex,
          text,
          bookNumber,
          chapterNumber,
        });
      return res;
    } catch (error) {
      rejectWithValue(error);
      return null;
    }
  },
);

export const getCertainNotes = createAsyncThunk(
  'notes/getCertainNotes',
  async (
    { bookNumber, chapterNumber }: IGetCertainNotesProps,
    { rejectWithValue },
  ) => {
    try {
      const res = await NotesService.getNotesByBookAndChapter(bookNumber, chapterNumber);
      return res;
    } catch (error) {
      rejectWithValue(error);
      return [];
    }
  },
);

export const editNote = createAsyncThunk(
  'notes/edit',
  async ({ _id, text, verseIndex, bookNumber, chapterNumber }: IEditNotesProps, { rejectWithValue, dispatch }) => {
    try {
      const res = await NotesService.editNote(_id, text);
      // TODO: implement it with normal way
      if (res.status === 404 || res.status === 'deleted') {
        dispatch(createNote({
          text,
          verseIndex,
          bookNumber,
          chapterNumber,
        })).unwrap();
        return;
      }

      return res;
    } catch (error: any) {
      rejectWithValue(error.message);
      return null;
    }
  },
);

export const removeNote = createAsyncThunk(
  'notes/remove',
  async (_id: string, { rejectWithValue }) => {
    try {
      const res = await NotesService.removeNote(_id);
      return res;
    } catch (error) {
      rejectWithValue(error);
      return null;
    }
  },
);
