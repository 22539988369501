import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ISearchButton {
  title: string;
  onSearchButtonClick: () => void;
}

export default function SearchButton({
  title,
  onSearchButtonClick,
}: ISearchButton) {
  return (
    <Button
      style={{ width: '100%' }}
      onClick={onSearchButtonClick}
    >
      {title}
      <SearchOutlined />
    </Button>
  );
}
