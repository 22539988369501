import { useMemo } from 'react';
import { Checkbox } from 'antd';
import { useLanguage } from 'core/hooks';
import { IBibleTranslation } from 'types';
import { useMemoSelector } from 'store/hooks';
import { getAuthState } from 'store/selectors/auth';

import '../../style.scss';

interface ITranslationsProps {
  bibleTranslations: IBibleTranslation[];
  selectedTranslations: (string | number)[];
  onTranslationSelect: (val: (string | number)[]) => void;
}
function Translations({
  bibleTranslations,
  selectedTranslations,
  onTranslationSelect,
}: ITranslationsProps) {
  const { userInfo } = useMemoSelector(getAuthState);
  const { currentLanguage } = useLanguage();

  const options = useMemo(() => (
    bibleTranslations.map(({ name, translator_name, id }) => ({
      label: `${name} ${translator_name}`,
      value: id,
    }))
  ), [bibleTranslations]);

  return (
    <div className="bible-navigation__translation">
      {/* @ts-ignore */}
      <Checkbox.Group value={selectedTranslations} onChange={onTranslationSelect}>
        {options.map(({ label, value }) => (
          <Checkbox
            key={value}
            value={value}
          >
            {label}
          </Checkbox>
        ))}
        {userInfo?._id && (
          <Checkbox
            key="notes"
            value="notes"
          >
            {currentLanguage.notes}
          </Checkbox>
        )}
      </Checkbox.Group>
    </div>
  );
}

export default Translations;
