import { APP_LANGUAGES_ENUM } from 'config/constants';

export enum TESTAMENTS {
    OLD ='old',
    NEW ='new',
}

export interface IBibleTranslation {
    id: number;
    name: string;
    translator_name: string;
    translator_abr: string;
    language_name: string;
    language: APP_LANGUAGES_ENUM;
    createdAt: string;
    updatedAt: string;
}
export interface IBibleBook {
    id: number;
    title: string;
    short_title: string;
    number: number;
    translation_id: number;
    chapters_count: number;
    testament: TESTAMENTS;
    language: APP_LANGUAGES_ENUM;
    createdAt: string;
    updatedAt: string;
}
export interface IBibleBookChapter {
    title: string;
    preface: boolean;
    text: string;
    number: number;
    translation_id: number;
    book_number: number;
    language: APP_LANGUAGES_ENUM;
}
export interface ISearchResult {
    index: number;
    text: string;
    chapter: {
        number: number;
        title: string;
    };
    book: {
        number: number;
        title: string;
    };
}

export type TypeSearchResult = Record <string, ISearchResult[]>
