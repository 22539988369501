import Flex from 'components/common/Flex';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Tooltip } from 'antd';
import { MdSkipPrevious, MdDownload, MdSkipNext } from 'react-icons/md';
import Lottie from 'react-lottie-player';
import { useLanguage, useQueryParams } from 'core/hooks';

import playerBarsAnimation from './playerBarsAnimation.json';

interface IPlayerProps {
  playlist: string[];
  className?: string;
  currentTrackIndex: number;
  playing: boolean;
  setPlaying: React.Dispatch<SetStateAction<boolean>>;
  setCurrentTrackIndex: React.Dispatch<SetStateAction<number>>;
}

function Player({
  playlist,
  className,
  currentTrackIndex,
  playing,
  setPlaying,
  setCurrentTrackIndex,
}: IPlayerProps) {
  const playerRef = useRef<ReactPlayer>(null);
  const playerBars = useRef<any | null>(null);
  const [animationPlaying, setAnimationPlaying] = useState(false);
  const { currentLanguage } = useLanguage();
  const lang = useQueryParams().get('lang');
  const handlePlayNext = () => {
    setCurrentTrackIndex(prevIndex => (prevIndex + 1) % playlist.length);
    setPlaying(true);
  };

  const handlePlayPrev = () => {
    setCurrentTrackIndex(prevIndex => (prevIndex - 1 + playlist.length) % playlist.length);
  };

  const handlePause = useCallback(() => {
    setPlaying(false);
    setAnimationPlaying(false);
  }, [setPlaying]);
  const handlePlay = () => {
    setPlaying(true);
    setAnimationPlaying(true);
  };

  const handlePlayerReady = () => {
    const player = playerRef?.current?.getInternalPlayer();
    if (player) {
      player.setAttribute('controlsList', 'nodownload noplaybackrate');
    }
  };

  const handleDownload = () => {
    const currentAudioSrc = playlist[currentTrackIndex];
    const link = document.createElement('a');
    link.href = currentAudioSrc;
    link.download = 'audio.mp3';
    link.click();
  };

  useEffect(() => {
    handlePause();
    setCurrentTrackIndex(0);
  }, [handlePause, lang, setCurrentTrackIndex]);

  return (
    <div>
      <ReactPlayer
        className={className}
        ref={playerRef}
        url={playlist[currentTrackIndex]}
        playing={playing}
        controls
        width="100%"
        height="50px"
        onEnded={handlePlayNext}
        onPause={handlePause}
        onPlay={handlePlay}
        onReady={handlePlayerReady}
      />
      <Flex
        width="full"
        justifyContent="space-between"
        alignItems="center"
        marginTop="var(--gap-main)"
      >
        <Lottie
          loop
          play={animationPlaying}
          ref={playerBars}
          animationData={playerBarsAnimation}
        />
        <Flex>
          <Tooltip
            placement="top"
            title={currentLanguage.prev}
          >
            <Button
              shape="circle"
              size="middle"
              icon={<MdSkipPrevious />}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handlePlayPrev}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={currentLanguage.next}
          >
            <Button
              shape="circle"
              size="middle"
              icon={<MdSkipNext />}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'var(--gap-main)',
                marginRight: 'var(--gap-main)',
              }}
              onClick={handlePlayNext}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={currentLanguage.download}
          >
            <Button
              shape="circle"
              size="middle"
              icon={<MdDownload />}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleDownload}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </div>
  );
}

export default Player;
