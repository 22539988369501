import './style.scss';
import { FaPlay, FaPause } from 'react-icons/fa';

interface IAudioItemActionProps {
  playing: boolean;
  activeItem: boolean;
}

function AudioItemAction({ playing, activeItem }: IAudioItemActionProps) {
  return (
    <div className="audios__item-action">
      {playing && activeItem ? (
        <FaPause color="var(--main-secondary)" />
      ) : (
        <FaPlay color="var(--main-secondary)" />
      )}
    </div>
  );
}

export default AudioItemAction;
