import { useLanguage } from 'core/hooks';
import { memo } from 'react';

import SearchButton from './SearchButton';

interface IHeader {
  activeTranslations: any;
  onSearchButtonClick: any;
  chaptersTexts: any;
  notesShown: boolean;
}

function Header({
  notesShown,
  chaptersTexts,
  activeTranslations,
  onSearchButtonClick,
}: IHeader) {
  const { currentLanguage } = useLanguage();
  return (
    <thead>
      <tr className="translation-viewer__header">
        {activeTranslations?.map(
          ({ id, name, translator_name }: any) => (
            <td key={`${name}-${translator_name}`}>
              <SearchButton
                title={`${name} ${translator_name}`}
                onSearchButtonClick={() => (
                  onSearchButtonClick(
                    id,
                    translator_name,
                    name,
                  )
                )}
              />
            </td>
          ),
        )}
        {notesShown && (
          <td className="notes-cell">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                borderRadius: '8px',
                border: '1px solid #3b29634d',
              }}
            >
              {currentLanguage.notes}
            </div>
          </td>
        )}
      </tr>
      <tr>
        {chaptersTexts?.map(
          ({ title, number, translation_id, language, book_number }: any) => (
            <th
              key={translation_id + language + book_number}
              className={chaptersTexts.length > 1
                ? 'translation-viewer__verse-title-container translation-viewer__verse-title-container__bordered'
                : 'translation-viewer__verse-title-container'}
            >
              {`${number}. ${title}`}
            </th>
          ),
        )}
        {notesShown && (
          <th className="notes-cell">
            {chaptersTexts?.[0]?.number}
          </th>
        )}
      </tr>
    </thead>
  );
}

export default memo(Header);
