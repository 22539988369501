import { CONTACT_US_API } from 'config/constants';

interface IMessageBody {
  email: string;
  message: string;
  phoneNumber: string;
}
interface IResponse {
  key: string;
  message: string;
  type: string;
}

export const sendMessageForCustomer = async (value: IMessageBody): Promise<IResponse> => {
  const response = await fetch(CONTACT_US_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(value),
  });
  const data = response.json();

  return data;
};
