import { Typography } from 'antd';
import './style.scss';

const { Paragraph } = Typography;
interface ILyricsProps {
  lyrics: string;
}

function Lyrics({ lyrics }: ILyricsProps) {
  return (
    <div className="audios__lyrics">
      <Paragraph style={{ fontSize: 'var(--font-main-size)', margin: 0, whiteSpace: 'pre-line', textAlign: 'center' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: lyrics }} />
      </Paragraph>
    </div>
  );
}

export default Lyrics;
