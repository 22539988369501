import { NotificationType } from 'store/slices/auth/types';
import { NotificationInstance } from 'antd/es/notification/interface';

type NotificationPlacement = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'top' | 'bottom';

interface INotifications {
  api: NotificationInstance;
  type: NotificationType;
  message?: string | null;
  placement: NotificationPlacement;
  description?: string | null;
}

export const openNotificationWithIcon = ({
  api,
  type,
  message,
  description,
  placement,
}: INotifications) => {
  api[type]({
    message,
    description,
    placement,
  });
};
