import { useMemo } from 'react';

import { formatVerses } from '../utils';

interface IForewordProps {
  text: string;
  versesLinks: Record<string, string>;
  onVerseLinkClick: (event: any) => void;
}

function Foreword({ text, versesLinks, onVerseLinkClick }: IForewordProps) {
  const formattedText = useMemo(() => formatVerses(text, versesLinks), [text, versesLinks]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: formattedText }}
      onClick={onVerseLinkClick}
    />
  );
}

export default Foreword;
