import { Button } from 'antd';

interface INavigation {
  handleNavigation: (type: string) => void;
  prevPageToken?: string;
  nextPageToken?: string;
}

function Navigation(
  {
    handleNavigation,
    prevPageToken,
    nextPageToken,
  }: INavigation,
) {
  return (
    <div className="video-content__navigation">
      <Button
        type="primary"
        disabled={!prevPageToken}
        onClick={() => handleNavigation('prev')}
      >
        Previous
      </Button>
      <Button
        type="primary"
        disabled={!nextPageToken}
        onClick={() => handleNavigation('next')}
      >
        Next
      </Button>
    </div>
  );
}

export default Navigation;
