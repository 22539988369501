import { useCallback, useMemo, useState } from 'react';
import { Input, Select } from 'antd';
import { TypeSearchResult, ISearchResult, TESTAMENTS_TYPES } from 'types';
import { useLanguage } from 'core/hooks';
import { useMemoSelector } from 'store/hooks';
import { getCurrentBook } from 'store/selectors/bibleTranslations';
import { IQueryParams } from 'core/services/bible/chapters.search.service';
import Flex from 'components/common/Flex';
import SearchEmptyState from 'components/common/emptyState/searchEmptyState';

import useBookNavigation from '../useBookNavigation';

import ResultsGroup from './ResultsGroup';
import ResultsGroupItems from './ResultsGroupItems';

const { Search: AntSearch } = Input;
interface ISearch {
  id: number;
  searchResult: TypeSearchResult;
  searchForWord: (params: { translationId: number; searchedWord: string; queryParams: IQueryParams }) => void;
  closeModal: () => void;
  emptySelectedVerses: () => void;
  setSearchIsMinimized: (minimized: boolean) => void;
}

export enum searchOptions {
  search_all = 'search_all',
  search_old = 'search_old',
  search_new = 'search_new',
  search_book = 'search_book',
}

export default function Search({
  id, closeModal, searchResult, searchForWord, setSearchIsMinimized, emptySelectedVerses,
}: ISearch) {
  const [selectedWord, setSelectedWord] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [queryParams, setQueryParams] = useState<IQueryParams>({});
  const [showWordsList, setShowWordsList] = useState(false);
  const [searchedFirstTime, setSearchedFirstTime] = useState(true);
  const { number: currentBookNumber } = useMemoSelector(getCurrentBook);

  const { currentLanguage } = useLanguage();
  const buttons = useMemo(() => [
    { label: currentLanguage.search_all, value: searchOptions.search_all },
    { label: currentLanguage.search_old, value: searchOptions.search_old },
    { label: currentLanguage.search_new, value: searchOptions.search_new },
    { label: currentLanguage.search_book, value: searchOptions.search_book },
  ], [currentLanguage]);

  const { setCurrentBook, setCurrentChapter, setCurrentVerse } = useBookNavigation();
  const getQuery = useCallback((searchOption: searchOptions) => {
    const query = { bookNumber: '', testament: '' };
    switch (searchOption) {
      case searchOptions.search_book:
        query.bookNumber = String(currentBookNumber);
        break;
      case searchOptions.search_new:
        query.testament = TESTAMENTS_TYPES.new;
        break;
      case searchOptions.search_old:
        query.testament = TESTAMENTS_TYPES.old;
        break;
      default:
        break;
    }

    return query;
  }, [currentBookNumber]);

  const handleSearch = useCallback(() => {
    searchForWord({ translationId: id, searchedWord: inputValue, queryParams });
    setShowWordsList(false);
    setSearchedFirstTime(false);
  }, [id, inputValue, queryParams, searchForWord]);

  const redirect = (searchedItem: ISearchResult) => {
    const { book, chapter, index } = searchedItem;
    setCurrentBook(book.number);
    setCurrentChapter(chapter.number);
    setCurrentVerse(index);
    setSearchIsMinimized(true);
    setSearchedFirstTime(true);
    emptySelectedVerses();
    closeModal();
  };

  const handleOptionChange = useCallback(key => {
    const query = getQuery(key);
    setQueryParams(query);
    searchForWord({ translationId: id, searchedWord: inputValue, queryParams: query });
  }, [getQuery, id, inputValue, searchForWord]);

  return (
    <div className="search">
      <Flex width="var(--full)">
        <Select
          className="search__options"
          placeholder={currentLanguage.whereToSearch}
          options={buttons}
          onChange={handleOptionChange}
        />
        <AntSearch
          placeholder={currentLanguage.search}
          onChange={({ target }) => setInputValue(target.value)}
          onSearch={handleSearch}
        />
      </Flex>

      <div style={{ paddingRight: 12, maxHeight: '500px', overflowY: 'auto' }}>
        {!Object.keys(searchResult).length && !searchedFirstTime
          ? (
            <SearchEmptyState />
          ) : null}
        {!showWordsList && searchResult && (
          <ResultsGroup
            searchResult={searchResult}
            setSelectedWord={setSelectedWord}
            setShowWordsList={setShowWordsList}
          />
        )}
        {showWordsList && searchResult && (
          <ResultsGroupItems
            results={searchResult}
            selectedKey={selectedWord}
            backToWordsList={setShowWordsList}
            setSearchItem={redirect}
          />
        )}
      </div>
    </div>
  );
}
