import { BIBLE_API } from 'config/constants';
import { IBibleBookChapter } from 'types';

import Fetch from '../fetch';

export const getBookChapters = async (translationId: number, bookNumber: number): Promise<IBibleBookChapter[]> => {
  const response = await Fetch.get(`${BIBLE_API}/chapters/${translationId}/${bookNumber}`, {
    isCache: true,
    cache: 'force-cache',
  });

  return response as IBibleBookChapter[];
};

export const getChapterData = async (translationId: number, bookNumber: number, chapterNUmber: number): Promise<IBibleBookChapter> => {
  const response = await Fetch.get(`${BIBLE_API}/chapters/${translationId}/${bookNumber}/${chapterNUmber}`, {
    isCache: true,
    cache: 'force-cache',
  });

  return response as IBibleBookChapter;
};

export const getTranslationsChaptersTexts = async (translationIds: (number | string)[], bookNumber: number, chapterNumber: number): Promise<IBibleBookChapter[]> => {
  const response = await Fetch.get(`${BIBLE_API}/chapters/translations-texts/${bookNumber}/${chapterNumber}?translationIds=${translationIds?.join(',')}`, {
    isCache: true,
    cache: 'force-cache',
  });

  return response as IBibleBookChapter[];
};
