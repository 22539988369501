import { MemosService } from 'core/services';

interface IMemoCommonProps {
  text: string;
  title: string;
}
interface ICreateMemoProps extends IMemoCommonProps {
  userId: string;
}
interface IUpdateMemoProps extends IMemoCommonProps {
  id: string;
}

const useMemos = () => {
  const createMemo = async ({ userId, title, text }: ICreateMemoProps) => (
    MemosService.createMemo({ userId, title, text })
  );
  const getMemosByUserId = async () => {
    const result = await MemosService.getMemosByUserId();
    return result;
  };

  const getMemoById = async (id: string) => {
    const result = await MemosService.getMemoById(id);
    return result;
  };

  const updateMemo = async ({ id, title, text }: IUpdateMemoProps) => {
    MemosService.updateMemo({ id, title, text });
  };

  return {
    createMemo,
    getMemosByUserId,
    getMemoById,
    updateMemo,
  };
};

export default useMemos;
