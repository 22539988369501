/* eslint-disable max-len */
export default {
  title: '',
  description: `
    1. Meriv heye, difikire, ku ew qenc e, lê cimet p’êsîra xwe jê dadik’iþîne.
    2. Milet heye, ku dibêje: «Ezî Xwedênas im», lê p’ûtan dihebîne.
    3. Wey li wî merivî, yê þaþya xwe vediþêre, lê yê gunehê xwe dide rûyê xwe û ji xwe dûr dixe, ewê xêrê bibîne.
    4. Merivê aqil ya dil zû venake, lê nêta dilê bêfeman ser zarê wî ye.
    5. Hinek bona xwe dibêjin «Ez rast im», lê bi xwe dijminê rastyê ye.
    6. Ew merivê, ku þîret û hilatinê qebûl nake, ew ji þaþyên xwe aza nabe.
    7. Þika xirab, heta xêrxwazê merivan jî dûrî wî dixe.
    8. Hê baþ e bi gumana li ser Xwedê dilê xwe hêsa kî, ne ku ji rûyê nebaweryêda xweda biqilqilî.
    9. Malek ku diþewite, zû vêsîne û wî zû bigire, yê zinêrda dik’eve, heger na, pey undakirinêr’a t’u tiþt qazincê nade: usa jî þîreta derengî bêy qazincê e.
    10. Meriv heye, ku dide k’ifþê, yanê her tiþtî zane, lê bi rastî ew nizan e.
    11. Zaroka aqil dê-bavêr’a rûsipîtî ye, lê yê bêfem derd-kul û rûreþî ye.
    12. Lao bi ç’evê k’ubar merivan nenihêr’e û ber Xwedê guneh neke.
    13. Yê îç’kê hiz dike, xwe rûreþ dike û mala xwe jî xirab dike.
    14. K’ubarî dijminê ruhê evdan e.
    15. Camêrê xwedênas xwe ji xirabyê dûr digire, lê yê ç’evqul bê Xwedê ye, nêt-fikirê wî xirabî ye.
    16. K’ê Xwedê xweþ tê? Yê bawerya xwe gotinên wî tîne.
    17. Li ser dinyayê tengasya merivên rast gelek e, lê Xwedê wan aza dike, axirya wan jî xêr dike.
    18. Av êgir ditemirîne, xebera Xwedê jî ner’astyê.
    19. Rastya Xwedê milet rûsipî dike, lê ji rê k’etina milet, wî unda dike.
    20. Jîyîna vê dinê hulm-gulmek e, gotina Xwedê tu qebûl ke û ewê te bextewar ke.
    21. Yê xirabyê hîn dike, ew ruhê xwe unda dike, ew cimetê xirab dike û bêy ceza wê nemîne.
    22. Çawa av cyê bilind nasekine, dua-dirozge jî li ser qur’e-babaxan nasekine.
    23. Av xwe cyê nimiz digire, rehm û k’erema Xwedê jî xwe merivê nek’ubar digire.
    24. Xêrxwazê rast roja tengasyêda k’ifþ e, ew ji alîk’aryê venak’iþe.
    25. Xema giran her roj bîra mêrivda ye.
    26. Xwedê dûr dike, lê bîr nake, lê hinek dibêjin: Xwedê dîwanê nake.
    27. Bira Xwedê yar be, evd neyar be.
    28. Rastîk heye mala Xwedê.
    29. Mala Xwedê bi sebirê ava ye.
    30. Darbir’o daran bibir’e, gumana xwe ji Xwedê nebir’e.
    31. Gulên xwedênasyê bin, xulamê Xwedê bin.
    32. Yê dizî kir, neheqî kir, boy ruhê xwe xirabî kir. 
    33. Derew dijminê Xwedê ye.
    34. Dewsa qencyê qencî k’arê her merivî ye, lê dewsa xirabyê qencî, k’arê mêrê çê ye.
    35. Yê neyarî xirab kir, wî hizkirin ava kir.
    36. Evdê serreþ bêy guneh nabe û her gîha ser k’oka xwe þîn dibe.
    37. Yê hindava xêrxwazê xweda mixenet e, qezya ji wî dûr nak’eve.
    38. Mala ku Xwedê çê neke, ew mal nabe mal.
    39. Xwedênasî ew rastî ye, nûr-nedîra dinyayê ye.
    40. Wey li wî bajarî, li k’u xwedênasî nema.
    41. Mî p’yê xwe, bizin p’yê xwe, her kesê her’e ser emelê xwe.
    42. Dîwaro ter'a me, bûkê tu bibihê.
    43. Merivê xirab rya Xwedê berda û rê-rizmê kal-bavan hilda.
    44. Xwedê îmana rast bide te, axreta te xêr bike.
    45. K’ê çi bike, wê xwe bike. Rehmê bike, ku rehm-k’erem bê pêþya te, xirabyê neke û xirabî neyê pêþya te.
    46. K’ê çi diçîne, wê jî diçine.
    47. Xêrê bike, roda ber’de, ewê rokê bê pêþya te.
    48. Xwezî xêrkiran ne ku xêrxuran.
    49. Dinê dor e, xwe ne zor e.
    50. Dinê boþ û betal e, axreta meriva xêr be.
    51. Xofa Xwedê mêriv ji xirabyê dûr dixe.
    52. Xwedê xweyê k’etyan e, rehma wî ser rastan e.
    53. K’ulfeta xwedêxof mala xwe ava dike, lê ya dinêhiz, mala xwe xirab dike.
    54. Lomek’aro, gunehk’aro.
    55. Bira Xwedê ya xwe bike, ya evdan evdanr’a neke.
    56. Rasto rêda, qelpo zerêda.
    57. Av ser mesî hate bir’înê, mesî hiþk bû, Încîl ji mêriv hate hildanê, meriv unda bû.
    58. Xûnê neke, bira xûn ser teda neyê. Xûn erdê namîne.
    59. Merivê p'ayê xwe da, mîna gûzê, navê wî jî li ser bûzê.
    60. Ayê destekî destê dinr’a namîne, k’u ma ayê yê rast nerastanr’a bimîne.
    61. Aþ bê çeqeçeq nabe, jîyîna k’ubaran jî bêy þer’-dew nabe.
    62. Ar heta elekirinê ser elekê ye, xwedênasê dinêhiz jî heta roja cêribandinê.
    63. Aþ t’oz û tebar e, ser piþta bêfeman jî þivdar e.
    64. Ba t’une, t’oz jî t’une, k’ubarî t’une, þer’ jî t’une.
    65. Devê syar bûye, qûze-qûz dice.
    66. Alîkî dinê þîn e, alîk þayî.
    67. Anegorî ber’a xwe, nigê xwe dirêj ke.
    68. Aqil t’acê zêr’în e, serê her kesî nîne, biç’ûk û mezinan ew nizane.
    69. Xeber hene zêr’ in, xeber jî hene cî bi cî þer’ in. 
    70. Berxê Xwedê xwey dike, gur lê naxwe.
    71. Yê dixwaze merivê xirab xweþ bê, ewê rastî xirabyê bê.
    72. Merivê pozbilind ji k’ubaryê Xwedê xwe jî bîr kirye.
    73. Çiqas bibî mîr, dîsa Xwedê xwe bîne bîr.
    74. Aqilê giran, barê sivik, aqilê sivik barê giran.
    75. Xebera xweþ bahara dilan e, lê ya xirab zivistana giran e.
    76. Sêwîyan got: «Emê unda bin». Xwedê got: «Lê ez k’u me?».
    77. Ezî welatê xerîbda mame, piþtî Xwedê didebirim û heme.
    78. Derb heye qenc, derb heye xirab.
    79. Meriv bik’eve t’or’a camêran, lê nek’eve destê bêxwedan.
    80. Hezar salî dinê bî, zêr’-zîvada xinê bî, tuê dîsa rokê mêvanê gor’ê bî.
    81. Serê seran Xwedê ye, ew p’adþayê erd ezmên e.
    82. Pismamê ‏þaþyê, neyarê rastyê, pismamê dinê, neyarê Xwedê.
    83. Dîwana Xwedê li ser rastyê hîm bûye.
    84. Yê navê xwe diger’e, xwe li sosiretyê digire.
    85. P’ira Selatê weke derzî, yê xwedênas serr’a bezî, lê gunehk’ar çok lerizî û ew k’ete nava qezî.
    86. Teyr difir’e bi refê xwer’a.
    87. Xebat mîna þîrê dê li merivan rûdinê.
    88. Hebandin t’enê p’ara Xwedê dik’eve.
    89. Bêy Xwedê ç’ila ji darê nayê xwarê.
    90. Xiþînya mûryê t'opalr’a Xwedê hiþyar e.
    91. Mirin hespê xwe syar e, li çar qulbê vê dinyayê ew diger’e û her merivan dibe xware, qet napirse, gelo ewî kal e yan cahal e.
    92. Sebir sik’an e, axret bi îman e.
    93. Dar çiqasî qalim dibe, haqasî zû diþkê.
    94. Merivê bê bawerî, bêy Xwedê ye.
    95. Bê fem e, ew merivê ku k’arê ferz hiþtye û pey yê hewask’ar k’etye.
    96. Gelek meriv ji ç’evqulyê hebûka xwe jî unda dikin.
    97. Çi ku waxtê xweda nayê kirinê qîmetê wê t’une.
    98. Hene meriv, ku bi eþq ji xelqê deyn hildidin, lê gava paþda didin, usa digirîn ça bêjî k’eda xwe bê heq didin.
    99. Ruhê Xwedê her deran e û hemûyan ew dinihêr’e, dîwar, te’rî yan jî mirin qet t’u kesî venaþêrin.
    100. Meriv hene sore-gir in bê hunir in.
    101. Xwedê nayê xapandinê, yê gunehk’ar, boy k’arê xwe tê girtinê.
    102. Aqil ji dîndarê zêdetir e, merivê bê Xwedê jî ji yê aqil kêmtir e.
    103. Gelek ser k’etina dijminê xwe þa dibin, lê bi wê yekê unda dibin.
    104. Bona çi dilê te ji lawê te gazin e, çaxê þîretên te ew nizane.
    105. Ç’êleka sor ç’ermê xwe nikare biguhêze, merivê xirab jî binyata xwe, lê Xwedê jor gelek zor e, k’ê dixwaze wî diguhêze.
    106. Hê qenc e bi nanê t’isî mala xweda bijîy, ne ku bi qelî-qawirme mala xelqêda.
    107. Hê qenc e parî nanê t’isî, bi heq-helalî, ne ku hebûka gur’, bi nerastî.
    108. Heta dijmin jî li ber rastyê ta dibe.
    109. Merivê aqil bona rizgarbûna jîyîna xwe ber hebûka dinê nak’eve.
    110. Merivê ku Xwedê xwe bîr kirye, dinê axretêda xêrê nabîne.
    111. Gelek caran þaþyên merivan wanr’a dibine ders.
    112. Meriv hêsa dibe bindest, lê zehmet serbest.
    113. Çaxê hikumet yan jî cimet k’ubar dibin, þer’ çê dibin.
    114. Gava yek p’êþkêþan dide te, bizanibe nêt-meremê wî hene, belkî bona xirabyê ne?
    115. Yê t’evî k’arê xelqê dibe, badîhewa dik’eve nava qezya.
    116. Çi dera k’ê birîn e, ji wê derê ew gazin e, bi zarê wî jî eyan e, derd kulê wî çida ne.
    117. Ulmê Mesîh wek ezman e, hemû sur’î kes nizane, çiqas hîn bî, hê cî heye, kal jî hîn bin, ne þermî ye.
    118. Tu t’evî k’ê dixebitî, t’imê qedirê wî bizanibî, hevalê baþ ter’a piþt e, bêy hevaltî ne t’u tiþt e.
    119. Ulmê Xwedê tu dest bîne, ew jîyînê pêþda tîne, hebûk diçe û namîne, lê ulmê wî t’im dimîne.
    120. Yê ku xeyba xelqê dike ji wî her’e, çimkî ewê rojeke baþ li ser te jî xeybê bike.
    121. Firêqetyêda k’esîbanr’a qencyê bike, dinê dor e, qencya teyê te veger’a.
    122. Li ber Xwedê t’enê rastî qebûl e.
    123. Xweykiryê Xwedê bî, ku ji xirabyê tu dûr bî.
    124. Gumanek heye, ew jî, Xwedê.
    125. Zikê t’êr çi haþ ji zikê birçî heye.
    126. Mirin rabû, meriv ta bû, Mesîh rabû, mirin ta bû.
    127. Mêrê çê rewþa cimet e, xebera çê rewþa milet e.
    128. Miletê xwe tu hiz bike, lê xelqê tu bêhurmet neke.
    129. Merivê zane qewatê distîne û bi serwaxtyê hîmê mala xwe datîne.
    130. Devî k’ê xar e, k’en ne st’ar e.
    131. Dev xirabyê dibêje, ç’ev þerm dike.
    132. Devê hînî derewan bûye ra pê nabe.
    133. Rezê dare, ku gelek bin, devê dihejin jî wê gelek bin.
    134. Xwedê heye, xem t'uneye, Încîla wî bona me ye.
    135. Azayî ew p’êþkêþa Xwedê ye, ku bi Mesîh t’imê heye.
  `,
};
