import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { useLanguage } from 'core/hooks';
import Flex from 'components/common/Flex';

import AuthActions from './AuthActions';

const { Paragraph } = Typography;

function NavbarItems({
  className,
  isMobileDevice = false,
  toMemos,
}: {
  className?: string;
  isMobileDevice?: boolean;
  toMemos: () => void;
}) {
  const { currentLanguage } = useLanguage();
  return (
    <Flex
      alignItems={isMobileDevice ? 'flex-start' : 'center'}
      direction={isMobileDevice ? 'column-reverse' : 'row'}
    >
      <div className={className}>
        <Paragraph
          onClick={toMemos}
          className="navbar-item"
        >
          {currentLanguage.memos}
        </Paragraph>
        <Paragraph
          className="navbar-item"
        >
          <Link to="/contact-us">
            {currentLanguage.contactUs}
          </Link>
        </Paragraph>
      </div>
      <div
        style={{
          ...(isMobileDevice && {
            paddingBottom: 'var(--gap-main)',
            marginBottom: 'var(--gap-main)',
            marginLeft: 0,
            borderBottom: '1px solid white',
            width: '100%',
          }),
          ...(!isMobileDevice && {
            marginLeft: 'var(--gap-main)',
            paddingLeft: 'var(--gap-main)',
            borderLeft: '1px solid white',
          }),
        }}
      >
        <AuthActions
          logoutLabel={currentLanguage?.logout || ''}
          signupLabel={currentLanguage?.signup || ''}
          loginLabel={currentLanguage?.login || ''}
        />
      </div>
    </Flex>
  );
}

export default NavbarItems;
