import { BIBLE_API } from 'config/constants';

import Fetch from '../fetch';

export interface IQueryParams {
  bookNumber?: string;
  testament?: string;
}
export const chapterSearch = async (words: string, translationId?: number, params?: IQueryParams) => {
  if (!translationId || !words || !words.length) {
    return undefined;
  }

  const queryString = Object.entries(params || {}).map(([key, value]) => value ? `${key}=${value}` : '').join('&');

  const response = await Fetch
    .get(`${BIBLE_API}/chapters-search/${translationId}/${words}?${queryString}`, {});
  return response;
};
